import React from "react";
import { FieldProps } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const WelcomeEditor = ({ field }: FieldProps) => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["link"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

    [{ size: [false] }],

    [{ font: [] }],
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  return (
    <ReactQuill
      theme="snow"
      value={field.value}
      modules={modules}
      style={{
        width: "100%",
        height: "calc(100% - 42px)",
      }}
      className="text-editor"
      onChange={(value) =>
        field.onChange({
          target: { name: field.name, value },
        })
      }
    />
  );
};

export default WelcomeEditor;
