import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "hooks/useAuth";
import { GuardProps } from "types";

const AdminAuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, role } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || role !== "admin") {
      navigate("/manual/login", { replace: true });
    }
  }, [isLoggedIn, navigate, role]);

  return children;
};

export default AdminAuthGuard;
