import {
  ADMIN_DOC_API_URL,
  USER_DOC_API_URL,
  USER_DOC_PUBLIC_API_URL,
} from "../../../config";
import { Show } from "../../../types/show";
import { httpClient } from "../httpClient";

function getPublicShows(): Promise<Show[]> {
  return httpClient.get(`${USER_DOC_PUBLIC_API_URL}/shows`).then((resp) => {
    return resp.data ?? [];
  });
}

function getShows(): Promise<Show[]> {
  return httpClient.get(`${USER_DOC_API_URL}/shows`).then((resp) => {
    return resp.data ?? [];
  });
}

function getShowByID(showId: number): Promise<Show> {
  return httpClient.get(`${USER_DOC_API_URL}/shows/${showId}`).then((resp) => {
    return resp.data ?? null;
  });
}

function createShow(show: Show): Promise<Show> {
  return httpClient.post(`${ADMIN_DOC_API_URL}/show`, show).then((resp) => {
    return resp.data ?? null;
  });
}

function createShowFromTemplate(
  showName: string,
  templateId: number
): Promise<Show> {
  const body = {
    showName: showName,
    templateId: templateId,
  };
  return httpClient
    .post(`${ADMIN_DOC_API_URL}/show/templates`, body)
    .then((resp) => {
      return resp.data ?? null;
    });
}

function updatePassword(showId: number, password: string): Promise<Show> {
  const body = {
    password: password,
  };
  return httpClient
    .post(`${ADMIN_DOC_API_URL}/show/${showId}/change_password`, body)
    .then((resp) => {
      return resp.data ?? null;
    });
}

function deleteShow(showId: number): Promise<Show> {
  return httpClient
    .delete(`${ADMIN_DOC_API_URL}/show/${showId}`)
    .then((resp) => {
      return resp.data ?? null;
    });
}

function updateShow(showId: number, body: any): Promise<Show> {
  return httpClient
    .put(`${ADMIN_DOC_API_URL}/show/${showId}`, body)
    .then((resp) => {
      return resp.data ?? null;
    });
}

export const showClient = {
  getPublicShows,
  getShows,
  getShowByID,
  createShow,
  createShowFromTemplate,
  updatePassword,
  deleteShow,
  updateShow,
};
