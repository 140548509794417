import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { Policy } from "../pages/policy/views/Policy";

import { useDispatch } from "store";
import { setSelectedPolicy } from "store/slices/policy";

export const PrivateRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname === "/policies" ||
      location.pathname === "/policies/"
    ) {
      dispatch(
        setSelectedPolicy({
          policyName: "Universal International Studios",
          policyCode: "uis",
        })
      );
    }

    if (
      location.pathname === "/matchboxpicturesandtap-policies" ||
      location.pathname === "/matchboxpicturesandtap-policies/"
    ) {
      dispatch(
        setSelectedPolicy({
          policyName: "Matchbox Pictures & Tony Ayres Productions",
          policyCode: "mp",
        })
      );
    }

    if (
      location.pathname === "/monkeykingdom-policies" ||
      location.pathname === "/monkeykingdom-policies/"
    ) {
      dispatch(
        setSelectedPolicy({
          policyName: "Monkey Kingdom",
          policyCode: "mk",
        })
      );
    }
  }, [location, dispatch]);

  const selectedPolicy = useSelector(
    (state: any) => state.policy.selectedPolicy
  );
  const user = useSelector((state: any) => state.user.users.policiesUser);

  const policyRoleMap: Record<string, string> = {
    uis: "uis",
    mp: "mp",
    mk: "mk",
  };

  if (!selectedPolicy || !selectedPolicy.policyCode) {
    return <Navigate to="/login" />;
  }

  const requiredRole = policyRoleMap[selectedPolicy.policyCode];

  if (requiredRole && user.roles.includes(requiredRole) && user.isLoggedIn) {
    return <Policy />;
  }

  return <Navigate to="/login" />;
};
