import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { IconFileUpload, IconCircleCheck } from "@tabler/icons-react";

const fileTypes = ["pdf", "docx"];

interface DropBoxProps {
  uploaded: boolean;
}

const DropBox = ({ uploaded }: DropBoxProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        border: "1px dashed #000",
        borderRadius: "4px",
        p: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {uploaded && (
          <IconCircleCheck size={40} stroke={1} style={{ color: "green" }} />
        )}
        {!uploaded && <IconFileUpload size={40} stroke={1} />}

        <Typography variant="caption" sx={{ pt: "8px" }}>
          Drag & Drop
        </Typography>
        <Typography variant="caption" sx={{ pb: "8px" }}>
          or
        </Typography>
        <Button sx={{ p: "4px 8px", fontSize: "12px" }}>Upload</Button>
      </Box>
    </Box>
  );
};

interface DragDropProps {
  onFileSelect: (file: File) => void;
}

function DragDrop({ onFileSelect }: DragDropProps) {
  const [uploaded, setUploaded] = useState(false);
  const handleChange = (file: File) => {
    onFileSelect(file);
    setUploaded(true);
  };

  return (
    <FileUploader
      style={{ width: "100%" }}
      handleChange={handleChange}
      onDrop={handleChange}
      name="file"
      types={fileTypes}
      label={"Click here or drag your file to upload"}
    >
      <DropBox uploaded={uploaded} />
    </FileUploader>
  );
}

export default DragDrop;
