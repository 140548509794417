import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

type LogoProps = {
  height: string;
  source: string;
};

export const Logo = ({ height, source }: LogoProps) => {
  return (
    <RouterLink to="/">
      <img
        src={source}
        alt="Universal Studio Logo"
        className={""}
        style={{ height: `${height}px` }}
      />
    </RouterLink>
  );
};
