import axios from "axios";

import { API_URL } from "../../../config";

export const getPolicies = (policy: string) => {
  return axios
    .get(`${API_URL}/policies?policy=${policy}`)
    .then((res) => res.data)
    .catch((err) => console.info(err));
};
