import React from "react";

import ProductionManualLayout from "../components/Layout/ProductionManualLayout";
import AdminHome from "../modules/productionManualAdmin/home/views/AdminHome";
import AdminManual from "../modules/productionManualAdmin/manual/views/AdminManual";
import AdminTemplates from "../modules/productionManualAdmin/templates/views/AdminTemplates";
import Productions from "../modules/productionManualAdmin/productions/views/Productions";
import AdminAuthGuard from "../utils/AdminAuthGuard";
import AdminDashboard from "../modules/productionManualAdmin/home/views/AdminDashboard";
import AdminProductionVendors from "../modules/productionManualAdmin/productionVendors/views/AdminProductionVendors";
import Users from "../modules/productionManualAdmin/users/views/Users";

import ProductionTemplates from "modules/productionManualAdmin/production templates/views/ProductionTemplate";
import ModifyTemplates from "modules/productionManualAdmin/production templates/views/ModifyTemplates";
import AdminContact from "modules/productionManualAdmin/home/views/AdminContact";
import AdminMeetings from "modules/productionManualAdmin/home/views/AdminMeetings";
import AdminWelcome from "modules/productionManualAdmin/home/views/AdminWelcome";

const ManualAdminRoutes = {
  path: "/manual",
  element: (
    <AdminAuthGuard>
      <ProductionManualLayout />
    </AdminAuthGuard>
  ),
  children: [
    {
      path: "admin/home",
      element: <AdminHome />,
      children: [
        { path: "", element: <AdminDashboard /> },
        { path: "welcome", element: <AdminWelcome /> },
        { path: "contact", element: <AdminContact /> },
        { path: "meetings", element: <AdminMeetings /> },
      ],
    },
    {
      path: "admin/manual",
      element: <AdminManual />,
    },
    {
      path: "admin/templates",
      element: <AdminTemplates />,
    },
    {
      path: "admin/vendors",
      element: <AdminProductionVendors />,
    },
    {
      path: "admin/productions",
      element: <Productions />,
    },
    {
      path: "admin/users",
      element: <Users />,
    },
    {
      path: "admin/production-templates",
      element: <ProductionTemplates />,
    },
    {
      path: "admin/production-templates/modify",
      element: <ModifyTemplates />,
    },
  ],
};

export default ManualAdminRoutes;
