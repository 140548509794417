import * as React from "react";

export const TextContainer = () => {
  return (
    <div className="pt-12">
      <p>
        Welcome to the <b>Universal International Studios</b> production
        website. As a division of Universal Studio Group,{" "}
        <b>Universal International Studios&nbsp;</b>
        delivers award-winning content to millions across the globe.
        Headquartered in London with offices in Los Angeles,{" "}
        <b>Universal International Studios</b>, owns a number of global
        production companies including{" "}
        <b>
          Carnival Films, Heyday Television, Matchbox Pictures, Working Title
          Television
        </b>
        , along with unscripted outfits such as <b>Monkey Kingdom</b>.
        <b>Universal International Studios</b> also has a first-look deal with
        <b>&nbsp;LARK Productions Canada</b>, and operates a prolific
        International Formats business, which licenses and produces content in
        over 70 territories worldwide.
        <br />
        <br /> For those engaged to work on our scripted and/or non-scripted
        productions, this site hosts our policies which we expect those engaged
        to familiarise themselves with and abide by. For more information,
        please click on the “Policies” tab above.
        <br />
        <br /> Everyone has a responsibility to promote the highest ethical
        standards and comply with the law. We encourage you to speak up and
        report concerns promptly. If you wish to do so please click on the
        “Reporting” tab above.
      </p>
    </div>
  );
};
