import axios from "axios";

import { API_URL } from "../../../../config";

export type LoginCredentialsDTO = {
  Policy: string | undefined;
  Password: string;
};
export const loginWithPolicyAndPassword = (
  data: LoginCredentialsDTO
): Promise<any> => {
  return axios.post(`${API_URL}/login`, data);
};
