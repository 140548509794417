import { Grid } from "@mui/material";

import ManualLoginForm from "../components/ManualLoginForm";
import { Logo } from "../../../../components/Logo";
import { images } from "../../../../assets";
import ManualFooter from "../../../../components/ManualFooter/ManualFooter";

import AuthWrapper from "./ManualLoginAuthWrapper";
import AuthCardWrapper from "./ManualLoginAuthCardWrapper";

const ManualLogin = () => {
  return (
    <AuthWrapper>
      <Grid
        container
        direction="column"
        sx={{
          minHeight: "100vh",
          justifyContent: "flex-end",
          flexDirection: "row",
          alignItems: "center",
          p: "0 16px",
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Logo source={images.logo.sm} height={"70"} />
                  </Grid>
                  <Grid item xs={12}>
                    <ManualLoginForm />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ManualFooter />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default ManualLogin;
