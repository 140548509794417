export const API_URL = process.env.REACT_APP_API_URL as string;
export const USER_DOC_PUBLIC_API_URL = process.env
  .REACT_APP_USER_DOC_PUBLIC_API_URL as string;
export const USER_DOC_API_URL = process.env
  .REACT_APP_USER_DOC_API_URL as string;
export const ADMIN_DOC_API_URL = process.env
  .REACT_APP_ADMIN_DOC_API_URL as string;

export const COGNITO_API_URL = process.env.REACT_APP_COGNITO_URL as string;

export const REACT_APP_AUTHORIZE_URL = process.env
  .REACT_APP_AUTHORIZE_URL as string;
export const SAML_CLIENT_ID = process.env.REACT_APP_SAML_CLIENT_ID as string;
export const SAML_CLIENT_SECRET = process.env
  .REACT_APP_SAML_CLIENT_SECRET as string;

export const CALLBACK_URI = process.env.REACT_APP_CALLBACK_URI as string;

export const isLocal: boolean = process.env.REACT_APP_ENV === "local";
export const isProduction: boolean = process.env.REACT_APP_ENV === "prod";
