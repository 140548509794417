import React, { useEffect, useState, useRef, useMemo } from "react";
import { toast } from "react-toastify";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  FormikProps,
  FieldProps,
} from "formik";
import * as Yup from "yup";

import MainCard from "components/Cards/MainCard";
import { useCustomTheme } from "themes";
import { useDispatch } from "store";
import WelcomeEditor from "components/WelcomeEditor/WelcomeEditor";
import { templateClient } from "services/api/manual/template";
import { getTemplateById } from "store/slices/templates";
import { ProductionCompanyState } from "types/productionCompany";
import { TemplateState } from "types/template";

interface FormValues {
  welcomeMessage: string;
}

interface ResetFormProps {
  onReset: (resetValues: { values: FormValues }) => void;
}

interface AdminWelcomeMessageFormProps {
  disableUpdate?: boolean;
}

export default function AdminTemplateWelcomeMessageForm({
  disableUpdate = false,
}: AdminWelcomeMessageFormProps) {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const formikRef = useRef<FormikProps<FormValues>>(null);

  const selectedTemplate = useSelector(
    (state: TemplateState) => state.templates.selectedTemplate
  );

  const template = useSelector(
    (state: any) => state.templates.selectedTemplate
  );

  const selectedProdCompany = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany
  );

  const initialValues = useMemo(
    () => ({ welcomeMessage: selectedTemplate?.welcomeMessage! }),
    [selectedTemplate?.welcomeMessage]
  );

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm({ values: initialValues });
    }
  }, [initialValues]);

  const validationSchema = Yup.object().shape({
    welcomeMessage: Yup.string().required("Welcome message is required"),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    const body = {
      ...template,
      welcomeMessage: values.welcomeMessage,
    };
    setLoading(true);

    try {
      await templateClient.updateTemplate(template.id, body);
      toast.success("Successfully updated template welcome message");
      setLoading(false);
      dispatch(
        getTemplateById(selectedProdCompany?.productionCompanyId!, template.id)
      );
    } catch {
      toast.error("Error updating template welcome message");
      setLoading(false);
    }
  };

  const ResetForm = ({ onReset }: ResetFormProps) => {
    const handleReset = () => {
      onReset({
        values: {
          welcomeMessage: "",
        },
      });
    };

    return (
      <Button
        onClick={handleReset}
        variant="outlined"
        sx={{
          p: "4px 8px",
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.secondary.light,
          "&:hover": {
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
          },
        }}
      >
        <Typography
          sx={{
            color: "inherit",
          }}
          variant="caption"
        >
          Clear Form
        </Typography>
      </Button>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MainCard
        sx={{
          borderRadius: "8px",
          width: "100%",
          minHeight: "500px",
        }}
        title="Update welcome message"
        content={false}
        boxShadow={false}
        border={true}
        subtitle={"Please update the welcome message for this show."}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {({ resetForm, errors, touched }) => (
            <Form>
              <Grid container spacing={2} sx={{ p: "16px" }}>
                <Grid item xs={12} sm={12}>
                  <ResetForm
                    onReset={() =>
                      resetForm({
                        values: {
                          welcomeMessage: "",
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ minHeight: "300px" }}>
                  <Field name="welcomeMessage" sx={{}}>
                    {({ field, form, meta }: FieldProps) => (
                      <WelcomeEditor field={field} form={form} meta={meta} />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      sx={{
                        boxShadow: "none",
                        width: "20%",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={disableUpdate ? true : loading}
                    >
                      {loading ? (
                        <CircularProgress
                          sx={{
                            color: "#000",
                            "&:hover": {
                              color: "#000",
                            },
                          }}
                          size={20}
                        />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </MainCard>
    </Box>
  );
}
