import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import { activeItem, openDrawer } from "../../../../store/slices/menu";
import { useCustomTheme } from "../../../../themes";

import { dispatch, useSelector } from "store";

export interface SubItem {
  id: string;
  title: string;
  url: string;
}

interface NavItemProps {
  id: string;
  url: string;
  title: string;
  icon: React.ReactElement;
  subItems?: SubItem[];
}

const NavItem = ({ id, title, url, icon, subItems }: NavItemProps) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const theme = useCustomTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const { selectedItem, drawerOpen } = useSelector((state) => state.menu);
  const selectedShowId = useSelector(
    (state: any) => state.show.selectedShow.showId
  );

  const isSelected = selectedItem && selectedItem.id === id;

  const itemHandler = () => {
    dispatch(activeItem({ id, name: title }));
    dispatch(openDrawer(false));
  };

  useEffect(() => {
    if (pathname.split("/").includes(id)) {
      dispatch(activeItem({ id, name: title }));
    }
  }, [pathname, id, title]);

  const handleClick = () => {
    setOpen(!open);
    dispatch(activeItem({ id, name: title }));
  };

  return (
    <>
      {matchUpMd || drawerOpen ? (
        <>
          <ListItemButton
            component={Link}
            disabled={selectedShowId === null}
            to={url}
            sx={{
              borderRadius: `8px`,
              alignItems: "flex-start",
              backgroundColor: isSelected
                ? theme.palette.tertiary.light
                : "#fff",
              p: "10px 16px",
              mb: "6px",
              "&.Mui-selected": {
                backgroundColor: theme.palette.tertiary.light,
                border: `1px solid ${theme.palette.tertiary.main}`,
              },
            }}
            selected={isSelected}
            onClick={subItems ? handleClick : itemHandler}
          >
            <Stack
              direction="row"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex" }}>
                {(matchUpMd || drawerOpen) && (
                  <ListItemIcon
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "& svg": {
                        width: "20px",
                        height: "20px",
                      },
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <Typography
                      variant={isSelected ? "h5" : "body1"}
                      color="inherit"
                    >
                      {title}
                    </Typography>
                  }
                />
              </Box>

              {subItems &&
                (open ? (
                  <IconChevronUp size={20} />
                ) : (
                  <IconChevronDown size={20} />
                ))}
            </Stack>
          </ListItemButton>
          {subItems && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subItems.map((subItem) => (
                  <ListItemButton
                    key={subItem.id}
                    component={Link}
                    to={subItem.url}
                    disabled={selectedShowId === null}
                    sx={{
                      mb: "4px",
                      pl: theme.spacing(4),
                      backgroundColor: pathname.includes(subItem.url)
                        ? theme.palette.tertiary.light
                        : "#fff",
                      "&:hover": {
                        backgroundColor: theme.palette.tertiary.main,
                      },
                      borderRadius: "8px",
                    }}
                    onClick={itemHandler}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="body2" color="inherit">
                          {subItem.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </>
      ) : (
        <ListItemButton
          component={Link}
          disabled={selectedShowId === null}
          to={url}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: `8px`,
            backgroundColor: "#fff",
            mb: "6px",
            "&.Mui-selected": {
              backgroundColor: theme.palette.tertiary.light,
              border: `1px solid ${theme.palette.tertiary.main}`,
            },
          }}
          selected={isSelected}
          onClick={itemHandler}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {icon}
          </ListItemIcon>
        </ListItemButton>
      )}
    </>
  );
};

export default NavItem;
