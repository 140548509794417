import { useEffect } from "react";

import { Hero } from "../components/Hero/Hero";
import { TextContainer } from "../components/TextContainer/TextContainer";
import { Brands } from "../components/Brands";
import { ContentLayout } from "../../../components/Layout/MainLayout/ContentLayout";
import { SwiperCarousel } from "../components/SwiperCarousel/SwiperCarousel";
import { Footer } from "../../../components/Footer";

import { getProductionCompanies } from "store/slices/productionCompany";
import { useDispatch } from "store";
import MainNavigation from "components/Navigation/MainNavigation/MainNavigation";

export const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductionCompanies());
  }, [dispatch]);

  return (
    <>
      <MainNavigation />
      <ContentLayout className="px-[150px] tablet:px-[150px] phone:px-[20px] phone:">
        <Hero />
        <TextContainer />
        <Brands />
        <SwiperCarousel />
      </ContentLayout>
      <Footer />
    </>
  );
};
