import { Box, Drawer, useMediaQuery } from "@mui/material";

import { useCustomTheme } from "../../../../themes";
import MenuList from "../MenuList";
import { DRAWER_WIDTH } from "../../../../constants";
import { useSelector } from "../../../../store";

import MiniDrawerStyled from "./MiniDrawerStyled";

const Sidebar = () => {
  const theme = useCustomTheme();
  const { drawerOpen } = useSelector((state) => state.menu);
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      component="nav"
      sx={{
        zIndex: 500,
        flexShrink: { md: 0 },
        width: matchUpMd ? DRAWER_WIDTH : "auto",
      }}
      aria-label="mailbox folders"
    >
      {matchUpMd || drawerOpen ? (
        <Drawer
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,

            "& .MuiDrawer-paper": {
              mt: "86px",
              p: "23px 16px 16px 16px",
              width: DRAWER_WIDTH,

              border: "none",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <MenuList />
        </Drawer>
      ) : (
        <MiniDrawerStyled anchor="left" variant="permanent">
          <MenuList />
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default Sidebar;
