import React from "react";

import useModalState from "../../../../hooks/useModalState";

import CreateProductionModal from "components/Modals/CreateProductionModal";
import UpdatePasswordModal from "components/Modals/UpdatePasswordModal";
import DeleteProductionModal from "components/Modals/DeleteProductionModal";
import CreateTemplateProductionModal from "components/Modals/CreateTemplateProductionModal";

const ProductionModals = () => {
  const modalState = useModalState();

  return (
    <>
      {modalState.createProductionModalOpen && <CreateProductionModal />}
      {modalState.createTemplateProductionModalOpen && (
        <CreateTemplateProductionModal />
      )}
      {modalState.updatePasswordModalOpen && <UpdatePasswordModal />}
      {modalState.deleteProductionModalOpen && <DeleteProductionModal />}
    </>
  );
};

export default ProductionModals;
