import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import { useDispatch } from "store";
import { closeDeleteProductionModal } from "store/slices/modal";
import { showClient } from "services/api/manual";
import { getShowsByProductionCompany } from "store/slices/show";

const DeleteProductionModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const deleteProductionModalOpen = useSelector(
    (state: any) => state.modal.deleteProductionModalOpen
  );

  const deleteProductionModalParamsId = useSelector(
    (state: any) => state.modal.deleteProductionModalParams.id
  );

  const showList = useSelector(
    (state: any) => state.show.productionCompanyShows
  );

  const selectedShow = showList.find(
    (item: any) => item.showId === deleteProductionModalParamsId
  );

  const productionCompanyId = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyId
  );

  const handleDelete = async (showId: number, prodCompanyId: number) => {
    if (showId) {
      try {
        setLoading(true);
        await showClient.deleteShow(showId);
        setLoading(false);
        toast.success("Successfully deleted");
        dispatch(getShowsByProductionCompany(productionCompanyId));
      } catch (error) {
        toast.error("Error deleting production");
        console.error("Error in deleting production:", error);
      } finally {
        dispatch(getShowsByProductionCompany(productionCompanyId));
        dispatch(closeDeleteProductionModal());
      }
    }
  };

  return (
    <Dialog
      sx={{ "& .MuiPaper-root": { p: "8px" } }}
      open={deleteProductionModalOpen}
      onClose={() => dispatch(closeDeleteProductionModal())}
    >
      <DialogTitle variant="h2" sx={{ py: 4 }}>
        {`Delete "${selectedShow.showName}"?`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to delete{" "}
              <strong style={{ fontFamily: "inherit" }}>
                {selectedShow.showName}
              </strong>
              ?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: "16px 24px" }}>
        <Button
          sx={{
            boxShadow: "none",
            background: "#eb1e13",
            "&:hover": {
              boxShadow: "none",
              color: "#eb1e13",
              background: "#fff",
              border: "1px solid #eb1e13",
            },
          }}
          variant="contained"
          disabled={loading}
          onClick={() => {
            handleDelete(selectedShow.showId, productionCompanyId);
          }}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: "#000",
              }}
              size={20}
            />
          ) : (
            "Delete"
          )}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            dispatch(closeDeleteProductionModal());
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProductionModal;
