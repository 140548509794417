import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from "swiper";
import { nanoid } from "nanoid";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

import { images } from "../../../../assets";
import Next from "../../../../assets/icons/next-button.svg";
import Prev from "../../../../assets/icons/prev-button.svg";

import styles from "./swiperCarousel.module.css";

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

export const SwiperCarousel = () => {
  const swiperNavPrevRef = React.useRef(null);
  const swiperNavNextRef = React.useRef(null);
  return (
    <div className="laptop:py-[100px] tablet:py-[50px] phone:py-[50px]">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        navigation={{
          prevEl: swiperNavPrevRef.current,
          nextEl: swiperNavNextRef.current,
        }}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className={styles.swiper}
        onInit={(swiper: any) => {
          swiper.params.navigation.prevEl = swiperNavPrevRef.current;
          swiper.params.navigation.nextEl = swiperNavNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {images.sliderImages.map((item, index) => {
          return (
            <SwiperSlide key={nanoid()} className={styles.swiperSlide}>
              <img
                srcSet={`${item.sm} 320w, ${item.md} 600w, ${item.lg} 1200w, ${item.xl} 1920w`}
                sizes="(min-width: 1200px) 1200px, 100vw"
                className={styles.swiperSlideImg}
                src={item.xl}
                alt={item.alt}
                loading="lazy"
              />
              <p className="pt-3 text-center">{item.title}</p>
            </SwiperSlide>
          );
        })}

        <div
          className={styles.swiperNavPrev}
          style={{ backgroundImage: `url(${Prev})` }}
          ref={swiperNavPrevRef}
        ></div>
        <div
          className={styles.swiperNavNext}
          style={{ backgroundImage: `url(${Next})` }}
          ref={swiperNavNextRef}
        ></div>
      </Swiper>
    </div>
  );
};
