import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getShow,
  getShowsByProductionCompany,
  setSelectedShow,
} from "store/slices/show";
import { PublicShowListItem } from "types/show";
import { useAppDispatch } from "hooks";

const ShowSelect = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const shows = useSelector((state: any) => state.show.productionCompanyShows);
  const productionCompanyId = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyId
  );

  const [show, setShow] = useState<PublicShowListItem | null>(null);

  useEffect(() => {
    dispatch(getShowsByProductionCompany(productionCompanyId));
  }, [dispatch, productionCompanyId]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedShowName = event.target.value;

    const selectedShow = shows.find(
      (s: PublicShowListItem) => s.showName === selectedShowName
    );
    setShow(selectedShow);
    if (selectedShow) {
      dispatch(
        setSelectedShow({
          showId: selectedShow.showId,
          showName: selectedShow.showName,
        })
      );
      if (selectedShow.showId) {
        dispatch(getShow(selectedShow.showId, navigate));
      }
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            background: "#fff",
          }}
          id="show-select-label"
        >
          Select a show
        </InputLabel>
        <Select
          placeholder="Select a show"
          labelId="show-select-label"
          id="show-select"
          value={show?.showName || ""}
          label="Show"
          onChange={handleChange}
          sx={{
            minWidth: "250px",
            "& #show-select": {
              padding: "12px 16px",
            },
          }}
        >
          {shows.map((show: PublicShowListItem) => (
            <MenuItem key={nanoid()} value={show.showName || ""}>
              {show.showName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ShowSelect;
