import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DefaultRootStateProps } from "types";
import { SelectedPolicy } from "types/policy";

const initialState: DefaultRootStateProps["policy"] = {
  error: null,
  selectedPolicy: null,
};

const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    getPolicySuccess(state, action: PayloadAction<SelectedPolicy>) {
      state.selectedPolicy = action.payload;
    },
    setSelectedPolicy(state, action: PayloadAction<SelectedPolicy | null>) {
      state.selectedPolicy = action.payload;
    },
    hasError(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
  },
});

export const { setSelectedPolicy } = policySlice.actions;

export default policySlice.reducer;
