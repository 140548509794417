export class SfpStore {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  public get<T>(key: string): T | null {
    return this.getOrDefault(key, null);
  }

  public getOrDefault<T>(key: string, defaultValue: T): T {
    let serializedValue: string | null = null;
    try {
      serializedValue = this.storage.getItem(key);
    } catch (err) {}
    if (serializedValue === null || serializedValue === "undefined") {
      return defaultValue;
    }
    return JSON.parse(serializedValue) as T;
  }

  public set(key: string, value: {}) {
    let serializedValue: string;
    try {
      serializedValue = JSON.stringify(value);
      this.storage.setItem(key, serializedValue);
    } catch (err) {}
  }

  public remove(key: string) {
    this.storage.removeItem(key);
  }

  public clear() {
    this.storage.clear();
  }
}

export function newLocalStorage(): SfpStore {
  return new SfpStore(localStorage);
}
