import { Box } from "@mui/material";

import { Logo } from "../../../../Logo";
import { images } from "../../../../../assets";

const LogoSection = () => (
  <Box sx={{ maxWidth: "200px" }}>
    <Logo source={images.logo.sm} height={"50"} />
  </Box>
);

export default LogoSection;
