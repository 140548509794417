import React from "react";
import { Box, Typography } from "@mui/material";

const ShowSelectMessage = () => {
  return (
    <Box
      id="container"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 200px)",
      }}
    >
      <Box
        sx={{
          p: "32px",
          background: "#fff",
          maxWidth: "400px",
          borderRadius: "16px",
        }}
      >
        <Box sx={{ pb: "16px" }}>
          <Typography variant="h2">Select a show</Typography>
        </Box>
        <Box sx={{}}>
          <Typography variant="subtitle1">
            Please select a show from the dropdown above.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ShowSelectMessage;
