import { Avatar, Box, useMediaQuery } from "@mui/material";
import { IconMenu2 } from "@tabler/icons-react";

import { useCustomTheme } from "../../../../themes";
import { openDrawer } from "../../../../store/slices/menu";
import { dispatch, useSelector } from "../../../../store";

import LogoSection from "./LogoSection";
import SearchSection from "./SearchSection";
import LogoutSection from "./LogoutSection";
import AdminNavButtonSection from "./AdminNavButtonSection";

import ShowSelect from "components/ShowSelect/ShowSelect";

const Header = () => {
  const theme = useCustomTheme();
  const { drawerOpen } = useSelector((state) => state.menu);
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const user = useSelector((state) => state.menu.menuType);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: matchDownMd ? "space-between" : "",
        width: "100%",
      }}
    >
      {/* ------ LOGO SECTION ------- */}
      {!matchDownMd && (
        <Box
          sx={{
            width: "236px",
          }}
        >
          <LogoSection />
        </Box>
      )}
      {/* ------ MOBILE MENU SECTION ------- */}
      {matchDownMd && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            variant="rounded"
            sx={{
              overflow: "hidden",
              cursor: "pointer",
              transition: "all .2s ease-in-out",
              background: theme.palette.tertiary.light,
              color: theme.palette.primary.main,
              "&:hover": {
                background: theme.palette.primary.main,
                color: "#fff",
              },
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        </Box>
      )}
      <Box
        sx={{
          width: "calc(100vw - 260px)",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {/* ------ SEARCH INPUT SECTION ------- */}
          {user === "user" && <SearchSection />}

          {/* ------ NAV BUTTON SECTION ------- */}
          {!matchDownMd && user === "admin" && <AdminNavButtonSection />}

          {/* ------ SELECT SHOW SECTION ------- */}
          {user === "admin" && <ShowSelect />}

          {/* ------ LOGOUT SECTION ------- */}
          <LogoutSection />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
