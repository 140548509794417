import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import AdminList from "components/List/AdminList/AdminList";
import { getAdminUsers, getCurrentUser } from "store/slices/user";
import { useDispatch } from "store";
import { openCreateUserModal, openDeleteUserModal } from "store/slices/modal";
import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

interface ProductionsData {
  id: number;
  username: string;
  sso: string;
  roles: string;
}

const headCells: any = [
  {
    id: "username",
    numeric: false,
    label: "Username",
    align: "left",
  },
  {
    id: "sso",
    numeric: false,
    label: "User SSO",
    align: "left",
  },
  {
    id: "roles",
    numeric: false,
    label: "User Roles",
    align: "left",
  },
];

const Users = () => {
  const dispatch = useDispatch();

  const dataRows = useSelector(
    (state: any) => state.user.users.manualAdminUsers.users
  );

  useEffect(() => {
    dispatch(getAdminUsers());
    dispatch(getCurrentUser());
  }, [dispatch]);

  function createData(
    id: number,
    username: string,
    sso: string,
    roles: string
  ): ProductionsData {
    return { id: id, username: username, sso: sso, roles: roles };
  }

  const rows = dataRows.map(
    (
      row: { sub: string; userEmail: string; sso: string; roles?: string[] },
      index: number
    ) => {
      let rolesString = "";
      if (row.roles && row.roles.length > 1) {
        rolesString = row.roles.join(", ");
      }

      if (row.roles && row.roles.length === 1) {
        rolesString = row.roles[0];
      }
      return createData(Number(row.sso), row.userEmail, row.sso, rolesString);
    }
  );

  return (
    <Box sx={{ p: "16px", width: "100%", height: "100%" }}>
      <ShowTitleSection />
      <AdminList
        title={"Users"}
        categoryTitle={"Users"}
        rows={rows}
        editIcon={false}
        searchComponent={true}
        onDelete={openDeleteUserModal}
        onAdd={openCreateUserModal}
        headCells={headCells}
        searchProp="sso"
        disableDeleteAll={true}
      />
    </Box>
  );
};

export default Users;
