export interface Category {
  id: number;
  name: string | undefined;
}

export interface SubCategory {
  id: number;
  name: string;
}

export interface CategoryItem {
  category: Category | undefined;
  subCategories: SubCategory[];
}

export interface Region {
  id: number;
  name: string;
}

export const categories: CategoryItem[] = [
  {
    category: { id: 1, name: "Manual" },
    subCategories: [
      { id: 1, name: "Production Manual" },
      { id: 2, name: "Manual Chapters" },
    ],
  },
  {
    category: { id: 2, name: "Templates" },
    subCategories: [
      { id: 3, name: "UIS Production Pack" },
      { id: 4, name: "BALA Production Pack" },
      { id: 5, name: "UIS Additional Templates" },
      { id: 6, name: "Memos & Guidelines" },
      { id: 7, name: "Policies" },
    ],
  },
  { category: { id: 3, name: "Production Vendors" }, subCategories: [] },
];

export const regions: Region[] = [{ id: 1, name: "UK" }];
