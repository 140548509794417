export const DRAWER_WIDTH = 260;

export const USER_INSTRUCTION = `
To delete multiple documents, first, ensure to select each document by clicking 
on its checkbox. Then, proceed by clicking the delete all icon located at the top 
right corner of the list.
`;

//===========| Home |===========//
export const MANUAL_CHAPTERS = [
  "Production Start Up Meetings",
  "Production Set Up",
  "Crew & HODs",
  "Artists and Contributors",
  "Working & Filming with Children",
  "Production Finance, Budgets and Accounting",
  "Insurance",
  "Health & Safety, & International Security",
  "Engaging Vendors / Suppliers",
  "Sustainability and Albert Reporting",
  "Locations and International Filming",
  "Production Paperwork",
  "Unions and Guilds",
  "Diversity & Diamond Reporting",
  "Respectful Workplace",
  "Clearances",
  "Marketing, Press & Publicity",
  "Post Production",
  "Wrap",
  "Delivery",
];
export const DOC_REGIONS = ["All", "UK"];

//===========| Welcome |===========//
export const WELCOME_HEADER =
  "Welcome to Universal International Studio, Production Manual site.";
export const WELCOME_PARAS = [
  {
    text: `
    This website contains key information regarding UIS process, 
    systems, and procedure from pre-prep to delivery and beyond.
    `,
  },
  {
    text: `
    Please acknowledge receipt of the Production Manual, as we 
    require you to complete and sign the last page of the document, 
    returning it to your UIS Production contact. This website is a 
    necessary tool for the Producer, Line Producer, Production 
    and Accounting teams, Post Supervisor and Post Coordinator 
    to utilise during all phases of production.
    `,
  },
  {
    text: `
    This site also includes information which may be useful to all 
    crew working on the production and can be shared as necessary. 
    Where appropriate, the production manual signposts matters which 
    must be escalated and reported to Universal International Studios.
    `,
  },
  {
    text: `
    If you are ever in doubt, please contact the UIS Production Department.
    `,
  },
  {
    text: `
    Please familiarise yourself with the ‘Comcast Code of Conduct’; as 
    this policy forms the basis for all our processes and should be 
    understood and accepted by all engaged Production personnel. 
    `,
  },
  {
    text: `
    There is also an additional Production Finance Manual, Post 
    Production Manual and Health & Safety Manual which will be issued 
    by departmental representatives.
    `,
  },
];

//===========| Manual |===========//
export const MANUAL_PARAS = [
  {
    text: `
    Use the links below to open a PDF version of the Complete Text of 
    the Manual or the individual Manual Chapters.
    `,
  },
  {
    text: `
    Select the boxes and click the download button at the top of the 
    page to begin the download of multiple chapters or click the view 
    icon to open singular documents on your internet browser.
    `,
  },
];

//===========| Templates |===========//
export const TAB_LABELS = [
  "UIS Production Pack",
  "BALA Production Pack",
  "UIS Additional Templates",
  "Memos & Guidelines",
  "Policies",
];
export const TEMPLATES_PARAS = [
  {
    text: `
    Click the view icon to open singular documents on your internet browser. 
    You can also save the document directly to your computer by 
    selecting the download button.
    `,
  },
];

//===========| Redbook |===========//

//===========| Production Vendors |===========//
export const WELCOME_MESSAGE = "Welcome to Production Vendors!";
export const VENDORS_PARAS = [
  {
    text: `
    Once you have chosen vendors, you must send a list to your UIS 
    Production contact. This will be kept for UIS records and sent 
    to the relevant Cyber and Content Security personnel who will 
    inform production of any breach during your time working with 
    the vendor.
    `,
  },
  {
    text: `
    Please see the Production Manual for further information.
    `,
  },
];

//===========| Production Templates |===========//
export const PROD_TEMPLATE_TAB_LABELS = [
  "Welcome Message",
  "Contact",
  "Meetings",
  "Documents",
];

//===========| Policies |===========//
export const COMCAST_CODE_OF_CONDUCT = "Comcast code of conduct";
export const COMCAST_CODE_OF_CONDUCT_LINK =
  "https://corporate.comcast.com/impact/values-integrity/integrity/our-suppliers-and-business-partners";
