import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import {
  Formik,
  Form,
  Field,
  FormikErrors,
  FormikValues,
  FormikTouched,
  FieldProps,
  useField,
} from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { showClient } from "../../services/api/manual";

import { closeCreateTemplateProductionModal } from "store/slices/modal";
import { getShowsByProductionCompany } from "store/slices/show";
import { ProductionCompanyState } from "types/productionCompany";
import { ProductionCompany } from "types/show";
import { AppDispatch } from "store";
import { Template, TemplateState } from "types/template";

const FORM_FIELD_BG_COLOR = "#f0f2f5";

interface FormValues {
  showName: string;
  templateId: number | null;
}

const initialValues: FormValues = {
  showName: "",
  templateId: null,
};

const validationSchema = Yup.object({
  showName: Yup.string().required("Production name is required"),
  templateId: Yup.string().required("Template is required"),
});

const handleCreate = async (
  values: FormValues,
  selectedProductionCompany: ProductionCompany,
  dispatch: AppDispatch,
  setLoading: any,
  resetForm: any
) => {
  const { showName, templateId } = values;

  try {
    setLoading(true);
    await showClient.createShowFromTemplate(showName, templateId!);
    toast.success("Successfully added production");
    setLoading(false);
  } catch (error) {
    toast.error("Error adding production");
    console.error("Error in creating show:", error);
  } finally {
    if (selectedProductionCompany.productionCompanyId) {
      dispatch(
        getShowsByProductionCompany(
          selectedProductionCompany.productionCompanyId
        )
      );
    }
    dispatch(
      getShowsByProductionCompany(
        selectedProductionCompany.productionCompanyId!
      )
    );
    dispatch(closeCreateTemplateProductionModal());
    resetForm();
  }
};

interface FormikSelectProps extends FieldProps {
  label: string;
  children: React.ReactNode;
}

const FormikSelect = ({ label, children, ...props }: FormikSelectProps) => {
  const [field, meta] = useField(props.field.name);

  return (
    <FormControl
      sx={{
        "& .MuiSelect-select": {
          background: FORM_FIELD_BG_COLOR,
        },
        "& .MuiFormHelperText-root": { background: "#fff" },
      }}
      fullWidth
      error={meta.touched && !!meta.error}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props.field}
        label={label}
        error={meta.touched && !!meta.error}
      >
        {children}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

interface ProductionFormFieldsProps {
  values: FormValues;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

// Component for production form fields
const ProductionFormFields = ({
  values,
  handleChange,
  errors,
  touched,
}: ProductionFormFieldsProps) => {
  const templates = useSelector(
    (state: TemplateState) => state.templates.templates
  );

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ p: "16px 0px" }}>
          <Typography variant="h4">Production</Typography>
        </Box>
        <Field
          as={TextField}
          name="showName"
          fullWidth
          label="Production Name"
          value={values.showName}
          onChange={handleChange}
          error={Boolean(errors.showName && touched.showName)}
          helperText={touched.showName && errors.showName}
          sx={{
            "& .MuiOutlinedInput-input": {
              background: FORM_FIELD_BG_COLOR,
            },
            "& .MuiFormHelperText-root": { background: "#fff" },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Field
          component={FormikSelect}
          name="templateId"
          label="Template"
          fullWidth
          error={Boolean(
            errors.documentSubCategory && touched.documentSubCategory
          )}
          helperText={touched.documentSubCategory && errors.documentSubCategory}
        >
          {templates.map((template: Template) => (
            <MenuItem sx={{ p: "0px" }} key={nanoid()} value={template.id}>
              <Typography sx={{ p: "8px 16px" }}>{template.name}</Typography>
            </MenuItem>
          ))}
        </Field>
      </Grid>
    </>
  );
};

// The main component
const CreateTemplateProductionModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedProductionCompany = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany
  );
  const isCreateTemplateProductionModalOpen = useSelector(
    (state: any) => state.modal.createTemplateProductionModalOpen
  );

  return (
    <Dialog
      open={isCreateTemplateProductionModalOpen}
      onClose={() => dispatch(closeCreateTemplateProductionModal())}
    >
      <DialogTitle variant="h2" sx={{ p: "16px" }}>
        Create a production
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (selectedProductionCompany?.productionCompanyId) {
            handleCreate(
              values,
              selectedProductionCompany,
              dispatch,
              setLoading,
              resetForm
            );
          }
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, errors, touched, resetForm }) => (
          <Form id="create-production-form">
            <Grid
              container
              spacing={3}
              sx={{
                p: "16px",
                width: "100%",
                margin: "0px",
                "& .MuiGrid-item": { p: "8px 0px" },
              }}
            >
              <ProductionFormFields
                {...{ values, handleChange, errors, touched }}
              />

              <Grid item xs={12}>
                <Grid sx={{ display: "flex", justifyContent: "end" }} container>
                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{
                        boxShadow: "none",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#000",
                          }}
                          size={20}
                        />
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </Grid>

                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={loading}
                      onClick={() => {
                        dispatch(closeCreateTemplateProductionModal());
                      }}
                      sx={{
                        boxShadow: "none",
                        background: "#fff",
                        color: "#000",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateTemplateProductionModal;
