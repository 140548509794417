import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

import { dispatch } from "../index";
import { showClient } from "../../services/api/manual";

import {
  setIsLoggedIn,
  setLoggedInCompany,
  setLoggedInShow,
  setRole,
} from "./manualUser";

import { Show, PublicShowListItem } from "types/show";
import { DefaultRootStateProps } from "types";
import { tokenStorage } from "services/storage";

const initialState: DefaultRootStateProps["show"] = {
  error: null,
  show: {} as Show,
  selectedShow: {
    showId: null,
    showName: null,
  },
  publicShows: [],
  productionCompanyShows: [],
};

const showSlice = createSlice({
  name: "show",
  initialState,
  reducers: {
    getShowSuccess(state, action: PayloadAction<Show>) {
      state.show = action.payload;
    },
    getPublicShowsSuccess(state, action: PayloadAction<PublicShowListItem[]>) {
      state.publicShows = action.payload;
    },
    getProductionCompanyShowsSuccess(state, action: PayloadAction<Show[]>) {
      state.productionCompanyShows = action.payload;
    },
    setSelectedShow(state, action: PayloadAction<PublicShowListItem | null>) {
      state.selectedShow = action.payload;
    },
    hasError(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
  },
});

export function getShow(showId: number, navigate: NavigateFunction) {
  return async () => {
    return showClient
      .getShowByID(showId)
      .then((show) => {
        dispatch(showSlice.actions.getShowSuccess(show));
      })
      .catch((error) => {
        dispatch(showSlice.actions.hasError(error));
        if (error.response && error.response.status === 401) {
          console.log(error);

          dispatch(setIsLoggedIn(false));
          dispatch(setSelectedShow({ showId: null, showName: null }));
          dispatch(setLoggedInCompany(null));
          dispatch(setLoggedInShow(null));
          dispatch(setRole(null));

          toast.success("Session expired, please login again.");
          tokenStorage.removeToken();
          navigate("/manual/login");
        }
      });
  };
}

export function getPublicShows() {
  return async () => {
    return showClient
      .getPublicShows()
      .then((data) => {
        dispatch(showSlice.actions.getPublicShowsSuccess(data as []));
      })
      .catch((error: any) => {
        dispatch(showSlice.actions.hasError(error));
      });
  };
}

export function getShowsByProductionCompany(productionCompanyId: number) {
  return async () => {
    return showClient
      .getShows()
      .then((data) => {
        return data.filter(
          (show: Show) =>
            show.productionCompany.productionCompanyId === productionCompanyId
        );
      })
      .then((data) => {
        dispatch(
          showSlice.actions.getProductionCompanyShowsSuccess(data as [])
        );
      })
      .catch((error: any) => {
        dispatch(showSlice.actions.hasError(error));
      });
  };
}

export const {
  getShowSuccess,
  getPublicShowsSuccess,
  getProductionCompanyShowsSuccess,
  setSelectedShow,
  hasError,
} = showSlice.actions;

export default showSlice.reducer;
