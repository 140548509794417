import * as React from "react";
import { nanoid } from "nanoid";

import { hodData } from "./hod.data";
import { HodList } from "./HodList";

export const HodSection = () => {
  return (
    <>
      {hodData.map((item) => {
        return (
          <div
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            key={nanoid()}
            className="flex min-h-[650px] text-white laptop:flex-row tablet:flex-col phone:flex-col"
          >
            <div className=" flex p-[50px] justify-center items-center z-10">
              <div className="flex justify-center  items-center border-r-2 p-[20px] laptop:w-[300px] laptop:border-r-2 laptop:border-b-0 tablet:border-b-2 tablet:border-r-0 phone:border-b-2 phone:border-r-0">
                <h1 className="text-right font-bold tablet:text-center phone:text-center laptop:text-[42px] tablet:text-[42px] phone:text-[32px]">
                  {item.mainTitle}
                </h1>
              </div>
            </div>
            <div className="flex flex-col justify-center p-[50px] z-10">
              <h1 className="pb-[10px] font-bold text-[18px]">
                {item.secondaryTitle}
              </h1>
              {item.lists.map((item) => {
                return (
                  <HodList
                    key={nanoid()}
                    title={item.listTitle}
                    bullets={item.bullets}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
