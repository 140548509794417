import React from "react";
import { Box } from "@mui/material";

import List from "components/List/UserList/List";

const Documents = () => {
  return (
    <Box sx={{ p: "16px", minHeight: "calc(100vh - 200px)" }}>
      <List
        title={"All Documents"}
        subtitleParas={[]}
        category={""}
        subcategory={""}
        searchComponent={false}
      />
    </Box>
  );
};

export default Documents;
