import { AppBar, Box, Toolbar, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";

import { useCustomTheme } from "../../../themes";
import ManualFooter from "../../ManualFooter/ManualFooter";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Modals from "./Modals/Modals";

import MeetingModal from "components/Modals/MeetingModal";

const ProductionManualLayout = () => {
  const theme = useCustomTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: "#fff",
          borderBottom: `1px solid ${theme.palette.tertiary.main}`,
        }}
      >
        <Toolbar
          sx={{
            p: !matchDownMd ? "16px" : "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Header />
        </Toolbar>
      </AppBar>
      <Sidebar />
      <Box
        sx={{
          marginTop: matchDownMd ? "90px" : "109px",
          flexGrow: 1,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          minHeight: "100%",
          marginRight: "20px",
          p: "32px",
          pb: "0",
          bgcolor: theme.palette.tertiary.light,
          borderRadius: "8px 8px 0 0",
          border: `1px solid ${theme.palette.tertiary.main}`,
        }}
      >
        <Outlet />
        <ManualFooter />
      </Box>

      <Modals />
      <MeetingModal />
    </Box>
  );
};

export default ProductionManualLayout;
