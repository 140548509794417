import { List, useMediaQuery } from "@mui/material";
import React, { JSXElementConstructor } from "react";
import {
  IconHome,
  IconBook,
  IconForms,
  IconBuildingStore,
  IconMovie,
  IconUser,
  IconTemplate,
} from "@tabler/icons-react";

import NavItem from "../NavItem";

import { useSelector } from "store";
import { useCustomTheme } from "themes";

const PROD_TEMPLATES = "Production Templates";

interface SubMenuItem {
  id: string;
  title: string;
  url: string;
}

interface MenuItem {
  id: string;
  title: string;
  url: string;
  icon?: React.ReactElement<any, string | JSXElementConstructor<any>> | null;
  subItems?: SubMenuItem[];
}

export const menuItems: MenuItem[] = [
  { id: "1", title: "Home", url: "/manual", icon: <IconHome /> },
  { id: "2", title: "Manual", url: "/manual/manual", icon: <IconBook /> },
  {
    id: "3",
    title: "Templates",
    url: "/manual/templates",
    icon: <IconForms />,
  },
  {
    id: "4",
    title: "Production Vendors",
    url: "/manual/vendors",
    icon: <IconBuildingStore />,
  },
  // { id: "5", title: "Redbook", url: "/manual/redbook", icon: <IconLetterR /> },
];

export const adminMenuItems: MenuItem[] = [
  {
    id: "1",
    title: "Home",
    url: "/manual/admin/home",
    icon: <IconHome stroke={2} size={"22px"} />,
    subItems: [
      { id: "1-1", title: "Welcome", url: "/manual/admin/home/welcome" },
      { id: "1-2", title: "Contact", url: "/manual/admin/home/contact" },
      { id: "1-3", title: "Meetings", url: "/manual/admin/home/meetings" },
    ],
  },
  {
    id: "2",
    title: "Manual",
    url: "/manual/admin/manual",
    icon: <IconBook stroke={2} size={"22px"} />,
  },
  {
    id: "3",
    title: "Templates",
    url: "/manual/admin/templates",
    icon: <IconForms stroke={2} size={"22px"} />,
  },
  {
    id: "4",
    title: "Production Vendors",
    url: "/manual/admin/vendors",
    icon: <IconBuildingStore stroke={2} size={"22px"} />,
  },
  {
    id: "5",
    title: "Productions",
    url: "/manual/admin/productions",
    icon: <IconMovie stroke={2} size={"22px"} />,
  },
  {
    id: "6",
    title: "Users",
    url: "/manual/admin/users",
    icon: <IconUser stroke={2} size={"22px"} />,
  },
  {
    id: "7",
    title: PROD_TEMPLATES,
    url: "/manual/admin/production-templates",
    icon: <IconTemplate stroke={2} size={"22px"} />,
  },
];

const MenuList: React.FC = () => {
  const menuType = useSelector((state: any) => state.menu.menuType);
  const theme = useCustomTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const renderNavItems = (items: MenuItem[]) =>
    items.map((item) => (
      <NavItem
        key={item.id}
        id={item.id}
        title={item.title}
        url={item.url}
        icon={item.icon ?? <IconHome />}
        subItems={item.subItems}
      />
    ));

  const renderConditionalNavItems = (
    items: MenuItem[]
  ): React.ReactElement[] => {
    const alwaysRenderItems = items
      .filter(
        (item) =>
          item.title !== "Productions" &&
          item.title !== "Users" &&
          item.title !== PROD_TEMPLATES
      )
      .map((item) => (
        <NavItem
          key={item.id}
          id={item.id}
          title={item.title}
          url={item.url}
          icon={item.icon ?? <IconHome />}
          subItems={item.subItems}
        />
      ));

    const conditionallyRenderItems = items
      .filter(
        (item) =>
          item.title === "Productions" ||
          item.title === "Users" ||
          item.title === PROD_TEMPLATES
      )
      .map((item) =>
        matchDownMd ? (
          <NavItem
            key={item.id}
            id={item.id}
            title={item.title}
            url={item.url}
            icon={item.icon ?? <IconHome />}
            subItems={item.subItems}
          />
        ) : null
      )
      .filter((item): item is React.ReactElement => item !== null);

    return [...alwaysRenderItems, ...conditionallyRenderItems];
  };

  return (
    <List sx={{ pt: 0 }}>
      {menuType === "user" && renderNavItems(menuItems)}
      {menuType === "admin" && renderConditionalNavItems(adminMenuItems)}
    </List>
  );
};

export default MenuList;
