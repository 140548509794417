import React from "react";
import { Box, Typography } from "@mui/material";
import { nanoid } from "nanoid";

import { VENDORS_PARAS, WELCOME_MESSAGE } from "../../../../constants";

import List from "components/List/UserList/List";
import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

const ProductionVendorsInfo = () => {
  return (
    <Box sx={{ p: "16px" }}>
      <Typography variant="h4" gutterBottom>
        {WELCOME_MESSAGE}
      </Typography>
      <Typography variant="body1" paragraph>
        The below list contains information on vendors who have met UIS due
        diligence required checks relating to{" "}
        <b style={{ fontFamily: "inherit" }}>
          Cyber Security, Content Security
        </b>{" "}
        and <b style={{ fontFamily: "inherit" }}>Privacy review.</b>
      </Typography>
      <Typography>
        If a proposed vendor is <b style={{ fontFamily: "inherit" }}>not</b>{" "}
        featured on the below list,{" "}
        <b style={{ fontFamily: "inherit" }}>please urgently speak to UIS</b> to
        initiate relevant vendor checks and ensure they are compliant in the
        required operational standards.
      </Typography>

      {VENDORS_PARAS.map((para) => {
        return (
          <Typography key={nanoid()} variant="body1" paragraph>
            {para.text}
          </Typography>
        );
      })}
    </Box>
  );
};

const ProductionVendors = () => {
  return (
    <Box sx={{ p: "16px", minHeight: "calc(100vh - 200px)" }}>
      <ShowTitleSection />
      <List
        title={"Production Vendors"}
        subtitleParas={[]}
        category={"Production Vendors"}
        subcategory={""}
        headingInfoComponent={<ProductionVendorsInfo />}
        searchComponent={false}
      />
    </Box>
  );
};

export default ProductionVendors;
