import { useSelector } from "react-redux";

import { ManualUserProps } from "types/manualUser";

const useAuth = () => {
  const authState = useSelector(
    (state: any): ManualUserProps => state.manualUser
  );

  if (!authState) throw new Error("Authentication state must be available");

  const { isLoggedIn, loggedInCompany, loggedInShow, role, consent } =
    authState;

  return { isLoggedIn, loggedInCompany, loggedInShow, role, consent };
};

export default useAuth;
