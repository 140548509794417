import React from "react";

import useModalState from "../../../../hooks/useModalState";

import CreateUserModal from "components/Modals/CreateUserModal";
import DeleteUserModal from "components/Modals/DeleteUserModal";

const UserModals = () => {
  const modalState = useModalState();

  return (
    <>
      {modalState.createUserModalOpen && <CreateUserModal />}
      {modalState.deleteUserModalOpen && <DeleteUserModal />}
    </>
  );
};

export default UserModals;
