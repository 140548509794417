import { useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { IconLogout } from "@tabler/icons-react";

import { useCustomTheme } from "themes";
import { useDispatch } from "store";
import {
  setIsLoggedIn,
  setLoggedInCompany,
  setLoggedInShow,
  setRole,
} from "store/slices/manualUser";
import { tokenStorage } from "services/storage";
import { setSelectedShow } from "store/slices/show";
import { getTemplateSuccess } from "store/slices/templates";

const LogoutSection = () => {
  const theme = useCustomTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    try {
      dispatch(setIsLoggedIn(false));
      tokenStorage.removeToken();
      navigate("/");
      toast.success("Logout successful!");
      dispatch(
        setSelectedShow({
          showId: null,
          showName: null,
        })
      );
      dispatch(setLoggedInCompany(null));
      dispatch(setLoggedInShow(null));
      dispatch(setRole(null));
      dispatch(getTemplateSuccess(null));
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, navigate]);

  return (
    <Box>
      <Button
        onClick={handleLogout}
        sx={{
          ml: "16px",
          p: "11px",
          bgcolor: theme.palette.tertiary.light,
          "&:hover": {
            bgcolor: theme.palette.primary.main,
            color: "#fff",
          },
          transition: "background-color 0.3s ease",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!matchDownMd && <Typography sx={{ mr: "6px" }}>Logout</Typography>}
          <IconLogout stroke={1} size={"20px"} />
        </Box>
      </Button>
    </Box>
  );
};

export default LogoutSection;
