import { useEffect } from "react";
import { nanoid } from "nanoid";
import { IconChevronDown } from "@tabler/icons-react";
import { Box, Grid, List, Typography } from "@mui/material";

import { useCustomTheme } from "../../../../../themes";

import UpdatesItem from "./UpdatesItem";

import { useSelector, useDispatch } from "store";
import { groupUpdatesByCategory } from "utils/groupByCategory";
import { getUpdates } from "store/slices/updates";
import { updatesTimePeriod } from "utils/updatesTimePeriod";

interface UpdatesProps {
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
}

const Updates = ({ lg, md, sm, xs }: UpdatesProps) => {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const updates = useSelector((state: any) => state.updates.updates);
  const groupedUpdates = groupUpdatesByCategory(updates);
  const showId = useSelector((state: any) => state.show.selectedShow.showId);

  useEffect(() => {
    dispatch(getUpdates(showId));
  }, [dispatch, showId]);

  if (updates.length > 0) {
    return (
      <Grid lg={lg} md={md} sm={sm} xs={xs} item>
        <Box
          sx={{
            maxHeight: "600px",
            overflow: "scroll",
            bgcolor: "#fff",
            borderRadius: "8px",
            border: `1px solid ${theme.palette.tertiary.main}`,
          }}
        >
          <Box
            sx={{
              p: "16px",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: `1px solid ${theme.palette.tertiary.main}`,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ pr: 1 }}>DOCUMENT UPDATES</Typography>
              <IconChevronDown size={20} stroke={2} />
            </Box>

            <Typography>{updatesTimePeriod()}</Typography>
          </Box>
          <List sx={{ p: "16px" }}>
            {Object.entries(groupedUpdates).map(([category, updates]) => (
              <UpdatesItem
                key={nanoid()}
                category={category}
                updatedDocuments={updates}
              />
            ))}
          </List>
        </Box>
      </Grid>
    );
  }

  return null;
};

export default Updates;
