import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { loginWithPolicyAndPassword } from "../api/login";
import { loginPolicyUser } from "../../../../store/slices/user";

const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
});

export const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const policy = useSelector((state: any) => state.policy.selectedPolicy);
  const currentRoles = useSelector(
    (state: any) => state.user.users.policiesUser.roles
  );

  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!policy || !policy.policyName) {
      navigate("/");
    }
  }, [policy, navigate]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        height: "500px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Formik
        initialValues={{ password: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
          setSubmitting(true);
          loginWithPolicyAndPassword({
            Password: values.password,
            Policy: policy!.policyCode,
          })
            .then((_res) => {
              const updatedRoles = [...currentRoles, policy?.policyCode].filter(
                Boolean
              );
              dispatch(
                loginPolicyUser({ isLoggedIn: true, roles: updatedRoles })
              );
              dispatch(
                loginPolicyUser({ isLoggedIn: true, roles: updatedRoles })
              );
              if (policy?.policyCode === "uis") {
                navigate(`/policies`);
              }
              if (policy?.policyCode === "mk") {
                navigate(`/monkeykingdom-policies`);
              }
              if (policy?.policyCode === "mp") {
                navigate(`/matchboxpicturesandtap-policies`);
              }
            })
            .catch((_error) => {
              setStatus("Oops, wrong password! Please try again.");
              resetForm();
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              {`${policy?.policyName} Policies`}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              This content is password protected. To view it please enter your
              password below:
            </Typography>
            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {touched.password && errors.password && (
                <FormHelperText error>{errors.password}</FormHelperText>
              )}
            </FormControl>
            {status && (
              <Typography color="error" sx={{ mt: 2 }}>
                {status}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{ mt: 2, boxShadow: "none" }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Login"}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
