import React, { useEffect, useState, useRef, useMemo } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import MainCard from "components/Cards/MainCard";
import { useCustomTheme } from "themes";
import { Contact, Show } from "types/show";
import { templateClient } from "services/api/manual/template";
import { ProductionCompanyState } from "types/productionCompany";
import { useDispatch } from "store";
import { getTemplateById } from "store/slices/templates";
import { TemplateState } from "types/template";

const FORM_FIELD_BG_COLOR = "#f0f2f5";

interface AdminContactFormProps {
  contact?: Contact;
  selectedShow?: Show;
  disableUpdate?: boolean;
}

interface FormValues {
  name: string;
  position: string;
  email: string;
  phone: string;
  info: string;
}

interface ResetFormProps {
  onReset: (resetValues: { values: FormValues }) => void;
}

export default function AdminTemplateContactForm({
  disableUpdate = false,
}: AdminContactFormProps) {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const formikRef = useRef<FormikProps<FormValues>>(null);

  const selectedTemplate = useSelector(
    (state: TemplateState) => state.templates?.selectedTemplate!
  );

  const selectedProdCompany = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany
  );

  const contact = selectedTemplate?.contact;

  const initialValues = useMemo(
    () => ({
      name: contact?.contactName || "",
      position: contact?.ContactPosition || "",
      email: contact?.contactEmail || "",
      phone: contact?.contactPhone || "",
      info: contact?.contactInfo || "",
    }),
    [contact]
  );

  useEffect(() => {
    if (selectedTemplate?.id && selectedProdCompany?.productionCompanyId) {
      dispatch(
        getTemplateById(
          selectedProdCompany.productionCompanyId,
          selectedTemplate.id
        )
      );
    }
  }, [
    dispatch,
    selectedTemplate?.id,
    selectedProdCompany?.productionCompanyId,
  ]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm({ values: initialValues });
    }
  }, [contact, initialValues]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    position: Yup.string().required("Required"),
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format")
      .required("Required"),
    phone: Yup.string().required("Required"),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const body = {
      ...selectedTemplate,
      contact: {
        ContactPosition: values.position,
        contactEmail: values.email,
        contactInfo: values.info,
        contactName: values.name,
        contactPhone: values.phone,
      },
    };
    setLoading(true);
    try {
      if (selectedTemplate) {
        await templateClient.updateTemplate(selectedTemplate?.id!, body);
        toast.success("Successfully updated template contact");
        setLoading(false);
      }
    } catch {
      toast.error("Error updating template contact");
      setLoading(false);
    }
  };

  const ResetForm = ({ onReset }: ResetFormProps) => {
    const handleReset = () => {
      onReset({
        values: {
          name: "",
          position: "",
          email: "",
          phone: "",
          info: "",
        },
      });
    };

    return (
      <Button
        onClick={handleReset}
        variant="outlined"
        sx={{
          mt: 2,
          ml: 1,
          p: "4px 8px",
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.secondary.light,
          "&:hover": {
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
          },
        }}
      >
        <Typography
          sx={{
            color: "inherit",
          }}
          variant="caption"
        >
          Clear Form
        </Typography>
      </Button>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MainCard
        sx={{
          borderRadius: "8px",
          width: "100%",
        }}
        title="Update contact"
        content={false}
        boxShadow={false}
        border={true}
        subtitle={"Please add or update the contact details for this show."}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {({ resetForm, errors, touched }) => (
            <Form>
              <CardContent>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                    <ResetForm
                      onReset={() =>
                        resetForm({
                          values: {
                            name: "",
                            position: "",
                            email: "",
                            phone: "",
                            info: "",
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Grid container spacing={2} sx={{ p: "16px" }}>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        background: FORM_FIELD_BG_COLOR,
                      },
                      "& .MuiFormHelperText-root": {
                        background: theme.palette.secondary.light,
                      },
                    }}
                    id="NameBasic"
                    name="name"
                    label="Name"
                    fullWidth
                    autoComplete="given-name"
                    error={Boolean(errors.name && touched.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        background: FORM_FIELD_BG_COLOR,
                      },
                      "& .MuiFormHelperText-root": {
                        background: theme.palette.secondary.light,
                      },
                    }}
                    id="positionBasic"
                    name="position"
                    label="Position"
                    fullWidth
                    autoComplete="position"
                    error={Boolean(errors.position && touched.position)}
                    helperText={touched.position && errors.position}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        background: FORM_FIELD_BG_COLOR,
                      },
                      "& .MuiFormHelperText-root": {
                        background: theme.palette.secondary.light,
                      },
                    }}
                    id="emailBasic"
                    name="email"
                    label="Email"
                    fullWidth
                    autoComplete="email"
                    error={Boolean(errors.email && touched.email)}
                    helperText={touched.position && errors.position}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        background: FORM_FIELD_BG_COLOR,
                      },
                      "& .MuiFormHelperText-root": {
                        background: theme.palette.secondary.light,
                      },
                    }}
                    id="phoneBasic"
                    name="phone"
                    label="Phone"
                    fullWidth
                    autoComplete="phone"
                    error={Boolean(errors.phone && touched.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: FORM_FIELD_BG_COLOR,
                      },
                      "& .MuiFormHelperText-root": {
                        background: theme.palette.secondary.light,
                      },
                    }}
                    id="infoBasic"
                    name="info"
                    label="Additional Information"
                    multiline
                    rows={4}
                    fullWidth
                    autoComplete="additional-information"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      sx={{
                        boxShadow: "none",
                        width: "20%",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={disableUpdate ? true : loading}
                    >
                      {loading ? (
                        <CircularProgress
                          sx={{
                            color: "#000",
                            "&:hover": {
                              color: "#000",
                            },
                          }}
                          size={20}
                        />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </MainCard>
    </Box>
  );
}
