export const MANUAL_AUTH_FORM_LABELS = {
  OPTION_SELECT: "Select a Show",
  PASSWORD: "Password",
  CHECKBOX_DESCRIPTION: `By clicking on this box, you represent, under penalty of
    law, that you are a current NBCUniversal employee or crew
    member working on the Show indicated above or have otherwise
    been authorized by NBCUniversal to access this
    website.Except as authorized by NBCUniversal, you will not
    republish, distribute, copy or otherwise make available any
    of this website’s contents. You further agree to be bound by
    the privacy policy and terms of use.`,
  SIGN_IN: "Sign In",
  SSO_SIGN_IN: "Sign In With SSO ",
  PASSWORD_REQUIRED: "Password is required",
  SELECTION_REQUIRED: "Selection is required",
  CHECKBOX_REQUIRED: "Required",
};
