import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconMovie, IconUser, IconTemplate } from "@tabler/icons-react";

import { dispatch } from "store";
import { activeItem } from "store/slices/menu";
import { getShowsByProductionCompany } from "store/slices/show";

const links = [
  {
    id: "1",
    title: "Productions",
    url: "/manual/admin/productions",
    icon: <IconMovie stroke={1} size={"20px"} />,
  },
  {
    id: "2",
    title: "Production Templates",
    url: "/manual/admin/production-templates",
    icon: <IconTemplate stroke={1} size={"20px"} />,
  },
  {
    id: "3",
    title: "Users",
    url: "/manual/admin/users",
    icon: <IconUser stroke={1} size={"20px"} />,
  },
];

function AdminNavButtonSection() {
  const theme = useTheme();
  const navigate = useNavigate();

  const productionCompanyId = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyId
  );

  return (
    <Box sx={{ pr: "16px" }}>
      {links.map((link) => (
        <Button
          key={link.url}
          onClick={() => {
            dispatch(activeItem({ id: 0, name: link.title }));
            dispatch(getShowsByProductionCompany(productionCompanyId));
            navigate(link.url);
          }}
          sx={{
            ml: "16px",
            bgcolor: theme.palette.tertiary.light,
            "&:hover": {
              bgcolor: theme.palette.primary.main,
              color: "#fff",
            },
            transition: "background-color 0.3s ease",
          }}
        >
          {link.icon}
          <Typography sx={{ mr: "6px", ml: "8px" }}>{link.title}</Typography>
        </Button>
      ))}
    </Box>
  );
}

export default AdminNavButtonSection;
