export const wellbeingData = {
  sections: [
    {
      sectionTitle: "Production HR Team",
      link: "#section1",
    },
    {
      sectionTitle: "Employee Assistance Programme",
      link: "#section2",
    },
    {
      sectionTitle: "Respect in the Workplace",
      link: "#section3",
    },
    {
      sectionTitle: "Raising a Concern",
      link: "#section4",
    },
    {
      sectionTitle: "Comcast NBCUniversal Reporting Line",
      link: "#section5",
    },
  ],
};

export const team = [
  { id: 1, details: "VP, Human Resources: Sara Swinney, +44 (0)7585 960903" },
  { id: 2, details: "HR Director: Charlie Oliver, +44 (0) 7831 608686" },
  { id: 3, details: "Senior HR Manager: Britt Duncan, +44 (0) 7990400230" },
  { id: 4, details: "HR Manager: Zoe Mansell, +44 (0) 7876 217130" },
  { id: 5, details: "HR Generalist: Tiffany Tee, +44 (0) 7810185187" },
  { id: 6, details: "HR Generalist: Eve Thurlow, TBC" },
  { id: 7, details: "HR Coordinator: Jess Kent, +44 (0) 7810185187" },
];

export const services = [
  { id: 1, details: "24/7 confidential support line." },
  { id: 2, details: "Telehealth sessions with trained counsellors." },
  { id: 3, details: "In person crisis support." },
  { id: 4, details: "In person/virtual training/guidance sessions." },
  {
    id: 5,
    details:
      "You can refer to your Call Sheet for details of your EAP or reach out to your Production team or HR if any questions.",
  },
  {
    id: 6,
    details:
      "All of our productions, cast and crew, have access to Work-Life Support: ComPsych. ComPsych Guidance Resources offers work-life support and resources. You can access up to 5 no cost, confidential counselling sessions 24/7 via the details below.",
  },
  {
    id: 7,
    details:
      "Get started here: call 1-888-515-4327 or visit guidanceresources.com (Web ID: CCC313), or use the 24/7 chat available online and through the app, GuidanceNow.",
  },
  {
    id: 8,
    details:
      "ComPsych Guidance Resources, our Employee Assistance Provider, offers work-life support and resources.",
  },
];
