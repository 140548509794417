import { Box, Grid, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import ContactComponent from "../components/Contact/Contact";
import Meetings from "../components/Meetings/Meetings";
import Welcome from "../components/Welcome/Welcome";
import Updates from "../components/Updates";
import { useCustomTheme } from "../../../../themes";
import { openDrawer } from "../../../../store/slices/menu";

import { dispatch } from "store";
import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

const ManualHome = () => {
  const theme = useCustomTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const selectedShowId = useSelector(
    (state: any) => state.show.selectedShow.showId
  );

  useEffect(() => {
    if (matchDownMd) {
      dispatch(openDrawer(false));
    }
  }, [matchDownMd]);

  if (!selectedShowId) {
    return null;
  }

  return (
    <Box
      sx={{ p: "16px", minHeight: "calc(100vh - 200px)", minWidth: "600px" }}
    >
      <ShowTitleSection />
      <Grid
        container
        spacing={2}
        sx={{
          flexWrap: matchDownMd ? "wrap" : "nowrap",
        }}
      >
        <Grid item md={8} sm={12}>
          <Grid container sx={{ gap: "16px" }}>
            <Welcome />
            {matchDownMd ? <ContactComponent xs={12} /> : <Updates xs={12} />}
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
          <Grid container sx={{ gap: "16px" }}>
            {matchDownMd ? <Meetings /> : <ContactComponent xs={12} />}
            {matchDownMd ? <Updates xs={12} /> : <Meetings />}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManualHome;
