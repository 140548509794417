import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { IconPhone, IconMail } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import { useCustomTheme } from "../../../../../themes";

import { useDispatch, useSelector } from "store";
import { getShow } from "store/slices/show";

interface ContactComponentProps {
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
}

const ContactComponent = ({ lg, md, sm, xs }: ContactComponentProps) => {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showId = useSelector((state: any) => state.show.selectedShow.showId);
  const show = useSelector((state: any) => state.show.show);

  useEffect(() => {
    dispatch(getShow(showId, navigate));
  }, [showId, dispatch, navigate]);

  return (
    <Grid lg={lg} md={md} sm={sm} xs={xs} item>
      <Box
        sx={{
          minHeight: "100%",
          bgcolor: "#fff",
          borderRadius: "8px",
          border: `1px solid ${theme.palette.tertiary.main}`,
        }}
      >
        <Box
          sx={{
            p: "16px",
            minHeight: "57px",
            borderBottom: `1px solid ${theme.palette.tertiary.main}`,
          }}
        >
          <Typography>PRODUCTION CONTACT</Typography>
        </Box>
        <Box sx={{ p: "16px" }}>
          <Box sx={{ pb: "16px" }}>
            <Typography variant="h3">{show.contact?.contactName}</Typography>
          </Box>
          <Box sx={{ pb: "16px" }}>
            <Typography sx={{ fontSize: "14px" }}>
              {show.contact?.ContactPosition}
            </Typography>
          </Box>
          <Box>
            {show.contact?.contactPhone !== "" && (
              <Box sx={{ pb: "12px", display: "flex", alignItems: "center" }}>
                <IconPhone stroke={1.5} size="16px" />
                <Typography sx={{ ml: "8px" }} variant="body1">
                  {show.contact?.contactPhone}
                </Typography>
              </Box>
            )}
            {show.contact?.contactEmail !== "" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& svg": {
                    mr: "8px",
                  },
                }}
              >
                <IconMail stroke={1.5} size="16px" />
                <Typography variant="body1">
                  {show.contact?.contactEmail}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContactComponent;
