import SelectionTab from "../components/SelectionTab";

const ModifyTemplates = () => {
  return (
    <>
      <SelectionTab />
    </>
  );
};

export default ModifyTemplates;
