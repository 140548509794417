import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { dispatch } from "../index";

import { DefaultRootStateProps } from "types";
import { documentClient } from "services/api/manual";

const initialState: DefaultRootStateProps["updates"] = {
  error: null,
  updates: { recentlyUpdatedDocuments: [], showId: null },
};

const UpdatesSlice = createSlice({
  name: "updates",
  initialState,
  reducers: {
    hasError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    getUpdatesSuccess(state, action: PayloadAction<any>) {
      state.updates = action.payload;
    },
  },
});

export function getUpdates(showId: number) {
  return async () => {
    return documentClient
      .getUpdates(showId)
      .then((data) => dispatch(UpdatesSlice.actions.getUpdatesSuccess(data)))
      .catch((error: any) => {
        dispatch(UpdatesSlice.actions.hasError(error));
      });
  };
}

export default UpdatesSlice.reducer;
