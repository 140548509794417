import React from "react";

import useModalState from "../../../../hooks/useModalState";

import CreateDocumentModal from "components/Modals/CreateDocumentModal";
import CreateTemplateDocumentModal from "components/Modals/CreateTemplateDocumentModal";
import UpdateDocumentModal from "components/Modals/UpdateDocumentModal";
import DeleteDocumentModal from "components/Modals/DeleteDocumentModal";
import DeleteTemplateDocumentModal from "components/Modals/DeleteTemplateDocumentModal";

const DocumentModals = () => {
  const modalState = useModalState();

  return (
    <>
      {modalState.createDocumentModalOpen && <CreateDocumentModal />}
      {modalState.createTemplateDocumentModalOpen && (
        <CreateTemplateDocumentModal />
      )}
      {modalState.updateDocumentModalOpen && <UpdateDocumentModal />}
      {modalState.deleteDocumentModalOpen && <DeleteDocumentModal />}
      {modalState.deleteTemplateDocumentModalOpen && (
        <DeleteTemplateDocumentModal />
      )}
    </>
  );
};

export default DocumentModals;
