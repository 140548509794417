import * as React from "react";
import { clsx } from "clsx";

type HeadingProps = {
  title: string;
  className?: string;
};

export const Heading = ({ title, className, ...props }: HeadingProps) => {
  return (
    <div
      className={clsx(`flex items-center mb-[40px] text-black h-10`, className)}
    >
      <h1 className="font-extrabold">{title}</h1>
    </div>
  );
};
