import * as React from "react";
import { nanoid } from "nanoid";

type HodListProps = {
  title: string | JSX.Element;
  bullets: (string | JSX.Element)[];
};

export const HodList = ({ title, bullets }: HodListProps) => {
  return (
    <div className="text-white">
      <h1 className="pb-[10px] font-bold">{title}</h1>
      <ul className="pb-[10px]">
        {bullets.map((item) => {
          return (
            <li className="text-[14px] pb-[4px]" key={nanoid()}>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
