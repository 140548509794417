import { useState } from "react";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Field, Form, Formik, useField } from "formik";
import * as Yup from "yup";

import { useDispatch } from "store";
import { closeCreateUserModal } from "store/slices/modal";
import { userClient } from "services/api/manual/user";
import { getAdminUsers } from "store/slices/user";

const FORM_FIELD_BG_COLOR = "#f0f2f5";

interface FormValues {
  userSso: string;
  userEmail: string;
  group: string[];
}

const userGroups = ["APG-IMO_Admin", "APG-IMO_Principle_Admin"];

const FormikCheckbox = ({ label, value, setFieldValue }: any) => {
  const [field, meta] = useField({ name: "group" });

  return (
    <FormControl
      error={meta.touched && !!meta.error}
      component="fieldset"
      variant="standard"
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={
                Array.isArray(field.value) && field.value.includes(value)
              }
              onChange={() => {
                const set = new Set(field.value || []);
                if (set.has(value)) {
                  set.delete(value);
                } else {
                  set.add(value);
                }
                setFieldValue(field.name, Array.from(set));
              }}
              name={field.name}
              color="primary"
            />
          }
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};

const CreateUserModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const createUserModalOpen = useSelector(
    (state: any) => state.modal.createUserModalOpen
  );

  const initialValues: FormValues = {
    userSso: "",
    userEmail: "",
    group: [],
  };

  const validationSchema = Yup.object().shape({
    userSso: Yup.string()
      .matches(/^\d{9}$/, "SSO must be exactly 9 digits")
      .required("SSO is required"),
    userEmail: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    group: Yup.array()
      .of(Yup.string())
      .min(1, "At least one group is required"),
  });

  const handleCreate = async (values: FormValues) => {
    const data = {
      IsDeleted: false,
      groupNames: values.group,
      memberSSO: values.userSso,
      memberEmail: values.userEmail,
    };

    if (values) {
      try {
        setLoading(true);
        await userClient.modifyUser(data);
        setLoading(false);
        toast.success("Successfully Added User");
        dispatch(getAdminUsers());
      } catch (error) {
        toast.error("Error creating user");
        console.error("Error in creating user:", error);
      } finally {
        dispatch(closeCreateUserModal());
      }
    }
  };

  const onSubmit = (values: FormValues) => {
    handleCreate(values);
  };

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: "500px",
        },
      }}
      open={createUserModalOpen}
      onClose={() => dispatch(closeCreateUserModal())}
    >
      <DialogTitle variant="h2" sx={{ p: "16px" }}>
        Add a User
      </DialogTitle>

      <Box sx={{ p: "0px 0px 16px 16px" }}>
        <Typography variant="caption">
          Please fill all required fields below.
        </Typography>
      </Box>

      <Box>
        <Divider />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <Form id="create-User-form">
            <Grid
              container
              spacing={3}
              sx={{
                p: "16px",
                width: "100%",
                margin: "0px",
                "& .MuiGrid-item": { p: "8px 0px" },
              }}
            >
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="userSso"
                  name="userSso"
                  label="User's SSO"
                  fullWidth
                  error={Boolean(errors.userSso && touched.userSso)}
                  helperText={touched.userSso && errors.userSso}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="userEmail"
                  name="userEmail"
                  label="User's Email"
                  fullWidth
                  error={Boolean(errors.userEmail && touched.userEmail)}
                  helperText={touched.userEmail && errors.userEmail}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">User Groups</Typography>
                {userGroups.map((group: string) => (
                  <Field
                    key={nanoid()}
                    component={FormikCheckbox}
                    name="group"
                    label={group}
                    value={group}
                    setFieldValue={setFieldValue}
                  />
                ))}
                {touched.group && errors.group ? (
                  <Box>
                    <Typography
                      sx={{ color: "#d32f2f", fontSize: "12px", p: "0px 14px" }}
                    >
                      {errors.group}
                    </Typography>
                  </Box>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <Grid sx={{ display: "flex", justifyContent: "end" }} container>
                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{
                        boxShadow: "none",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#000",
                          }}
                          size={20}
                        />
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </Grid>

                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={() => {
                        dispatch(closeCreateUserModal());
                      }}
                      sx={{
                        boxShadow: "none",
                        background: "#fff",
                        color: "#000",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateUserModal;
