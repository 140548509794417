import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { userClient } from "services/api/manual/user";
import { AdminUser, AuthUser, CurrentUser } from "types/user";

interface UserState {
  error: null | Error;
  users: {
    policiesUser: {
      isLoggedIn: boolean;
      roles: string[];
    };
    manualAdminUsers: {
      currentUser: CurrentUser | {};
      users: AdminUser[] | [];
    };
  };
}

const initialState: UserState = {
  error: null,
  users: {
    policiesUser: {
      isLoggedIn: false,
      roles: [],
    },
    manualAdminUsers: {
      currentUser: {},
      users: [],
    },
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getAdminUsersSuccess(state, action: PayloadAction<AdminUser[]>) {
      state.users.manualAdminUsers.users = action.payload;
    },
    getCurrentUserSuccess(state, action: PayloadAction<CurrentUser>) {
      state.users.manualAdminUsers.currentUser = action.payload;
    },
    loginPolicyUser: (state, action: PayloadAction<AuthUser>) => {
      state.users.policiesUser.isLoggedIn = action.payload.isLoggedIn;
      state.users.policiesUser.roles = action.payload.roles;
    },
    logoutPolicyUser: (state) => {
      state.users.policiesUser.isLoggedIn = false;
      state.users.policiesUser.roles = [];
    },
  },
});

export const getPolicyUser = (state: any): UserState["users"] =>
  state.user.users.policiesUser;

export function getCurrentUser() {
  return async (dispatch: any) => {
    userClient
      .getCurrentUser()
      .then((currentUser) => {
        dispatch(userSlice.actions.getCurrentUserSuccess(currentUser));
      })
      .catch((error) => {
        dispatch(userSlice.actions.hasError(error));
      });
  };
}

export function getAdminUsers() {
  return async (dispatch: any) => {
    userClient
      .getAdminUsers()
      .then((adminUsers) => {
        dispatch(userSlice.actions.getAdminUsersSuccess(adminUsers));
      })
      .catch((error) => {
        dispatch(userSlice.actions.hasError(error));
      });
  };
}

export const { loginPolicyUser, logoutPolicyUser } = userSlice.actions;

export default userSlice.reducer;
