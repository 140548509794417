import logo_image_small from "../assets/images/home/logo-small.png";
import logo_white_image_small from "../assets/images/home/logo-white-small.png";
import hero_image_small from "../assets/images/home/hero-small.png";
import hero_image_medium from "../assets/images/home/hero-medium.png";
import hero_image_large from "../assets/images/home/hero-large.png";
import hero_image_xl from "../assets/images/home/hero-xl.png";
import brands_image_small from "../assets/images/home/brands-small.png";
import brands_image_medium from "../assets/images/home/brands-medium.png";
import brands_image_large from "../assets/images/home/brands-large.png";
import brands_image_xl from "../assets/images/home/brands-xl.png";
import image_1_small from "../assets/images/home/show_1-small.jpg";
import image_1_meduim from "../assets/images/home/show_1-medium.jpg";
import image_1_large from "../assets/images/home/show_1-large.jpg";
import image_1_xl from "../assets/images/home/show_1-xl.jpg";
import image_2_small from "../assets/images/home/show_2-small.jpg";
import image_2_meduim from "../assets/images/home/show_2-medium.jpg";
import image_2_large from "../assets/images/home/show_2-large.jpg";
import image_2_xl from "../assets/images/home/show_2-xl.jpg";
import image_3_small from "../assets/images/home/show_3-small.jpg";
import image_3_meduim from "../assets/images/home/show_3-medium.jpg";
import image_3_large from "../assets/images/home/show_3-large.jpg";
import image_3_xl from "../assets/images/home/show_3-xl.jpg";
import image_4_small from "../assets/images/home/show_4-small.jpg";
import image_4_meduim from "../assets/images/home/show_4-medium.jpg";
import image_4_large from "../assets/images/home/show_4-large.jpg";
import image_4_xl from "../assets/images/home/show_4-xl.jpg";
import image_5_small from "../assets/images/home/show_5-small.jpg";
import image_5_meduim from "../assets/images/home/show_5-medium.jpg";
import image_5_large from "../assets/images/home/show_5-large.jpg";
import image_5_xl from "../assets/images/home/show_5-xl.jpg";
import image_6_small from "../assets/images/home/show_6-small.jpg";
import image_6_meduim from "../assets/images/home/show_6-medium.jpg";
import image_6_large from "../assets/images/home/show_6-large.jpg";
import image_6_xl from "../assets/images/home/show_6-xl.jpg";
import image_7_small from "../assets/images/home/show_7-small.jpg";
import image_7_meduim from "../assets/images/home/show_7-medium.jpg";
import image_7_large from "../assets/images/home/show_7-large.jpg";
import image_7_xl from "../assets/images/home/show_7-xl.jpg";
import wellbeing_image_1 from "../assets/images/wellbeing/section_1_image_1.png";
import wellbeing_image_2 from "../assets/images/wellbeing/section_1_image_2.png";
import wellbeing_image_3 from "../assets/images/wellbeing/section_1_image_3.png";
import wellbeing_image_4 from "../assets/images/wellbeing/section_2_image_1.png";
import wellbeing_image_5 from "../assets/images/wellbeing/section_2_image_2.png";
import wellbeing_image_6 from "../assets/images/wellbeing/section_2_image_3.png";
import wellbeing_image_7 from "../assets/images/wellbeing/section_3_image_1.png";
import wellbeing_image_8 from "../assets/images/wellbeing/section_3_image_2.png";
import wellbeing_image_9 from "../assets/images/wellbeing/section_3_image_3.png";
import wellbeing_image_10 from "../assets/images/wellbeing/section_3_image_4.png";
import wellbeing_image_11 from "../assets/images/wellbeing/section_4_image_1.png";
import wellbeing_image_12 from "../assets/images/wellbeing/section_5_image_1.png";
import wellbeing_image_13 from "../assets/images/wellbeing/section_5_image_2.png";
import wellbeing_image_14 from "../assets/images/wellbeing/section_2_image_4.png";
import wellbeing_image_15 from "../assets/images/wellbeing/section_2_image_5.png";
import hod_image_1 from "../assets/images/hod/section_1_image_1.jpg";
import hod_image_2 from "../assets/images/hod/section_2_image_1.png";
import hod_image_3 from "../assets/images/hod/section_3_image_1.jpg";
import hod_image_4 from "../assets/images/hod/section_4_image_1.jpg";
import hod_image_5 from "../assets/images/hod/section_5_image_1.jpg";
import hod_image_6 from "../assets/images/hod/section_6_image_1.png";

export const images = {
  hero: {
    sm: hero_image_small,
    md: hero_image_medium,
    lg: hero_image_large,
    xl: hero_image_xl,
    alt: "Universal international studios brand logo with partner brands",
  },
  brands: {
    sm: brands_image_small,
    md: brands_image_medium,
    lg: brands_image_large,
    xl: brands_image_xl,
    alt: "Universal international studios partner brands",
  },
  logo: {
    sm: logo_image_small,
    alt: "Universal international studios brand logo black text",
  },
  logoWhite: {
    sm: logo_white_image_small,
    alt: "Universal international studios brand logo white text",
  },
  sliderImages: [
    {
      title: "Downton Abbey",
      sm: image_1_small,
      md: image_1_meduim,
      lg: image_1_large,
      xl: image_1_xl,
      alt: "Downton Abbey",
    },
    {
      title: "Made in Chelsea",
      sm: image_2_small,
      md: image_2_meduim,
      lg: image_2_large,
      xl: image_2_xl,
      alt: "Made in Chelsea",
    },
    {
      title: "The real housewives",
      sm: image_3_small,
      md: image_3_meduim,
      lg: image_3_large,
      xl: image_3_xl,
      alt: "The real housewives",
    },
    {
      title: "Stateless",
      sm: image_4_small,
      md: image_4_meduim,
      lg: image_4_large,
      xl: image_4_xl,
      alt: "Stateless",
    },
    {
      title: "The long song",
      sm: image_5_small,
      md: image_5_meduim,
      lg: image_5_large,
      xl: image_5_xl,
      alt: "The long song",
    },
    {
      title: "The last kingdom",
      sm: image_6_small,
      md: image_6_meduim,
      lg: image_6_large,
      xl: image_6_xl,
      alt: "The last kingdom",
    },
    {
      title: "We are lady parts",
      sm: image_7_small,
      md: image_7_meduim,
      lg: image_7_large,
      xl: image_7_xl,
      alt: "We are lady parts",
    },
  ],
  wellbeingImages: {
    image1: wellbeing_image_1,
    image2: wellbeing_image_2,
    image3: wellbeing_image_3,
    image4: wellbeing_image_4,
    image5: wellbeing_image_5,
    image6: wellbeing_image_6,
    image7: wellbeing_image_7,
    image8: wellbeing_image_8,
    image9: wellbeing_image_9,
    image10: wellbeing_image_10,
    image11: wellbeing_image_11,
    image12: wellbeing_image_12,
    image13: wellbeing_image_13,
    image14: wellbeing_image_14,
    image15: wellbeing_image_15,
  },
  hodImages: {
    image1: hod_image_1,
    image2: hod_image_2,
    image3: hod_image_3,
    image4: hod_image_4,
    image5: hod_image_5,
    image6: hod_image_6,
  },
};
