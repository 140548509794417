import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import {
  Formik,
  FormikErrors,
  FormikHandlers,
  FormikTouched,
  FormikValues,
} from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { MyThemeOptions, useCustomTheme } from "../../../../themes";
import { MANUAL_AUTH_FORM_LABELS } from "../views/ManualLogin.constants";
import { authClient } from "../../../../services/api/auth";

import { useDispatch, useSelector } from "store";
import { getPublicShows, getShow, setSelectedShow } from "store/slices/show";
import { Spinner } from "components/Spinner";
import {
  setConsent,
  setIsLoggedIn,
  setLoggedInCompany,
  setLoggedInShow,
  setRole,
} from "store/slices/manualUser";
import { userClient } from "services/api/manual/user";
import { tokenStorage } from "services/storage/token";
import { setMenuType } from "store/slices/menu";
import { isLocal } from "config";
import { useAppDispatch } from "hooks";

interface Show {
  showId: number;
  showName: string;
}

interface FormValues {
  showId: string;
  showName: string;
  password: string;
  checked: boolean;
  submit: null | string;
}

const ManualLoginForm = ({ ...others }) => {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productionCompanyId = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyId
  );
  const productionCompanyName = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyName
  );
  const shows = useSelector((state: any) => state.show.publicShows);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productionCompanyId) {
      dispatch(getPublicShows());
    }
    if (!productionCompanyId) {
      navigate("/");
    }
  }, [productionCompanyId, navigate, dispatch]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event: React.MouseEvent) =>
    event.preventDefault();

  const handleShowSelection = (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ): void => {
    const selectedShowId = e.target.value as number;
    const selectedShow = shows.find(
      (show: Show) => show.showId === selectedShowId
    );
    if (selectedShow) {
      setFieldValue("showName", selectedShow.showName);
    }
    dispatch(getShow(selectedShowId, navigate));
  };

  const performLogin = async (
    values: FormValues,
    resetForm: () => void
  ): Promise<void> => {
    try {
      setLoading(true);
      const { id_token, refresh_token } = await userClient.userLogin(
        values.showName,
        values.password
      );

      tokenStorage.setIdToken(id_token);
      tokenStorage.setRefreshToken(refresh_token);
      updateLoginState(values);
      resetForm();
      navigate("/manual");
      toast.success("Login successful!");
    } catch (error: any) {
      handleLoginError(error);
    }
  };

  const updateLoginState = (values: FormValues) => {
    dispatch(setIsLoggedIn(true));
    dispatch(setLoggedInCompany(productionCompanyName));
    dispatch(setLoggedInShow(values.showName));
    dispatch(setRole("user"));
    dispatch(setMenuType("user"));
    dispatch(setConsent(true));
    dispatch(
      setSelectedShow({
        showId: Number(values.showId),
        showName: values.showName,
      })
    );
    setLoading(false);
  };

  const handleLoginError = (error: any) => {
    setLoading(false);
    console.error("Login error:", error);
    const errorMessage =
      error.response?.data.message ||
      "An error occurred during login. Please try again.";
    toast.error(`Login failed: ${errorMessage}`);
  };

  return (
    <Formik
      initialValues={{
        showId: "",
        showName: "",
        password: "",
        checked: false,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        showId: Yup.string().required(
          MANUAL_AUTH_FORM_LABELS.SELECTION_REQUIRED
        ),
        password: Yup.string()
          .max(255)
          .required(MANUAL_AUTH_FORM_LABELS.PASSWORD_REQUIRED),
        checked: Yup.boolean().oneOf(
          [true],
          MANUAL_AUTH_FORM_LABELS.CHECKBOX_REQUIRED
        ),
      })}
      onSubmit={(values, { resetForm }) => performLogin(values, resetForm)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...others}>
          {loading ? (
            <SpinnerDisplay />
          ) : (
            <FormContent
              theme={theme}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              shows={shows}
              handleShowSelection={handleShowSelection}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              showPassword={showPassword}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

const SpinnerDisplay = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spinner size="xl" />
  </Box>
);

interface FormContentProps {
  theme: MyThemeOptions;
  errors: FormikErrors<FormikValues>;
  handleBlur: FormikHandlers["handleBlur"];
  handleChange: FormikHandlers["handleChange"];
  setFieldValue: any;
  touched: FormikTouched<FormikValues>;
  values: FormValues;
  shows: Show[];
  handleShowSelection: (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: any
  ) => void;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: React.MouseEvent) => void;
  showPassword: boolean;
}

const FormContent = ({
  theme,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  touched,
  values,
  shows,
  handleShowSelection,
  handleClickShowPassword,
  handleMouseDownPassword,
  showPassword,
}: FormContentProps) => (
  <>
    <ShowSelect
      theme={theme}
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      errors={errors}
      touched={touched}
      shows={shows}
      handleShowSelection={handleShowSelection}
      setFieldValue={setFieldValue}
    />
    <PasswordInput
      theme={theme}
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      errors={errors}
      touched={touched}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      showPassword={showPassword}
    />
    <OtherFormElements
      values={values}
      handleChange={handleChange}
      errors={errors}
      touched={touched}
    />
  </>
);

interface ShowSelectProps {
  theme: MyThemeOptions;
  values: { showId: string; [key: string]: any };
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  errors: FormikErrors<{ showId?: string }>;
  touched: FormikTouched<{ showId?: boolean }>;
  shows: Show[];
  handleShowSelection: (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    setFieldValue: any
  ) => void;
  setFieldValue: any;
}

const ShowSelect = ({
  theme,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  shows,
  handleShowSelection,
  setFieldValue,
}: ShowSelectProps) => {
  return (
    <FormControl fullWidth sx={{ ...theme.customSelectInput }}>
      <InputLabel htmlFor="select-show">Select a Show</InputLabel>
      <Select
        labelId="select-show-label"
        id="select-show"
        name="showId"
        value={values.showId}
        onChange={(e: any) => {
          handleShowSelection(e, setFieldValue);
          handleChange(e);
        }}
        onBlur={handleBlur}
        error={touched.showId && Boolean(errors.showId)}
      >
        {shows.map((show) => (
          <MenuItem key={show.showId} value={show.showId}>
            {show.showName}
          </MenuItem>
        ))}
      </Select>
      {touched.showId && errors.showId && (
        <FormHelperText error>{errors.showId}</FormHelperText>
      )}
    </FormControl>
  );
};

interface PasswordInputProps {
  theme: MyThemeOptions;
  values: { password: string; [key: string]: any };
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  errors: FormikErrors<{ password?: string }>;
  touched: FormikTouched<{ password?: boolean }>;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  showPassword: boolean;
}

const PasswordInput = ({
  theme,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  handleClickShowPassword,
  handleMouseDownPassword,
  showPassword,
}: PasswordInputProps) => (
  <FormControl
    fullWidth
    error={Boolean(touched.password && errors.password)}
    sx={{ ...theme.customInput }}
  >
    <InputLabel htmlFor="outlined-adornment-password-login">
      Password
    </InputLabel>
    <OutlinedInput
      sx={{ backgroundColor: "#eef2f6" }}
      id="outlined-adornment-password-login"
      type={showPassword ? "text" : "password"}
      value={values.password}
      name="password"
      onBlur={handleBlur}
      onChange={handleChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            size="large"
          >
            {showPassword ? (
              <Tooltip title={"Hide password"}>
                <Visibility />
              </Tooltip>
            ) : (
              <Tooltip title={"Show password"}>
                <VisibilityOff />
              </Tooltip>
            )}
          </IconButton>
        </InputAdornment>
      }
      label="Password"
    />
    {touched.password && errors.password && (
      <FormHelperText error id="standard-weight-helper-text-password-login">
        {errors.password}
      </FormHelperText>
    )}
  </FormControl>
);

interface OtherFormElementsProps {
  values: { checked: boolean; loading?: boolean; [key: string]: any };
  handleChange: FormikHandlers["handleChange"];
  errors: FormikErrors<{ checked?: string }>;
  touched: FormikTouched<{ checked?: boolean }>;
}

const OtherFormElements = ({
  values,
  handleChange,
  errors,
  touched,
}: OtherFormElementsProps) => {
  const [ssoLoading, setSsoLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        padding={"8px 0px"}
      >
        <Grid item>
          <FormControlLabel
            sx={{ alignItems: "start" }}
            control={
              <Checkbox
                checked={values.checked}
                onChange={handleChange}
                name="checked"
                id="checked"
                color="primary"
              />
            }
            label={
              <Typography variant="caption">
                {MANUAL_AUTH_FORM_LABELS.CHECKBOX_DESCRIPTION}
              </Typography>
            }
          />
          {touched.checked && errors.checked && (
            <FormHelperText sx={{ pl: 2 }} error>
              {errors.checked}
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Button
          disabled={values.loading}
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          sx={{
            p: "12px 32px",
            boxShadow: "none",
            bgcolor: "#000",
            color: "#fff",
            "&:hover": {
              color: "#000",
            },
          }}
        >
          {values.loading ? (
            <CircularProgress
              style={{
                color: "#fff",
              }}
              size={20}
            />
          ) : (
            MANUAL_AUTH_FORM_LABELS.SIGN_IN
          )}
        </Button>

        {!isLocal && (
          <Link
            href={`${authClient.getAuthorizeURL()}`}
            underline="none"
            style={{ textDecoration: "none" }}
            onClick={() => setSsoLoading(true)}
          >
            <Button
              disabled={ssoLoading}
              fullWidth
              size="large"
              variant="outlined"
              sx={{
                p: "12px 32px",
                boxShadow: "none",
                bgcolor: "#000",
                color: "#fff",
                "&:hover": {
                  color: "#000",
                },
              }}
            >
              {ssoLoading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                  }}
                  size={20}
                />
              ) : (
                MANUAL_AUTH_FORM_LABELS.SSO_SIGN_IN
              )}
            </Button>
          </Link>
        )}

        {isLocal && (
          <Button
            disabled={ssoLoading}
            fullWidth
            size="large"
            variant="outlined"
            sx={{
              p: "12px 32px",
              boxShadow: "none",
              bgcolor: "#000",
              color: "#fff",
              "&:hover": {
                color: "#000",
              },
            }}
            onClick={() => {
              dispatch(setIsLoggedIn(true));
              dispatch(setMenuType("admin"));
              dispatch(setRole("admin"));
              navigate("/manual/admin/home");
            }}
          >
            {ssoLoading ? (
              <CircularProgress
                style={{
                  color: "#fff",
                }}
                size={20}
              />
            ) : (
              MANUAL_AUTH_FORM_LABELS.SSO_SIGN_IN
            )}
          </Button>
        )}
      </Box>
    </>
  );
};

export default ManualLoginForm;
