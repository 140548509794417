import { Login } from "../pages/policy/auth";
import { HodGuide } from "../pages/hod-guide/views/HodGuide";
import { Terms } from "../pages/terms/views/Terms";
import { Wellbeing } from "../pages/wellbeing/views/Wellbeing";
import { MainLayout } from "../components/Layout/MainLayout";

import { PrivateRoutes } from "./Private";

const AppRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "policies",
      element: <PrivateRoutes />,
    },
    {
      path: "monkeykingdom-policies",
      element: <PrivateRoutes />,
    },
    {
      path: "matchboxpicturesandtap-policies",
      element: <PrivateRoutes />,
    },
    {
      path: "wellbeing",
      element: <Wellbeing />,
    },
    {
      path: "hod-guide",
      element: <HodGuide />,
    },
    {
      path: "terms",
      element: <Terms />,
    },
  ],
};

export default AppRoutes;
