import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popper,
  useMediaQuery,
} from "@mui/material";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import { IconSearch, IconX } from "@tabler/icons-react";
import { shouldForwardProp } from "@mui/system";

import Transitions from "../../../../Extended/Transitions";
import { useCustomTheme } from "../../../../../themes";

import { useDispatch } from "store";
import { setSearchTerm } from "store/slices/searchTerm";
import { activeItem } from "store/slices/menu";

const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      padding: "12px 0 12px 4px !important",
    },

    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    cursor: "pointer",
    borderRadius: "8px",
    width: "34px",
    height: "34px",
    fontSize: "1.2rem",
    background: theme.palette.tertiary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.tertiary.light,
    },
  })
);

interface Props {
  value: string;
  setValue: (value: string) => void;
  popupState: any;
  handleSubmit: () => void;
}

// SEARCH INPUT - MOBILE

const MobileSearch = ({ value, setValue, popupState, handleSubmit }: Props) => {
  const theme = useCustomTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      endAdornment={
        <InputAdornment position="end">
          <HeaderAvatarStyle variant="rounded" onClick={handleSubmit}>
            <IconSearch stroke={1.5} size="16px" />
          </HeaderAvatarStyle>
          <Box sx={{ ml: 2 }}>
            <Avatar
              variant="rounded"
              sx={{
                cursor: "pointer",
                borderRadius: "8px",
                width: "34px",
                height: "34px",
                fontSize: "1.2rem",
                background: theme.palette.tertiary.light,
                color: theme.palette.primary.main,
                "&:hover": {
                  background: theme.palette.primary.main,
                  color: theme.palette.tertiary.light,
                },
              }}
              {...bindToggle(popupState)}
            >
              <IconX stroke={1.5} size="20px" />
            </Avatar>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

// SEARCH INPUT
const SearchSection = () => {
  const theme = useCustomTheme();
  const [value, setValue] = useState("");
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setValue(event.target.value);
    }
  };

  const handleSearchSubmit = (value: string) => {
    if (value !== "") {
      dispatch(setSearchTerm(value));
      navigate(`/manual/documents`);
      dispatch(activeItem({ id: "0", name: "Documents" }));
      setValue("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (event.key === "Enter" && target.value !== "") {
      handleSearchSubmit(target.value);
    }
  };

  return (
    <>
      {matchDownMd && (
        <Box>
          <PopupState variant="popper" popupId="demo-popup-popper">
            {(popupState) => (
              <>
                <Box sx={{ ml: 2 }}>
                  <HeaderAvatarStyle
                    variant="rounded"
                    {...bindToggle(popupState)}
                  >
                    <IconSearch stroke={1.5} size="19.2px" />
                  </HeaderAvatarStyle>
                </Box>
                <PopperStyle {...bindPopper(popupState)} transition>
                  {({ TransitionProps }) => (
                    <>
                      <Transitions
                        type="zoom"
                        {...TransitionProps}
                        sx={{ transformOrigin: "center left" }}
                      >
                        <Card
                          sx={{
                            background: "#fff",
                            [theme.breakpoints.down("sm")]: {
                              border: 0,
                              boxShadow: "none",
                            },
                          }}
                        >
                          <Box id={"root"} sx={{ p: 2 }}>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Grid item xs>
                                <MobileSearch
                                  value={value}
                                  setValue={setValue}
                                  popupState={popupState}
                                  handleSubmit={() => handleSearchSubmit(value)}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Card>
                      </Transitions>
                    </>
                  )}
                </PopperStyle>
              </>
            )}
          </PopupState>
        </Box>
      )}

      {!matchDownMd && (
        <Box sx={{ display: "flex" }}>
          <OutlineInputStyle
            id="input-search-header"
            value={value}
            onChange={handleSearchChange}
            placeholder="Search"
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <HeaderAvatarStyle
                  variant="rounded"
                  onClick={() => handleSearchSubmit(value)}
                >
                  <IconSearch stroke={1.5} size="16px" />
                </HeaderAvatarStyle>
              </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ "aria-label": "weight" }}
          />
        </Box>
      )}
    </>
  );
};

export default SearchSection;
