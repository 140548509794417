import * as React from "react";

import { Heading } from "../../../../components/Heading";
import { images } from "../../../../assets";

export const Brands = () => {
  return (
    <div className="pt-40 phone:pt-[100px]">
      <div className="flex justify-center">
        <Heading
          className="text-[30px] phone:text-center phone:text-[26px]"
          title="Our Production Companies"
        />
      </div>
      <div className="flex justify-center">
        <img
          className="w-[90%]"
          srcSet={`${images.brands.sm} 320w, ${images.brands.md} 600w, ${images.brands.lg} 1200w, ${images.brands.xl} 1920w`}
          sizes="(min-width: 1200px) 1200px, 100vw"
          src={images.brands.xl}
          alt="Brands Img"
        />
      </div>
    </div>
  );
};
