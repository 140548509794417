export interface MainSiteLinkItem {
  title: string;
  href: string;
  hasDropdown: boolean;
  dropdownKey?: string;
  icon: React.ReactNode;
  Component?: React.ComponentType;
}

export const policyLinks = [
  {
    id: 1,
    name: "Universal International Studios",
    to: "/policies",
    code: "uis",
  },
  {
    id: 2,
    name: "Matchbox Pictures & Tony Ayres Productions",
    to: "/matchboxpicturesandtap-policies",
    code: "mp",
  },
  { id: 3, name: "Monkey Kingdom", to: "/monkeykingdom-policies", code: "mk" },
];

export const manualLinks = [
  { id: 1, name: "Universal International Studios", to: "/manual" },
  { id: 2, name: "Carnival Films", to: "/manual" },
  { id: 3, name: "Heyday", to: "/manual" },
  {
    id: 4,
    name: "Matchbox Pictures & Tony Ayres Productions",
    to: "/manual",
  },
  { id: 5, name: "Monkey Kingdom", to: "/manual" },
  { id: 6, name: "Working Title Television", to: "/manual" },
];
