import { useState } from "react";
import Box from "@mui/material/Box";
import { Tabs, Tab } from "@mui/material";

import { TEMPLATES_PARAS, TAB_LABELS } from "../../../../constants";
import List from "../../../../components/List/UserList/List";

import { useCustomTheme } from "themes";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: "32px" }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TemplateSelectionTab = () => {
  const theme = useCustomTheme();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newIndex: number) => {
    setSelectedIndex(newIndex);
  };

  const getTabStyle = (index: number) => {
    const isSelected = index === selectedIndex;
    const baseStyle = {
      background: isSelected ? "#ddd" : "#fff",
      borderBottom: isSelected ? "2px solid #000" : "none",
      border: `px solid ${theme.palette.tertiary.main}`,
    };

    if (index === 0) {
      return {
        ...baseStyle,
        borderTopLeftRadius: "8px",
      };
    } else if (index === TAB_LABELS.length - 1) {
      return {
        ...baseStyle,
        borderTopRightRadius: "8px",
      };
    } else {
      return baseStyle;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedIndex}
          onChange={handleChange}
          variant="fullWidth"
          allowScrollButtonsMobile
          aria-label="full width tabs"
        >
          {TAB_LABELS.map((label: string, index: number) => (
            <Tab
              key={label}
              sx={getTabStyle(index)}
              label={label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {TAB_LABELS.map((label: string, index: number) => (
        <TabPanel key={index} value={selectedIndex} index={index}>
          <List
            key={label}
            title={"Templates"}
            subtitleParas={TEMPLATES_PARAS}
            category={"Templates"}
            subcategory={TAB_LABELS[selectedIndex]}
            searchComponent={true}
          />
        </TabPanel>
      ))}
    </Box>
  );
};

export default TemplateSelectionTab;
