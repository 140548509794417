export function updatesTimePeriod() {
  const currentDate = new Date();

  currentDate.setMonth(currentDate.getMonth() - 6);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const startMonth = monthNames[currentDate.getMonth()];
  const endMonth = monthNames[new Date().getMonth()];

  const endYear = new Date().getFullYear();

  return `${startMonth} - ${endMonth} ${endYear}`;
}
