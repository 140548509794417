import axios from "axios";
import { stringify } from "qs";

import {
  CALLBACK_URI,
  COGNITO_API_URL,
  REACT_APP_AUTHORIZE_URL,
  SAML_CLIENT_ID,
  SAML_CLIENT_SECRET,
  USER_DOC_PUBLIC_API_URL,
} from "../../../config";
import { Token } from "../../../types/auth";
import { httpClient } from "../httpClient";

export const getClientConfig = () => {
  return [SAML_CLIENT_ID, SAML_CLIENT_SECRET];
};

export function getAuthorizeURL(): string {
  const [clientId] = getClientConfig();
  return `${REACT_APP_AUTHORIZE_URL}?client_id=${encodeURIComponent(
    clientId
  )}&response_type=code&scope=${encodeURIComponent(
    "openid profile"
  )}&redirect_uri=${encodeURIComponent(CALLBACK_URI)}`;
}

export function getToken(code: string): Promise<Token> {
  const [clientId, clientSecret] = getClientConfig();
  return axios
    .post(
      `${COGNITO_API_URL}/token`,
      stringify({
        code: code,
        grant_type: "authorization_code",
        client_id: clientId,
        redirect_uri: CALLBACK_URI,
      }),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: clientId,
          password: clientSecret,
        },
      }
    )
    .then((resp) => resp.data);
}

export function refreshToken(
  refreshToken: string,
  showName: string
): Promise<any> {
  const data = new URLSearchParams({
    refreshToken,
    showName,
  });

  return httpClient
    .post(`${USER_DOC_PUBLIC_API_URL}/refresh_token`, data.toString(), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((resp) => {
      return resp.data;
    });
}

export const authClient = {
  getClientConfig,
  getToken,
  refreshToken,
  getAuthorizeURL,
};
