import { styled, Theme, CSSObject } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

import { DRAWER_WIDTH } from "../../../../constants";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  borderRight: "none",
  zIndex: 1000,
  background: theme.palette.background.default,
  overflowX: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen + 200,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  marginTop: "56px",
  borderRight: "none",
  zIndex: 1000,
  padding: "34px 12px",
  background: theme.palette.background.default,
  overflowX: "hidden",
  maxHeight: "100vh",
  width: 70,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen + 200,
  }),
});

const MiniDrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  borderRight: "0px",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default MiniDrawerStyled;
