import { useEffect } from "react";
import { Box } from "@mui/material";

import AdminList from "components/List/AdminList/AdminList";
import { useDispatch, useSelector } from "store";
import { getShowsByProductionCompany } from "store/slices/show";
import {
  openCreateProductionModal,
  openCreateTemplateProductionModal,
  openDeleteProductionModal,
  openUpdatePasswordModal,
} from "store/slices/modal";
import { Show, ShowState } from "types/show";
import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

interface ProductionsData {
  id: number;
  productionName: string;
}

const headCells: any = [
  {
    id: "productionName",
    numeric: false,
    label: "Production Title",
    align: "left",
  },
];

const Productions = () => {
  const dispatch = useDispatch();
  const productionCompanyId = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyId
  );
  const showId = useSelector((state: any) => state.show.selectedShow.showId);
  const dataRows = useSelector(
    (state: ShowState) => state.show.productionCompanyShows
  );

  useEffect(() => {
    dispatch(getShowsByProductionCompany(productionCompanyId));
  }, [dispatch, showId, productionCompanyId]);

  function createData(showId: number, showName: string): ProductionsData {
    return { id: showId, productionName: showName };
  }

  const rows = dataRows.map((show: Show) => {
    return createData(show.showId, show.showName);
  });

  return (
    <Box sx={{ p: "16px", width: "100%", height: "100%" }}>
      <ShowTitleSection />
      {productionCompanyId && (
        <AdminList
          title={"Productions"}
          categoryTitle={"Productions"}
          rows={rows}
          editIcon
          searchComponent={true}
          onDelete={openDeleteProductionModal}
          onEdit={openUpdatePasswordModal}
          onAdd={openCreateProductionModal}
          editTooltipText={"Update password"}
          headCells={headCells}
          searchProp="productionName"
          SecondaryAddButton={true}
          onSecondaryAdd={openCreateTemplateProductionModal}
          secondaryActionButtonTitle={"Production by template"}
        />
      )}
    </Box>
  );
};

export default Productions;
