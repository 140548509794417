import React from "react";
import { Box } from "@mui/material";

import TemplateSelectionTab from "../components/TemplateSelectionTab";

import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

const Templates = () => {
  return (
    <Box sx={{ p: "16px", minHeight: "calc(100vh - 200px)" }}>
      <ShowTitleSection />
      <TemplateSelectionTab />
    </Box>
  );
};

export default Templates;
