import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { getPolicies } from "../api/getPolicies";
import { PolicyList } from "../components/PolicyList";
import PolicyLinkList from "../components/PolicyLinkList";
import { TermsAndConditions } from "../../../components/TermsAndConditions"; // Assume this will also be refactored or used as is

export const Policy = () => {
  const policyCode = useSelector(
    (state: any) => state.policy.selectedPolicy.policyCode
  );

  const { data, isLoading } = useQuery(
    ["policy", policyCode],
    () => getPolicies(policyCode!),
    { enabled: !!policyCode, cacheTime: 1000 * 60 * 60 }
  );

  return (
    <Container
      sx={{
        bgcolor: "#f5f5f5",
        py: 6,
        px: { laptop: 15, phone: 2.5 },
        maxWidth: "100%",
      }}
    >
      <Box sx={{ bgcolor: "white", p: "32px" }}>
        <Typography
          variant="h1"
          component="h1"
          sx={{ fontWeight: "bold", mb: 4 }}
        >
          Policies
        </Typography>
        {isLoading ? (
          <Box
            sx={{
              height: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={60} />
          </Box>
        ) : (
          <Box sx={{ pb: "32px" }}>
            <PolicyList policies={data} />
            <PolicyLinkList />
          </Box>
        )}
        <TermsAndConditions />
      </Box>
    </Container>
  );
};
