import { newLocalStorage, SfpStore } from "./storage";

const IdTokenKeyID = "serviceToken";
const RefreshTokenKeyID = "refreshToken";
const SignedSSOKeyID = "SignedSSO";

export class TokenStorage {
  private store: SfpStore;

  constructor(store: SfpStore) {
    this.store = store;
  }

  public setIdToken(value: string) {
    this.store.set(IdTokenKeyID, value);
  }

  public setRefreshToken(value: string) {
    this.store.set(RefreshTokenKeyID, value);
  }

  public getIdToken(): string | null {
    return this.store.get<string>(IdTokenKeyID);
  }

  public getRefreshToken(): string | null {
    return this.store.get<string>(RefreshTokenKeyID);
  }

  public setWithSSO(value: boolean) {
    this.store.set(SignedSSOKeyID, value);
  }

  public getWithSSO(): boolean {
    return !!this.store.get<boolean>(SignedSSOKeyID);
  }

  public removeToken(): void {
    return this.store.remove(IdTokenKeyID);
  }
}

export const tokenStorage = new TokenStorage(newLocalStorage());
