import { clsx } from "clsx";
import * as React from "react";

import { Head } from "../../Head";

type ContentLayoutProps = {
  children: React.ReactNode | React.ReactNode[];
  title?: string;
  className?: string;
};

export const ContentLayout = ({
  children,
  title,
  className,
  ...props
}: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className={clsx("min-h-[calc(100vh-174px)]", className)}>
        {children}
      </div>
    </>
  );
};
