import MinimalLayout from "../components/Layout/MinimalLayout";

import CallbackComponent from "modules/productionManual/auth/views/CallbackComponent";
import ManualLogin from "modules/productionManual/auth/views/ManualLogin";

const ManualLoginRoutes = {
  path: "/manual",
  element: <MinimalLayout />,
  children: [
    {
      path: "login",
      element: <ManualLogin />,
    },
    {
      path: "callback",
      element: <CallbackComponent />,
    },
  ],
};

export default ManualLoginRoutes;
