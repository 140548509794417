import { Box, List, ListItem, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { useCustomTheme } from "../../../../../../themes";

import { Update } from "types/updates";

interface UpdatesItemProps {
  category: string;
  updatedDocuments: Update[];
}

const UpdatesItem = ({ category, updatedDocuments }: UpdatesItemProps) => {
  const theme = useCustomTheme();

  const sortedDocuments = useMemo(() => {
    return updatedDocuments.sort((a, b) => {
      const dateA = new Date(a.lastUpdateDate);
      const dateB = new Date(b.lastUpdateDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [updatedDocuments]);

  return (
    <ListItem
      sx={{
        p: "16px 0 0 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          bgcolor: theme.palette.tertiary.light,
          width: "100%",
          p: "16px",
          borderRadius: "8px 8px 0 0",
          border: `1px solid ${theme.palette.tertiary.main}`,
        }}
      >
        <Typography>{category}</Typography>
      </Box>
      <List
        sx={{
          width: "100%",
          borderRadius: " 0 0 8px 8px",
          border: `1px solid ${theme.palette.tertiary.main}`,
          borderTop: "none",
        }}
      >
        {sortedDocuments.map((item: Update, index: number) => (
          <ListItem key={index}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.primary,
                }}
                to={`/manual/${item.document.category.toLocaleLowerCase()}`}
              >
                <Typography
                  sx={{
                    "&:hover": {
                      color: theme.palette.primary.main,
                      textDecoration: "underline",
                    },
                  }}
                >
                  {item.document.documentName}
                </Typography>
              </Link>

              <Typography>
                <b style={{ fontFamily: "inherit" }}>Updated on: </b>
                {item.lastUpdateDate}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </ListItem>
  );
};

export default UpdatesItem;
