import { httpClient } from "../httpClient";

import { ProductionCompany } from "types/show";
import { USER_DOC_PUBLIC_API_URL } from "config";

function getProductionCompanies(): Promise<ProductionCompany[]> {
  return httpClient
    .get(`${USER_DOC_PUBLIC_API_URL}/production_companies`)
    .then((resp) => {
      return resp.data ?? [];
    });
}

export const productionCompanyClient = {
  getProductionCompanies,
};
