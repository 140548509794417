import { useMemo } from "react";
import { ThemeOptions, createTheme } from "@mui/material/styles";

import { ColorPalette } from "../types";

export const colorPalette = (): ColorPalette => ({
  primary: {
    light: "#4d4d4d",
    main: "#000000",
    dark: "#000000",
  },
  secondary: {
    light: "#ffffff",
    main: "#ffffff",
    dark: "#b3b3b3",
  },
  tertiary: {
    light: "#f4f2ee",
    main: "#dfdeda",
    dark: "#8c8c8c",
  },
  goldAccent: {
    light: "#edd7a1",
    main: "#dbb16e",
    dark: "#b8903d",
  },
  greenAccent: {
    light: "#869689",
    main: "#495551",
    dark: "#303834",
  },
  redAccent: {
    light: "#e6958c",
    main: "#c96255",
    dark: "#9f453f",
  },
  pinkAccent: {
    light: "#e27f8e",
    main: "#be5462",
    dark: "#943944",
  },
  blueAccent: {
    light: "#6894c3",
    main: "#275d99",
    dark: "#1f4879",
  },
  turkAccent: {
    light: "#88b5bb",
    main: "#4f899e",
    dark: "#3b6d78",
  },
  textAccent: {
    light: "#74748a",
    main: "#333333",
    dark: "#1c1c26",
  },
});

export interface MyThemeOptions extends ThemeOptions {
  customInput?: any;
  customSelectInput?: any;
}

export const themeSettings = (): MyThemeOptions => {
  const colors = colorPalette();
  return {
    palette: {
      primary: {
        light: colors.primary.light,
        main: colors.primary.main,
        dark: colors.primary.dark,
      },
      secondary: {
        light: colors.secondary.light,
        main: colors.secondary.main,
        dark: colors.secondary.dark,
      },
      tertiary: {
        light: colors.tertiary.light,
        main: colors.tertiary.main,
        dark: colors.tertiary.dark,
      },
      goldAccent: {
        light: colors.goldAccent.light,
        main: colors.goldAccent.main,
        dark: colors.goldAccent.dark,
      },
      greenAccent: {
        light: colors.greenAccent.light,
        main: colors.greenAccent.main,
        dark: colors.greenAccent.dark,
      },
      redAccent: {
        light: colors.redAccent.light,
        main: colors.redAccent.main,
        dark: colors.redAccent.dark,
      },
      pinkAccent: {
        light: colors.pinkAccent.light,
        main: colors.pinkAccent.main,
        dark: colors.pinkAccent.dark,
      },
      blueAccent: {
        light: colors.blueAccent.light,
        main: colors.blueAccent.main,
        dark: colors.blueAccent.dark,
      },
      turkAccent: {
        light: colors.turkAccent.light,
        main: colors.turkAccent.main,
        dark: colors.turkAccent.dark,
      },
      textAccent: {
        light: colors.textAccent.light,
        main: colors.textAccent.main,
        dark: colors.textAccent.dark,
      },
      text: {
        primary: colors.textAccent.main,
      },
    },
    typography: {
      fontFamily: "Acumin-RPro, Roboto, sans-serif",
      h6: {
        fontWeight: 500,
        color: colors.textAccent.main,
        fontSize: "0.75rem",
      },
      h5: {
        fontSize: "0.875rem",
        color: colors.textAccent.main,
        fontWeight: 500,
      },
      h4: {
        fontSize: "1rem",
        color: colors.textAccent.main,
        fontWeight: 600,
      },
      h3: {
        fontSize: "1.25rem",
        color: colors.textAccent.main,
        fontWeight: 600,
      },
      h2: {
        fontSize: "1.5rem",
        color: colors.textAccent.main,
        fontWeight: 700,
      },
      h1: {
        fontSize: "2.125rem",
        color: colors.textAccent.main,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: "0.875rem",
        fontWeight: 500,
        color: colors.textAccent.main,
      },
      subtitle2: {
        fontSize: "0.75rem",
        fontWeight: 400,
        color: colors.textAccent.main,
      },
      caption: {
        fontSize: "0.75rem",
        color: colors.textAccent.main,
        fontWeight: 400,
      },
      body1: {
        fontSize: "0.875rem",
        fontWeight: 400,
        letterSpacing: ".7px",
        lineHeight: "1.334em",
      },
      body2: {
        letterSpacing: "0em",
        fontWeight: 400,
        lineHeight: "1.5em",
        color: colors.textAccent.main,
      },
      button: {
        textTransform: "capitalize",
        border: `1px solid ${colors.tertiary.main}`,
      },
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        top: 23,
        left: 0,
        color: colors.tertiary,
        '&[data-shrink="false"]': {
          top: 5,
        },
      },
      "& > div > input": {
        padding: "30.5px 14px 11.5px !important",
        background: "#eef2f6",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    customSelectInput: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        top: 23,
        left: 0,
        color: colors.tertiary,
        '&[data-shrink="false"]': {
          top: 5,
        },
      },
      "& .MuiOutlinedInput-input": {
        color: colors.primary,
        background: "#eef2f6",
        padding: "30.5px 14px 11.5px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        top: 0,
      },
      "& legend": {
        background: colors.tertiary,
        display: "none",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  };
};

export const useCustomTheme = () => {
  return useMemo(() => createTheme(themeSettings()), []);
};
