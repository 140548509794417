import { useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useDispatch } from "store";
import { closeCreateProductionTemplateModal } from "store/slices/modal";
import { templateClient } from "services/api/manual/template";
import { ProductionCompanyState } from "types/productionCompany";
import { getTemplates } from "store/slices/templates";

const FORM_FIELD_BG_COLOR = "#f0f2f5";

interface FormValues {
  templateTitle: string;
  contactName: string;
  contactEmail: string;
  contactPosition: string;
  contactPhone: string;
}

const CreateTemplateModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const productionCompanyId = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany?.productionCompanyId
  );
  const createTemplateModalOpen = useSelector(
    (state: any) => state.modal.createProductionTemplateModalOpen
  );

  const initialValues: FormValues = {
    templateTitle: "",
    contactName: "",
    contactEmail: "",
    contactPosition: "",
    contactPhone: "",
  };

  const validationSchema = Yup.object().shape({
    templateTitle: Yup.string().required("Template title is required"),
  });

  const handleCreate = async (values: FormValues) => {
    if (values && productionCompanyId) {
      const template = {
        name: values.templateTitle,
        contact: {
          ContactPosition: values.contactPosition,
          contactEmail: values.contactEmail,
          contactInfo: "",
          contactName: values.contactName,
          contactPhone: values.contactPhone,
        },
        info: "",
        productionCompany: {
          productionCompanyId: productionCompanyId,
        },
        welcomeMessage: "",
      };

      try {
        if (template) {
          setLoading(true);
          await templateClient.createTemplate(template);
          setLoading(false);
        }

        toast.success("Successfully created a template");

        if (productionCompanyId) {
          dispatch(getTemplates(productionCompanyId));
        }
      } catch (error) {
        toast.error("Error creating template");
        console.error("Error in deleting template:", error);
      } finally {
        dispatch(closeCreateProductionTemplateModal());
      }
    }
  };

  const onSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    handleCreate(values);
    setSubmitting(false);
  };

  return (
    <Dialog
      open={createTemplateModalOpen}
      onClose={() => dispatch(closeCreateProductionTemplateModal())}
    >
      <DialogTitle variant="h2" sx={{ p: "16px" }}>
        Add a new template
      </DialogTitle>

      <Box sx={{ p: "0px 0px 16px 16px" }}>
        <Typography variant="caption">
          Please fill all required fields below.
        </Typography>
      </Box>

      <Box>
        <Divider />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, resetForm, errors, touched, setFieldValue }) => (
          <Form id="create-document-form">
            <Grid
              container
              spacing={3}
              sx={{
                p: "16px",
                width: "100%",
                margin: "0px",
                "& .MuiGrid-item": { p: "8px 0px" },
              }}
            >
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="templateTitle"
                  name="templateTitle"
                  label="Template Title"
                  fullWidth
                  error={Boolean(errors.templateTitle && touched.templateTitle)}
                  helperText={touched.templateTitle && errors.templateTitle}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="contactName"
                  name="contactName"
                  label="Contact Name"
                  fullWidth
                  error={Boolean(errors.contactName && touched.contactName)}
                  helperText={touched.contactName && errors.contactName}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="contactEmail"
                  name="contactEmail"
                  label="Contact Email"
                  fullWidth
                  error={Boolean(errors.contactEmail && touched.contactEmail)}
                  helperText={touched.contactEmail && errors.contactEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="contactPosition"
                  name="contactPosition"
                  label="Contact Position"
                  fullWidth
                  error={Boolean(
                    errors.contactPosition && touched.contactPosition
                  )}
                  helperText={touched.contactPosition && errors.contactPosition}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="contactPhone"
                  name="contactPhone"
                  label="Contact Phone"
                  fullWidth
                  error={Boolean(errors.contactPhone && touched.contactPhone)}
                  helperText={touched.contactPhone && errors.contactPhone}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid sx={{ display: "flex", justifyContent: "end" }} container>
                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{
                        boxShadow: "none",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#000",
                          }}
                          size={20}
                        />
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </Grid>

                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={() => {
                        dispatch(closeCreateProductionTemplateModal());
                      }}
                      sx={{
                        boxShadow: "none",
                        background: "#fff",
                        color: "#000",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateTemplateModal;
