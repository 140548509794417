import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Formik,
  Form,
  Field,
  FormikErrors,
  FormikValues,
  FormikTouched,
} from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { showClient } from "../../services/api/manual";

import { closeCreateProductionModal } from "store/slices/modal";
import { getShowsByProductionCompany } from "store/slices/show";
import { ProductionCompanyState } from "types/productionCompany";
import { ProductionCompany } from "types/show";
import { AppDispatch } from "store";

const FORM_FIELD_BG_COLOR = "#f0f2f5";

interface FormValues {
  showName: string;
  additionalInformation: string;
  contactName: string;
  contactPosition: string;
  phone: string;
  email: string;
  additionalContactInformation: string;
  password: string;
  confirmPassword: string;
}

const initialValues: FormValues = {
  showName: "",
  additionalInformation: "",
  contactName: "",
  contactPosition: "",
  phone: "",
  email: "",
  additionalContactInformation: "",
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  showName: Yup.string().required("Production name is required"),
  additionalInformation: Yup.string(),
  contactName: Yup.string().required("Contact name is required"),
  contactPosition: Yup.string().required("Contact position is required"),
  phone: Yup.string(),
  email: Yup.string()
    .email("Invalid email")
    .required("Contact email is required"),
  additionalContactInformation: Yup.string(),
});

const handleCreate = async (
  values: FormValues,
  selectedProductionCompany: ProductionCompany,
  dispatch: AppDispatch,
  setLoading: any,
  resetForm: any
) => {
  const {
    showName,
    additionalInformation,
    contactName,
    contactPosition,
    phone,
    email,
    additionalContactInformation,
    password,
  } = values;

  if (selectedProductionCompany.productionCompanyId) {
    const show: any = {
      contact: {
        ContactPosition: contactPosition,
        contactEmail: email,
        contactInfo: additionalContactInformation,
        contactName: contactName,
        contactPhone: phone,
      },
      info: additionalInformation,
      password: password,
      productionCompany: {
        productionCompanyId: selectedProductionCompany.productionCompanyId,
        productionCompanyName: selectedProductionCompany.productionCompanyName,
      },
      showName: showName,
    };

    try {
      setLoading(true);
      await showClient.createShow(show);
      toast.success("Successfully added production");
      setLoading(false);
    } catch (error) {
      toast.error("Error adding production");
      console.error("Error in creating show:", error);
    } finally {
      if (selectedProductionCompany.productionCompanyId) {
        dispatch(
          getShowsByProductionCompany(
            selectedProductionCompany.productionCompanyId
          )
        );
      }
      dispatch(
        getShowsByProductionCompany(
          selectedProductionCompany.productionCompanyId
        )
      );
      dispatch(closeCreateProductionModal());
      resetForm();
    }
  }
};

interface ProductionFormFieldsProps {
  values: FormValues;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

// Component for production form fields
const ProductionFormFields = ({
  values,
  handleChange,
  errors,
  touched,
}: ProductionFormFieldsProps) => (
  <>
    <Grid item xs={12}>
      <Box sx={{ p: "16px 0px" }}>
        <Typography variant="h4">Production</Typography>
      </Box>
      <Field
        as={TextField}
        name="showName"
        fullWidth
        label="Production Name"
        value={values.showName}
        onChange={handleChange}
        error={Boolean(errors.showName && touched.showName)}
        helperText={touched.showName && errors.showName}
        sx={{
          "& .MuiOutlinedInput-input": {
            background: FORM_FIELD_BG_COLOR,
          },
          "& .MuiFormHelperText-root": { background: "#fff" },
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        as={TextField}
        name="additionalInformation"
        fullWidth
        multiline
        rows={4}
        label="Additional Information"
        value={values.additionalInformation}
        onChange={handleChange}
        error={Boolean(
          errors.additionalInformation && touched.additionalInformation
        )}
        helperText={
          touched.additionalInformation && errors.additionalInformation
        }
        sx={{
          "& .MuiInputBase-root": {
            background: FORM_FIELD_BG_COLOR,
          },
          "& .MuiFormHelperText-root": { background: "#fff" },
        }}
      />
    </Grid>
  </>
);

interface ContactFormFieldsProps {
  values: FormValues;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

// Component for contact form fields
const ContactFormFields = ({
  values,
  handleChange,
  errors,
  touched,
}: ContactFormFieldsProps) => (
  <>
    <Grid item xs={12}>
      <Box>
        <Typography variant="h4">Contact</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Field
        as={TextField}
        name="contactName"
        fullWidth
        label="Contact Name"
        value={values.contactName}
        onChange={handleChange}
        error={Boolean(errors.contactName && touched.contactName)}
        helperText={touched.contactName && errors.contactName}
        sx={{
          "& .MuiOutlinedInput-input": {
            background: FORM_FIELD_BG_COLOR,
          },
          "& .MuiFormHelperText-root": { background: "#fff" },
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        as={TextField}
        name="contactPosition"
        fullWidth
        label="Contact Position"
        value={values.contactPosition}
        onChange={handleChange}
        error={Boolean(errors.contactPosition && touched.contactPosition)}
        helperText={touched.contactPosition && errors.contactPosition}
        sx={{
          "& .MuiOutlinedInput-input": {
            background: FORM_FIELD_BG_COLOR,
          },
          "& .MuiFormHelperText-root": { background: "#fff" },
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        as={TextField}
        name="phone"
        fullWidth
        label="Contact Phone"
        value={values.phone}
        onChange={handleChange}
        error={Boolean(errors.phone && touched.phone)}
        helperText={touched.phone && errors.phone}
        sx={{
          "& .MuiOutlinedInput-input": {
            background: FORM_FIELD_BG_COLOR,
          },
          "& .MuiFormHelperText-root": { background: "#fff" },
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        as={TextField}
        name="email"
        fullWidth
        label="Contact Email"
        value={values.email}
        onChange={handleChange}
        error={Boolean(errors.email && touched.email)}
        helperText={touched.email && errors.email}
        sx={{
          "& .MuiOutlinedInput-input": {
            background: FORM_FIELD_BG_COLOR,
          },
          "& .MuiFormHelperText-root": { background: "#fff" },
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        as={TextField}
        name="additionalContactInformation"
        fullWidth
        multiline
        rows={4}
        label="Additional Contact Information"
        value={values.additionalContactInformation}
        onChange={handleChange}
        error={Boolean(
          errors.additionalContactInformation &&
            touched.additionalContactInformation
        )}
        helperText={
          touched.additionalContactInformation &&
          errors.additionalContactInformation
        }
        sx={{
          "& .MuiInputBase-root": {
            background: FORM_FIELD_BG_COLOR,
          },
          "& .MuiFormHelperText-root": { background: "#fff" },
        }}
      />
    </Grid>
  </>
);

// The main component
const CreateProductionModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedProductionCompany = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany
  );
  const isCreateProductionModalOpen = useSelector(
    (state: any) => state.modal.createProductionModalOpen
  );

  return (
    <Dialog
      open={isCreateProductionModalOpen}
      onClose={() => dispatch(closeCreateProductionModal())}
    >
      <DialogTitle variant="h2" sx={{ p: "16px" }}>
        Create a production
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (selectedProductionCompany?.productionCompanyId) {
            handleCreate(
              values,
              selectedProductionCompany,
              dispatch,
              setLoading,
              resetForm
            );
          }
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, errors, touched, resetForm }) => (
          <Form id="create-production-form">
            <Grid
              container
              spacing={3}
              sx={{
                p: "16px",
                width: "100%",
                margin: "0px",
                "& .MuiGrid-item": { p: "8px 0px" },
              }}
            >
              <ProductionFormFields
                {...{ values, handleChange, errors, touched }}
              />
              <ContactFormFields
                {...{ values, handleChange, errors, touched }}
              />

              <Grid item xs={12}>
                <Grid sx={{ display: "flex", justifyContent: "end" }} container>
                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{
                        boxShadow: "none",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#000",
                          }}
                          size={20}
                        />
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </Grid>

                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={loading}
                      onClick={() => {
                        dispatch(closeCreateProductionModal());
                      }}
                      sx={{
                        boxShadow: "none",
                        background: "#fff",
                        color: "#000",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateProductionModal;
