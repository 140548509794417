import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DefaultRootStateProps } from "types";
import { ProductionCompany } from "types/productionCompany";
import { productionCompanyClient } from "services/api/manual/productionCompany";

const initialState: DefaultRootStateProps["productionCompany"] = {
  error: null,
  selectedProductionCompany: {
    productionCompanyId: null,
    productionCompanyName: null,
  },
  productionCompanyList: [],
};

const productionCompanySlice = createSlice({
  name: "productionCompany",
  initialState,
  reducers: {
    getProductionCompanySuccess(
      state,
      action: PayloadAction<ProductionCompany>
    ) {
      state.selectedProductionCompany = action.payload;
    },
    getProductionCompaniesSuccess(
      state,
      action: PayloadAction<ProductionCompany[]>
    ) {
      state.productionCompanyList = action.payload;
    },
    setSelectedProductionCompany(
      state,
      action: PayloadAction<ProductionCompany | null>
    ) {
      state.selectedProductionCompany = action.payload;
    },
    hasError(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
  },
});

export function getProductionCompanies() {
  return async (dispatch: any) => {
    productionCompanyClient
      .getProductionCompanies()
      .then((productionCompanies) => {
        dispatch(
          productionCompanySlice.actions.getProductionCompaniesSuccess(
            productionCompanies
          )
        );
      })
      .catch((error) => {
        dispatch(productionCompanySlice.actions.hasError(error));
      });
  };
}

export const { setSelectedProductionCompany } = productionCompanySlice.actions;

export default productionCompanySlice.reducer;
