import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Typography,
  Divider,
  IconButton,
} from "@mui/material/";

import { closeModal } from "store/slices/modal";
import { useDispatch, useSelector } from "store";
import { useCustomTheme } from "themes";

const modalStyle = (theme: any) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: theme.palette.tertiary.light,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "8px",
  boxShadow: 24,
  p: "40px",
});

export default function MeetingModal() {
  const theme = useCustomTheme();
  const isModalOpen = useSelector((state) => state.modal.isOpen);
  const currentMeeting = useSelector((state) => state.meeting.currentMeeting);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isModalOpen}>
        <Box sx={modalStyle(theme)}>
          <Box sx={{ position: "absolute", right: "4px", top: "4px" }}>
            <IconButton onClick={handleClose} aria-label="delete">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              bgcolor: theme.palette.background.paper,
              p: "16px",
              border: `1px solid ${theme.palette.tertiary.main}`,
              borderRadius: "8px",
            }}
          >
            <Box sx={{ pb: "16px" }}>
              <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                {currentMeeting?.title}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", pb: "16px" }}>
              <Typography sx={{ fontSize: "18px" }}>
                {currentMeeting?.agenda}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ m: "0px 8px" }} />

              <Box sx={{ display: "flex" }}>
                <Typography id="" variant="h4" component="h2">
                  {`Duration:`}
                </Typography>
                <Typography sx={{ pl: 1 }}>
                  {currentMeeting?.duration}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ py: "16px" }}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px", pb: "8px" }}
                gutterBottom
              >
                Attendees
              </Typography>

              <Box sx={{ pb: "8px" }}>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                  gutterBottom
                >{`UIS team:`}</Typography>
                <Typography gutterBottom>
                  {currentMeeting?.attendeesUis}
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                  gutterBottom
                >{`Production team: `}</Typography>
                <Typography gutterBottom>
                  {currentMeeting?.attendeesProd}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                gutterBottom
              >
                Meeting Information
              </Typography>
              <Typography>{currentMeeting?.meetingInfo}</Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
