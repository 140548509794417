import axios from "axios";
import { stringify } from "qs";

import { tokenStorage } from "../storage";

import { authClient } from "./auth";

import { store } from "store";

export const httpClient = axios.create({
  paramsSerializer: {
    serialize: (params) => stringify(params, { arrayFormat: "repeat" }),
  },
});

httpClient.interceptors.request.use((requestConfig) => {
  const accessToken = tokenStorage.getIdToken();
  if (accessToken) {
    requestConfig.headers!.Authorization = `Bearer ${accessToken}`;
  }
  return requestConfig;
});

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = tokenStorage.getRefreshToken();

    if (
      error.response?.status === 401 &&
      refreshToken &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const state = store.getState();
      const { selectedShow } = state.show;
      const { selectedTemplate } = state.templates;
      const { role } = state.manualUser;
      const showName = selectedShow?.showName;

      try {
        if (role === "user" && showName && selectedTemplate) {
          const newTokens = await authClient.refreshToken(
            refreshToken,
            showName
          );
          updateTokens(newTokens);
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${newTokens.id_token}`;
          return httpClient(originalRequest);
        }
      } catch (err) {
        console.warn("![warning] Failed to refresh token.");
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

const updateTokens = (newTokens: any) => {
  tokenStorage.setIdToken(newTokens.id_token);
  tokenStorage.setRefreshToken(newTokens.refresh_token);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${newTokens.id_token}`;
};
