import { toast } from "react-toastify";

import { documentDownload } from "./downloadDocument";

import { getDocumentUrl } from "modules/productionManual/manual/api/getDocumentUrl";
import { SelectedDocument } from "types";

export const handleMultipleDownloads = async (
  documents: SelectedDocument[],
  setLoading: (loading: boolean) => void
) => {
  try {
    setLoading(true);

    const urlPromises = documents.map(async (doc) => {
      const url = await getDocumentUrl(doc.id);
      return { url, name: doc.name };
    });

    const urlsAndNames = await Promise.all(urlPromises);

    for (const { url, name } of urlsAndNames) {
      if (url) {
        try {
          await documentDownload(url, name);
          toast.success(`Downloaded: ${name}`);
        } catch (error) {
          console.error("Download error for file", name, ":", error);
          toast.error(`Failed to download: ${name}`);
        }
      } else {
        toast.error(`URL not found for: ${name}`);
      }
    }
  } catch (error) {
    console.error("Error fetching document URLs:", error);
    toast.error("Download failed");
  } finally {
    setLoading(false);
  }
};
