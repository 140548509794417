import React from "react";

import useModalState from "../../../../hooks/useModalState";

import CreateTemplateMeetingModal from "components/Modals/CreateTemplateMeetingModal";
import CreateTemplateModal from "components/Modals/CreateTemplateModal";
import DeleteTemplateModal from "components/Modals/DeleteTemplateModal";
import DeleteTemplateMeetingModal from "components/Modals/DeleteTemplateMeetingModal";

const TemplateModals = () => {
  const modalState = useModalState();

  return (
    <>
      {modalState.createTemplateMeetingModalOpen && (
        <CreateTemplateMeetingModal />
      )}
      {modalState.createTemplateModalOpen && <CreateTemplateModal />}
      {modalState.deleteProductionTemplateModalOpen && <DeleteTemplateModal />}
      {modalState.deleteTemplateMeetingModalOpen && (
        <DeleteTemplateMeetingModal />
      )}
    </>
  );
};

export default TemplateModals;
