import { stringify } from "qs";

import { httpClient } from "../httpClient";

import {
  ADMIN_DOC_API_URL,
  USER_DOC_API_URL,
  USER_DOC_PUBLIC_API_URL,
} from "config";
import { AdminUser, CurrentUser } from "types/user";

function userLogin(showName: string, password: string): Promise<any> {
  const data = stringify({ showName, password });
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return httpClient
    .post(`${USER_DOC_PUBLIC_API_URL}/login`, data, { headers })
    .then((resp) => {
      return resp.data;
    });
}

function getCurrentUser(): Promise<CurrentUser> {
  return httpClient.get(`${USER_DOC_API_URL}/current`).then((resp) => {
    return resp.data ?? {};
  });
}

function getAdminUsers(): Promise<AdminUser[]> {
  return httpClient.get(`${ADMIN_DOC_API_URL}/users`).then((resp) => {
    return resp.data ?? [];
  });
}

function modifyUser(data: any): Promise<any> {
  return httpClient
    .post(`${ADMIN_DOC_API_URL}/users/modify_groups`, data)
    .then((resp) => {
      return resp.data ?? [];
    });
}

export const userClient = {
  userLogin,
  getCurrentUser,
  getAdminUsers,
  modifyUser,
};
