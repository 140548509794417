import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminList from "components/List/AdminList/AdminList";
import { useDispatch } from "store";
import { getDocuments } from "store/slices/document";
import ShowSelectMessage from "components/ShowSelect/ShowSelectMessage";
import { Document } from "types/document";
import {
  openCreateDocumentModal,
  openDeleteDocumentModal,
  openUpdateDocumentModal,
} from "store/slices/modal";
import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

interface AdminManualData {
  id: number;
  documentName: string;
  category: string;
  subcategory: string;
  region: string;
}

const headCells: any = [
  {
    id: "documentName",
    numeric: false,
    label: "Document Title",
    align: "left",
  },
  {
    id: "category",
    numeric: false,
    label: "Document Category",
    align: "left",
  },
  {
    id: "subcategory",
    numeric: false,
    label: "Document Subcategory",
    align: "left",
  },
  {
    id: "region",
    numeric: false,
    label: "Document Region",
    align: "left",
  },
];

const AdminProductionVendors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showId = useSelector((state: any) => state.show.selectedShow.showId);
  const dataRows = useSelector((state: any) => state.document.documents);

  const vendorDocs = useMemo(() => {
    return dataRows.filter(
      (doc: Document) => doc.category === "Production Vendors"
    );
  }, [dataRows]);

  useEffect(() => {
    if (showId) {
      dispatch(getDocuments(showId, navigate));
    }
  }, [dispatch, showId, navigate]);

  function createData(
    documentId: number,
    documentName: string,
    category: string,
    subcategory: string,
    region: string
  ): AdminManualData {
    return {
      id: documentId,
      documentName: documentName,
      category: category,
      subcategory: subcategory,
      region: region,
    };
  }

  const rows = vendorDocs.map(
    (row: {
      documentId: number;
      documentName: string;
      category: string;
      subcategory: string;
      region: string;
    }) => {
      return createData(
        row.documentId,
        row.documentName,
        row.category,
        row.subcategory,
        row.region
      );
    }
  );

  return (
    <Box sx={{ p: "16px", width: "100%", height: "100%" }}>
      <ShowTitleSection />
      {!showId && <ShowSelectMessage />}
      {showId && (
        <AdminList
          title={"Production Vendors"}
          categoryTitle={"Document"}
          categoryId={3}
          rows={rows}
          editIcon
          searchComponent={true}
          onDelete={openDeleteDocumentModal}
          onAdd={openCreateDocumentModal}
          onEdit={openUpdateDocumentModal}
          editTooltipText={"Update document"}
          headCells={headCells}
          searchProp={"documentName"}
        />
      )}
    </Box>
  );
};

export default AdminProductionVendors;
