import {
  Link,
  Typography,
  Stack,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { useCustomTheme } from "../../themes";

const ManualFooter = () => {
  const theme = useCustomTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      direction={matchDownMd ? "column" : "row"}
      spacing={1}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: matchDownMd ? "center" : "space-between",
        p: matchDownMd ? "16px 0" : "32px 16px 16px 16px",
      }}
    >
      <Grid item>
        <Typography
          variant={matchDownMd ? "subtitle2" : "subtitle1"}
          sx={{ textAlign: matchDownMd ? "center" : "left" }}
        >
          {`© ${new Date().getFullYear()} NBCUniversal Media, LLC. All Rights Reserved.`}
        </Typography>
      </Grid>

      <Grid item>
        <Stack
          direction="row"
          spacing={1}
          alignItems={"center"}
          sx={{ textAlign: "center" }}
        >
          <Typography
            variant={matchDownMd ? "subtitle2" : "subtitle1"}
            component={Link}
            href="https://www.nbcuniversal.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            Privacy Policy
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant={matchDownMd ? "subtitle2" : "subtitle1"}>
            <RouterLink to={"/terms"} style={{ fontFamily: "inherit" }}>
              <Typography
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Terms Of Service
              </Typography>
            </RouterLink>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ManualFooter;
