import * as React from "react";
import Box from "@mui/material/Box";
import { Parallax } from "react-parallax";

import { useWindowSize } from "../../../../hooks";
import image_small from "../../../../assets/images/home/hero-small.png";
import image_medium from "../../../../assets/images/home/hero-medium.png";
import image_large from "../../../../assets/images/home/hero-large.png";
import image_xl from "../../../../assets/images/home/hero-xl.png";

export const Hero = () => {
  const windowSize = useWindowSize();

  const responsiveHeight = {
    height: {
      xs: "200px",
      sm: "300px",
      md: "520px",
      lg: "720px",
    },
  };

  return (
    <Parallax
      style={windowSize.width < 500 ? { marginTop: "50px" } : {}}
      bgImageSizes="(min-width: 1200px) 1200px, 100vw"
      bgImageSrcSet={`${image_small} 320w, ${image_medium} 600w, ${image_large} 1200w, ${image_xl} 1920w`}
      bgImage={image_xl}
      strength={windowSize.width > 500 ? 500 : 150}
      bgImageStyle={{ width: "100%", height: "auto" }}
    >
      <Box
        sx={{
          minHeight: responsiveHeight.height,
        }}
      ></Box>
    </Parallax>
  );
};
