import React from "react";
import { Box } from "@mui/material";

import ManualList from "../components/ManualList/ManualList";

import { useSelector } from "store";
import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

const Manual = () => {
  const selectedShow = useSelector((state: any) => state.show.selectedShow);
  return (
    <Box
      sx={{ p: "16px", minHeight: "calc(100vh - 200px)", minWidth: "600px" }}
    >
      <ShowTitleSection />
      <ManualList showId={selectedShow.showId} />
    </Box>
  );
};

export default Manual;
