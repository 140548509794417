import { FC, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  IconHome2,
  IconBook,
  IconRibbonHealth,
  IconReport,
  IconGavel,
  IconNotebook,
} from "@tabler/icons-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";

import { images } from "../../../assets";
import NavDropdownTemp from "../Dropdown/NavDropdow";

import { manualLinks, policyLinks } from "./MainNavigationLinkData";

import { Logo } from "components/Logo";
import { useCustomTheme } from "themes";
import { useDispatch } from "store";
import { setSelectedPolicy } from "store/slices/policy";
import { setSelectedProductionCompany } from "store/slices/productionCompany";
import { openDropdownDrawer } from "store/slices/menu";

const BORDER_STYLE = "2px solid #6e13e4";

interface MainNavigationTempProps {}

export const PolicyLinksDropdown: React.FC = () => {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="policy-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        disableRipple
        sx={{
          color: theme.palette.primary.main,
          border: "none",
          px: "16px",
          "&:hover": { background: "none" },
        }}
      >
        Policies
        <ExpandMoreIcon sx={{ color: "#000" }} />
      </Button>

      <Menu
        id="policy-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            borderTop: BORDER_STYLE,
            borderBottom: BORDER_STYLE,
          },
        }}
      >
        {policyLinks.map((link, index) => (
          <MenuItem
            key={index}
            sx={{ p: "8px 16px" }}
            onClick={() => {
              dispatch(
                setSelectedPolicy({
                  policyName: link.name,
                  policyCode: link.code,
                })
              );
              navigate(link.to);
              handleClose();
            }}
          >
            <Typography>{link.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const ManualLinksDropdown: React.FC = () => {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="manual-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        disableRipple
        sx={{
          color: theme.palette.primary.main,
          border: "none",
          px: "16px",
          "&:hover": { background: "none" },
        }}
      >
        Production Manual
        <ExpandMoreIcon sx={{ color: "#000" }} />
      </Button>

      <Menu
        id="manual-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            borderTop: BORDER_STYLE,
            borderBottom: BORDER_STYLE,
          },
        }}
      >
        {manualLinks.map((link, index) => (
          <MenuItem
            key={index}
            sx={{ p: "8px 16px" }}
            onClick={() => {
              dispatch(
                setSelectedProductionCompany({
                  productionCompanyId: link.id,
                  productionCompanyName: link.name,
                })
              );
              navigate("/manual");
              handleClose();
            }}
          >
            <Typography>{link.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const mainSiteLinks = [
  {
    title: "Home",
    href: "/",
    hasDropdown: false,
    icon: <IconHome2 />,
    Component: null,
  },
  {
    title: "Policies",
    href: "",
    hasDropdown: true,
    dropdownKey: "policies",
    icon: <IconGavel />,
    Component: PolicyLinksDropdown,
  },
  {
    title: "Wellbeing",
    href: "/wellbeing",
    hasDropdown: false,
    icon: <IconRibbonHealth />,
    Component: null,
  },
  {
    title: "HOD Guide",
    href: "/hod-guide",
    hasDropdown: false,
    icon: <IconNotebook />,
    Component: null,
  },
  {
    title: "Production Manual",
    href: "",
    hasDropdown: true,
    dropdownKey: "manual",
    icon: <IconBook />,
    Component: ManualLinksDropdown,
  },
  {
    title: "Reporting",
    href: "https://secure.ethicspoint.com/domain/media/en/gui/21987/index.html",
    hasDropdown: false,
    external: true,
    icon: <IconReport />,
    Component: null,
  },
];

const MainNavigation: FC<MainNavigationTempProps> = () => {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const dropdownOpen = useSelector((state: any) => state.menu.dropdownOpen);

  return (
    <AppBar elevation={0} position="static">
      <Toolbar
        sx={{
          height: "86px",
          background: "#fff",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: `1px solid ${theme.palette.tertiary.main}`,
        }}
      >
        <Logo source={images.logo.sm} height="50" />

        {!matchDownMd && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {mainSiteLinks.map((link, index) => {
              if (link.Component) {
                const DropdownComponent = link.Component;
                return <DropdownComponent key={index} />;
              }

              if (link.external) {
                return (
                  <Link
                    key={index}
                    href={link.href}
                    sx={{
                      textDecoration: "none",
                      px: "16px",
                      "&:hover": { textDecoration: "none" },
                    }}
                  >
                    <Typography>{link.title}</Typography>
                  </Link>
                );
              }

              return (
                <RouterLink
                  style={{
                    color: theme.palette.primary.main,
                    padding: "0 16px 0 16px",
                  }}
                  key={index}
                  to={link.href}
                >
                  <Typography>{link.title}</Typography>
                </RouterLink>
              );
            })}
          </Box>
        )}

        {matchDownMd && (
          <IconButton
            edge="start"
            sx={{ color: "#000" }}
            aria-label="menu"
            onClick={() => dispatch(openDropdownDrawer(!dropdownOpen))}
          >
            <MenuIcon />
          </IconButton>
        )}

        <NavDropdownTemp />
      </Toolbar>
    </AppBar>
  );
};

export default MainNavigation;
