import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { useDispatch } from "../store";
import { menuItems } from "../components/Layout/ProductionManualLayout/MenuList";
import { activeItem } from "../store/slices/menu";

const SetPath = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const relevantPath = pathParts[2];
    const currentItem = menuItems.find(
      (item) => item.url.split("/")[2] === relevantPath
    );

    if (currentItem) {
      dispatch(activeItem({ id: currentItem.id, name: currentItem.title }));
    }
  }, [location.pathname, dispatch]);

  return <>{children}</>;
};

export default SetPath;
