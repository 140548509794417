import { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import { useDispatch } from "store";
import {
  closeDeleteMeetingModal,
  closeDeleteUserModal,
} from "store/slices/modal";
import { userClient } from "services/api/manual/user";
import { getAdminUsers } from "store/slices/user";

const DeleteUserModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const deleteUserModalOpen = useSelector(
    (state: any) => state.modal.deleteUserModalOpen
  );

  const deleteUserModalParams = useSelector(
    (state: any) => state.modal.deleteUserModalParams.id
  );

  const userList = useSelector(
    (state: any) => state.user.users.manualAdminUsers.users
  );

  const selectedUser = userList.find(
    (item: any) => Number(item.sso) === deleteUserModalParams
  );

  const handleDelete = async (user: any) => {
    const data = {
      IsDeleted: true,
      groupNames: user.roles,
      memberSSO: user.sso,
    };

    if (user) {
      try {
        setLoading(true);
        await userClient.modifyUser(data);
        setLoading(false);
        toast.success("Successfully deleted user");
        dispatch(getAdminUsers());
      } catch (error) {
        toast.error("Error deleting user");
        console.error("Error:", error);
      } finally {
        dispatch(closeDeleteUserModal());
      }
    }
  };

  return (
    <Dialog
      sx={{ "& .MuiPaper-root": { p: "8px" } }}
      open={deleteUserModalOpen}
      onClose={() => dispatch(closeDeleteMeetingModal())}
    >
      <DialogTitle variant="h2" sx={{ py: 4 }}>
        {`Delete User: "${selectedUser?.sso}"?`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to delete user{" "}
              <strong style={{ fontFamily: "inherit" }}>
                {selectedUser?.sso}
              </strong>
              ?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: "16px 24px" }}>
        <Button
          sx={{
            boxShadow: "none",
            background: "#eb1e13",
            "&:hover": {
              boxShadow: "none",
              color: "#eb1e13",
              background: "#fff",
              border: "1px solid #eb1e13",
            },
          }}
          variant="contained"
          disabled={loading}
          onClick={() => handleDelete(selectedUser)}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: "#000",
              }}
              size={20}
            />
          ) : (
            "Delete"
          )}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            dispatch(closeDeleteUserModal());
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModal;
