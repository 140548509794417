import { nanoid } from "nanoid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import { formatPdfString } from "../../../utils/formatPdfString";
import { getPolicy } from "../api/getPolicy";

const linkPadding = "8px 8px 32px 8px";

type PolicyListProps = {
  policies: string[];
};

type PolicyButtonProps = {
  onClick: (item: any) => void;
  item: any;
  formattedString: string;
};

const PolicyButton = ({
  onClick,
  item,
  formattedString,
}: PolicyButtonProps) => {
  return (
    <Button
      key={nanoid()}
      onClick={() => onClick(item)}
      sx={{
        textDecoration: "none",
        fontSize: "15px",
        backgroundColor: "#6e13e4",
        px: "32px",
        py: "8px",
        border: "1px solid #6e13e4",
        borderRadius: "1000px",
        width: "fit-content",
        color: "white",
        textAlign: "center",
        "&:hover": {
          px: "32px",
          py: "8px",
          borderColor: "#6e13e4",
          backgroundColor: "white",
          color: "#6e13e4",
        },
        transition: "all 400ms",
      }}
    >
      <Typography>{formattedString}</Typography>
    </Button>
  );
};

export const PolicyList = ({ policies }: PolicyListProps) => {
  const policyCode = useSelector(
    (state: any) => state.policy.selectedPolicy.policyCode
  );

  const handleClick = (policy: string): void => {
    getPolicy(policy).then((res) => {
      const url = res.data.URL;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", `_blank`);
      const addedLink = document.body.appendChild(link);
      link.click();
      document.body.removeChild(addedLink);
    });
  };

  return (
    <Box>
      <Typography sx={{ py: 2, fontSize: "16px" }} gutterBottom>
        <b style={{ fontFamily: "inherit" }}>Click</b> on the appropriate
        buttons below to access the Policies you need.
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {policies.map((item: string) => {
          const formattedString = formatPdfString(item);

          if (
            formatPdfString(item).includes("Child Protection") &&
            policyCode === "uis"
          ) {
            return (
              <Box key={nanoid()}>
                <PolicyButton
                  onClick={handleClick}
                  item={item}
                  formattedString={formattedString}
                />
                <Box sx={{ p: linkPadding }}>
                  <Typography variant="caption">
                    This document sets out the general Child Protection Policy
                    principles for UIS productions, a bespoke child protection
                    policy will also be provided for your production, please
                    contact Employment Legal.
                  </Typography>
                </Box>
              </Box>
            );
          }

          if (
            formatPdfString(item).includes("Health and Safety Policy") &&
            policyCode === "uis"
          ) {
            return (
              <Box key={nanoid()}>
                <PolicyButton
                  onClick={handleClick}
                  item={item}
                  formattedString={formattedString}
                />
                <Box sx={{ p: linkPadding }}>
                  <Typography variant="caption">
                    This document sets out the general Health & Safety
                    guidelines for UIS productions, a bespoke H&S policy will be
                    provided for your production, please contact EHS.
                  </Typography>
                </Box>
              </Box>
            );
          }

          if (
            formatPdfString(item).includes("Health and Safety Policy") &&
            policyCode === "mk"
          ) {
            return (
              <Box key={nanoid()}>
                <PolicyButton
                  onClick={handleClick}
                  item={item}
                  formattedString={formattedString}
                />
                <Box sx={{ p: linkPadding }}>
                  <Typography variant="caption">
                    This document sets out the general Health & Safety
                    guidelines for UIS productions, a bespoke H&S policy will be
                    provided for your production, please contact EHS.
                  </Typography>
                </Box>
              </Box>
            );
          }

          if (
            formatPdfString(item).includes("Child Protection Policy") &&
            policyCode === "mk"
          ) {
            return (
              <Box key={nanoid()}>
                <PolicyButton
                  onClick={handleClick}
                  item={item}
                  formattedString={formattedString}
                />
                <Box sx={{ p: linkPadding }}>
                  <Typography variant="caption">
                    This document sets out the general Child Protection Policy
                    principles for Monkey Kingdom productions, a bespoke child
                    protection policy will also be provided for your production
                    if applicable, please contact Employment Legal.
                  </Typography>
                </Box>
              </Box>
            );
          }

          return (
            <Box key={nanoid()} sx={{ pb: "32px" }}>
              <PolicyButton
                onClick={handleClick}
                item={item}
                formattedString={formattedString}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
