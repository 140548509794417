import { Outlet } from "react-router-dom";

import { Footer } from "../../Footer";

import MainNavigation from "components/Navigation/MainNavigation/MainNavigation";

export const MainLayout = () => {
  return (
    <div>
      <MainNavigation />
      <Outlet />
      <Footer />
    </div>
  );
};
