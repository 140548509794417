import React from "react";

import useModalState from "../../../../hooks/useModalState";

import CreateMeetingModal from "components/Modals/CreateMeetingModal";
import UpdateMeetingModal from "components/Modals/UpdateMeetingModal";
import DeleteMeetingModal from "components/Modals/DeleteMeetingModal";

const MeetingModals = () => {
  const modalState = useModalState();

  return (
    <>
      {modalState.createMeetingModalOpen && <CreateMeetingModal />}
      {modalState.updateMeetingModalOpen && <UpdateMeetingModal />}
      {modalState.deleteMeetingModalOpen && <DeleteMeetingModal />}
    </>
  );
};

export default MeetingModals;
