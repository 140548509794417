import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const LoaderWrapper = styled("div")({
  position: "fixed",
  top: "86px",
  left: 0,
  zIndex: 1300,
  width: "100%",
});

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="inherit" />
  </LoaderWrapper>
);

export default Loader;
