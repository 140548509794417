import * as React from "react";

type HodTeamMemberCardProps = {
  image: string;
  title: string;
  position: string;
  email: string;
};

export const HodTeamMemberCard = ({
  image,
  title,
  position,
  email,
}: HodTeamMemberCardProps) => {
  return (
    <div className="tablet:px-[0px] phone:px-[25px] phone:py-[20px] max-w-[100vw]">
      <div className="w-full pb-2">
        <img className="w-full rounded-xl" src={image} alt={title} />
      </div>
      <div>
        <h1 className="text-[16px] pb-[3px]">{title}</h1>
        <p className="text-[12px] pb-[2px]">{position}</p>
        <p className="text-[12px] break-words">{email}</p>
      </div>
    </div>
  );
};
