import { documentClient } from "../../../../services/api/manual";

export async function getDocumentUrl(id: number) {
  return documentClient
    .getDocumentByID(id)
    .then((doc) => doc.documentURl)
    .catch((error) => {
      console.error("Error fetching document URL:", error);
      return null;
    });
}
