import { useState } from "react";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { useDispatch } from "store";
import { closeUpdatePasswordModal } from "store/slices/modal";
import { showClient } from "services/api/manual";
import { ShowState } from "types/show";
import { getShowsByProductionCompany } from "store/slices/show";

interface FormValues {
  password: string;
  confirmPassword: string;
}

const UpdatePasswordModal = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const selectedShowId = useSelector(
    (state: ShowState) => state.show.show.showId
  );

  const updatePasswordModalOpen = useSelector(
    (state: any) => state.modal.updatePasswordModalOpen
  );
  const productionCompanyId = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyId
  );

  const handleUpdate = async (values: FormValues) => {
    const { password } = values;

    try {
      setLoading(true);
      await showClient.updatePassword(Number(selectedShowId), password);
      toast.success("Successfully Updated Password");
      dispatch(getShowsByProductionCompany(productionCompanyId));
    } catch (error) {
      toast.error("Error updating password");
      console.error("Error in updating password:", error);
    } finally {
      dispatch(closeUpdatePasswordModal());
    }
  };

  const ProductionSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*]).{8,}$/, {
        message:
          "Password must be at least 8 characters, include one uppercase letter, one lowercase letter, one number, and one special character (!@#$&*).",
      }),
    confirmPassword: Yup.string()
      .required("Password confirmation is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={ProductionSchema}
      onSubmit={(values, { resetForm }) => {
        handleUpdate(values);
        resetForm();
      }}
    >
      {({ values, handleChange, errors, touched, resetForm }) => (
        <Form id="update-password-form">
          <Dialog
            sx={{ "& .MuiPaper-root": { p: "8px" } }}
            open={updatePasswordModalOpen}
            onClose={() => dispatch(closeUpdatePasswordModal())}
          >
            <DialogTitle variant="h2" sx={{ py: 4 }}>
              Update password
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ pt: 2 }}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    type="password"
                    name="password"
                    fullWidth
                    label="New Password"
                    value={values.password}
                    onChange={handleChange}
                    error={Boolean(errors.password && touched.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    type="password"
                    name="confirmPassword"
                    fullWidth
                    label="Confirm Password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={Boolean(
                      errors.confirmPassword && touched.confirmPassword
                    )}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: "16px 24px" }}>
              <Button
                sx={{
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#e6e6e6",
                    boxShadow: "none",
                    border: "1px solid #000",
                  },
                }}
                disabled={loading}
                form="update-password-form"
                type="submit"
                variant="contained"
              >
                {loading ? (
                  <CircularProgress
                    style={{
                      color: "#000",
                    }}
                    size={20}
                  />
                ) : (
                  "Update"
                )}
              </Button>
              <Button
                variant="text"
                disabled={loading}
                onClick={() => {
                  dispatch(closeUpdatePasswordModal());
                  resetForm();
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePasswordModal;
