import { FC, memo } from "react";
import { Box, Link, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import {
  COMCAST_CODE_OF_CONDUCT,
  COMCAST_CODE_OF_CONDUCT_LINK,
} from "../../../constants";

const uisPolicyLinks = [
  {
    id: 1,
    title: COMCAST_CODE_OF_CONDUCT,
    link: COMCAST_CODE_OF_CONDUCT_LINK,
  },
  {
    id: 2,
    title: "Respect In The Workplace",
    link: "https://www.nbcunow.com/_flysystem/s3-flysystem/DMS/Respect%20in%20the%20Workplace%20Policy%20-%207.18.23.pdf",
  },
];

const mkPolicyLinks = [
  {
    id: 1,
    title: COMCAST_CODE_OF_CONDUCT,
    link: COMCAST_CODE_OF_CONDUCT_LINK,
  },
  {
    id: 2,
    title: "Respect In The Workplace",
    link: "https://www.nbcunow.com/_flysystem/s3-flysystem/DMS/Respect%20in%20the%20Workplace%20Policy%20-%207.18.23.pdf",
  },
];

const mpPolicyLinks = [
  {
    id: 1,
    title: COMCAST_CODE_OF_CONDUCT,
    link: COMCAST_CODE_OF_CONDUCT_LINK,
  },
];

const linkStyle = {
  textDecoration: "none",
  fontSize: "15px",
  backgroundColor: "#6e13e4",
  px: "32px",
  py: "8px",
  mb: "32px",
  border: "1px solid #6e13e4",
  borderRadius: "1000px",
  width: "fit-content",
  color: "white",
  textAlign: "center",
  "&:hover": {
    backgroundColor: "white",
    color: "#6e13e4",
  },
  transition: "all 400ms",
};

interface PolicyLinks {
  uis: { id: number; title: string; link: string }[];
  mk: { id: number; title: string; link: string }[];
  mp: { id: number; title: string; link: string }[];
}

const PolicyLinkList: FC = () => {
  const policyLinksMap: PolicyLinks = {
    uis: uisPolicyLinks,
    mk: mkPolicyLinks,
    mp: mpPolicyLinks,
  };

  const policyCode = useSelector<any, keyof PolicyLinks>(
    (state) => state.policy.selectedPolicy.policyCode
  );

  const currentLinks = policyLinksMap[policyCode] ?? [];

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {currentLinks.map((policy) => (
        <Link
          key={policy.id}
          href={policy.link}
          target="_blank"
          rel="noopener noreferrer"
          sx={linkStyle}
        >
          <Typography>{policy.title}</Typography>
        </Link>
      ))}
    </Box>
  );
};

export default memo(PolicyLinkList);
