import React from "react";

import DocumentModals from "./DocumentModals";
import TemplateModals from "./TemplateModals";
import ProductionModals from "./ProductionModals";
import MeetingModals from "./MeetingModals";
import UserModals from "./UserModals";

const Modals = () => {
  return (
    <>
      <DocumentModals />
      <TemplateModals />
      <ProductionModals />
      <MeetingModals />
      <UserModals />
    </>
  );
};

export default Modals;
