import { createSlice } from "@reduxjs/toolkit";

import { DefaultRootStateProps } from "types";

const initialState: DefaultRootStateProps["menu"] = {
  selectedItem: { id: "1", name: "Home" },
  drawerOpen: false,
  dropdownOpen: false,
  menuType: "user",
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.selectedItem = action.payload;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },
    openDropdownDrawer(state, action) {
      state.dropdownOpen = action.payload;
    },
    setMenuType(state, action) {
      state.menuType = action.payload;
    },
  },
});

export const { activeItem, openDrawer, setMenuType, openDropdownDrawer } =
  menuSlice.actions;

export default menuSlice.reducer;
