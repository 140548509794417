import { createSlice } from "@reduxjs/toolkit";

import { DefaultRootStateProps } from "types";

const initialState: DefaultRootStateProps["manualUser"] = {
  isLoggedIn: false,
  loggedInCompany: null,
  loggedInShow: null,
  role: null,
  consent: false,
};

export const userSlice = createSlice({
  name: "manualUser",
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setLoggedInCompany: (state, action) => {
      state.loggedInCompany = action.payload;
    },
    setLoggedInShow: (state, action) => {
      state.loggedInShow = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setConsent: (state, action) => {
      state.consent = action.payload;
    },
  },
});

export const {
  setIsLoggedIn,
  setLoggedInCompany,
  setLoggedInShow,
  setRole,
  setConsent,
} = userSlice.actions;

export default userSlice.reducer;
