import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";

import { DOC_REGIONS, MANUAL_PARAS } from "../../../../../constants";
import { viewDocument } from "../../../../../utils/viewDocument";
import { getDocumentUrl } from "../../api/getDocumentUrl";
import { documentDownload } from "../../../../../utils/downloadDocument";

import { getDocuments } from "store/slices/document";
import MainCard from "components/Cards/MainCard";
import {
  GetComparator,
  EnhancedTableHeadProps,
  HeadCell,
  EnhancedTableToolbarProps,
  ArrangementOrder,
  KeyedObject,
  SelectedDocument,
} from "types";
import { useDispatch, useSelector } from "store";
import { handleMultipleDownloads } from "utils/handleMultipleDownloads";
import { Document } from "types/document";

function descendingComparator(a: KeyedObject, b: KeyedObject, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator: GetComparator = (order, orderBy) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(
  array: Document[],
  comparator: (a: Document, b: Document) => number
) {
  const stabilizedThis = array.map((el: Document, index: number) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0] as Document, b[0] as Document);
    if (order !== 0) return order;
    return (a[1] as number) - (b[1] as number);
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
  {
    id: "documentName",
    numeric: false,
    label: "Document Title",
    align: "left",
  },
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
  selected: SelectedDocument[];
  rows: Document[];
  completeTextDocument: Document | null;
}

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  onRequestSort,
  selected,
  rows,
  completeTextDocument,
}: CustomerListEnhancedTableHeadProps) {
  const createSortHandler =
    (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
      onRequestSort(event, property);
    };

  const isAllSelected = () => {
    const totalItems = rows.length + (completeTextDocument ? 1 : 0);
    return selected.length === totalItems;
  };

  const isIndeterminate = () => {
    return (
      selected.length > 0 &&
      selected.length < rows.length + (completeTextDocument ? 1 : 0)
    );
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ pl: 3 }}>
          <Checkbox
            color="primary"
            indeterminate={isIndeterminate()}
            checked={isAllSelected()}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all documents",
            }}
          />
        </TableCell>
        {numSelected > 0 && (
          <TableCell padding="none" colSpan={6}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              selected={selected}
            />
          </TableCell>
        )}
        {numSelected <= 0 &&
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {" "}
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        {numSelected <= 0 && (
          <TableCell
            sortDirection={false}
            align="right"
            sx={{ pr: 6, fontWeight: "bold" }}
          >
            Action
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({
  numSelected,
  selected,
}: EnhancedTableToolbarProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <Toolbar
      sx={{
        p: 0,
        pl: 1,
        pr: 1,
        ...(numSelected > 0 && {
          color: (theme) => theme.palette.secondary.main,
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography color="primary" variant="h4">
          {numSelected} Selected
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {numSelected > 0 && !loading && (
        <Tooltip title="Download Selected">
          <IconButton
            size="large"
            onClick={() => handleMultipleDownloads(selected, setLoading)}
          >
            <DownloadIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {loading && (
        <Box sx={{ p: "12px" }}>
          <CircularProgress color="success" size={20} />
        </Box>
      )}
    </Toolbar>
  );
};

// ==============================|| Region Filter||============================== //

interface RegionFilterProps {
  onRegionChange: (category: string) => void;
}

const RegionFilter = ({ onRegionChange }: RegionFilterProps) => {
  const [selectedRegion, setSelectedRegion] = useState<string>("All");

  const handleChange = (event: SelectChangeEvent<string>) => {
    const region = event.target.value;
    setSelectedRegion(region);
    onRegionChange(region);
  };

  return (
    <FormControl sx={{ minWidth: "110px" }}>
      <InputLabel
        sx={{
          backgroundColor: "white",
          padding: "0 4px",
        }}
        id="region-select-label"
      >
        Filter by region
      </InputLabel>
      <Select
        labelId="region-select-label"
        id="region-select"
        value={selectedRegion}
        label="All Documents"
        onChange={handleChange}
        sx={{
          textAlign: "center",
          "& .MuiSelect-select": {
            textAlignLast: "center",
          },
        }}
      >
        {DOC_REGIONS.map((Regions: string) => {
          return (
            <MenuItem key={nanoid()} value={Regions}>
              {Regions}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

// ==============================|| DOCUMENT LIST ||============================== //

interface ManualListProps {
  showId: number;
}

const ManualList = ({ showId }: ManualListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRegion, setSelectedRegion] = useState<string>("All");
  const [order, setOrder] = useState<ArrangementOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("documentName");
  const [selected, setSelected] = useState<SelectedDocument[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [rows, setRows] = useState<Document[]>([]);
  const [downloadingStatus, setDownloadingStatus] = useState<{
    [key: number]: boolean;
  }>({});
  const allDocuments = useSelector((state: any) => state.document.documents);
  const completeTextDocument = useSelector((state: any) =>
    state.document.documents?.find(
      (doc: Document) => doc.subcategory === "Production Manual"
    )
  );

  const completeTextDocumentLabelId = `enhanced-table-checkbox-complete-text`;
  const totalColumns = 1 + headCells.length + 1;

  useEffect(() => {
    dispatch(getDocuments(showId, navigate));
  }, [dispatch, showId, navigate]);

  useEffect(() => {
    if (!allDocuments) {
      setRows([]);
      return;
    }

    let filteredDocs = allDocuments.filter(
      (doc: Document) => doc.subcategory === "Manual Chapters"
    );

    if (selectedRegion !== "All") {
      filteredDocs = filteredDocs.filter(
        (doc: Document) =>
          doc.region.toLowerCase() === selectedRegion.toLowerCase()
      );
    }

    setRows(filteredDocs);
  }, [allDocuments, selectedRegion]);

  const handleRegionChange = (region: string) => {
    setSelectedRegion(region);
  };

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    let filteredDocs = allDocuments.filter(
      (doc: Document) => doc.subcategory === "Manual Chapters"
    );

    const newString = event?.target.value ?? "";

    setSearch(newString);

    if (newString) {
      filteredDocs = filteredDocs.filter((doc: any) => {
        const properties = ["documentName"];
        return properties.some((property) =>
          doc[property]
            .toString()
            .toLowerCase()
            .includes(newString.toLowerCase())
        );
      });
    }

    setRows(filteredDocs);
  };

  const handleRequestSort = (
    event: React.SyntheticEvent<Element, Event>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedDocuments = rows.map((doc) => ({
        name: doc.documentName,
        id: doc.documentId,
      }));

      if (
        completeTextDocument &&
        !newSelectedDocuments.some(
          (doc) => doc.id === completeTextDocument.documentId
        )
      ) {
        newSelectedDocuments.push({
          name: completeTextDocument.documentName,
          id: completeTextDocument.documentId,
        });
      }

      setSelected(newSelectedDocuments);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    document: Document
  ) => {
    const selectedIndex = selected.findIndex(
      (s) => s.id === document.documentId
    );
    let newSelected: SelectedDocument[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        name: document.documentName,
        id: document.documentId,
      });
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    event?.target.value && setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const handleDownload = async (
    documentId: number,
    fileName: string,
    docName: string
  ) => {
    try {
      setDownloadingStatus((prev) => ({ ...prev, [documentId]: true }));

      const url = await getDocumentUrl(documentId);
      const response = await documentDownload(url!, fileName);

      if (response.status === "ok") {
        setDownloadingStatus((prev) => ({ ...prev, [documentId]: false }));
      }
      toast.success(`Successfully downloaded "${docName}"`);
    } catch (error) {
      console.error("Error fetching document URL:", error);
      setDownloadingStatus((prev) => ({ ...prev, [documentId]: false }));
      toast.error(`Failed to download "${docName}"`);
    }
  };

  const isSelected = (name: string) =>
    selected.some((doc) => doc.name === name);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <MainCard
      sx={{
        borderRadius: "8px",
      }}
      title="Manual"
      content={false}
      boxShadow={false}
      border={true}
      subtitleParas={MANUAL_PARAS}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder="Search Documents"
              value={search}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
            <RegionFilter onRegionChange={handleRegionChange} />
          </Grid>
        </Grid>
      </CardContent>

      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            selected={selected}
            rows={rows}
            completeTextDocument={completeTextDocument}
          />
          <TableBody>
            <TableRow>
              <TableCell sx={{ p: "16px" }} colSpan={totalColumns} align="left">
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                  Complete Text of the Manual
                </Typography>
              </TableCell>
            </TableRow>
            {completeTextDocument && (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isSelected(completeTextDocument?.documentName)}
                tabIndex={-1}
                selected={isSelected(completeTextDocument?.documentName)}
              >
                <TableCell
                  padding="checkbox"
                  sx={{ pl: 3 }}
                  onClick={(event) => handleClick(event, completeTextDocument)}
                >
                  <Checkbox
                    color="primary"
                    checked={isSelected(completeTextDocument?.documentName)}
                    inputProps={{
                      "aria-labelledby": completeTextDocumentLabelId,
                    }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={completeTextDocumentLabelId}
                  scope="row"
                  sx={{ cursor: "pointer", p: "8px 16px" }}
                  onClick={(event) =>
                    handleClick(event, completeTextDocument?.documentName)
                  }
                >
                  <Typography variant="subtitle1">
                    {completeTextDocument?.documentName}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: "8px 16px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                  align="right"
                >
                  <Box
                    sx={{
                      width: "90px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="View">
                      <IconButton
                        color="primary"
                        size="large"
                        aria-label="View"
                        onClick={() => {
                          viewDocument(completeTextDocument.documentId);
                        }}
                      >
                        <VisibilityTwoToneIcon sx={{ fontSize: "1.3rem" }} />
                      </IconButton>
                    </Tooltip>

                    {downloadingStatus[completeTextDocument.documentId] && (
                      <Box sx={{ p: "12px" }}>
                        <CircularProgress color="success" size={20} />
                      </Box>
                    )}

                    {!downloadingStatus[completeTextDocument.documentId] && (
                      <Tooltip title="Download">
                        <IconButton
                          color="primary"
                          size="large"
                          aria-label="download"
                          onClick={() =>
                            handleDownload(
                              completeTextDocument.documentId,
                              completeTextDocument.documentPath,
                              completeTextDocument.documentName
                            )
                          }
                        >
                          <DownloadIcon sx={{ fontSize: "1.3rem" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell colSpan={totalColumns} align="left">
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                  Manual Chapters
                </Typography>
              </TableCell>
            </TableRow>

            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                if (typeof row === "number") return null;
                const isItemSelected = isSelected(row.documentName);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{ pl: 3 }}
                      onClick={(event) => handleClick(event, row)}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      component="th"
                      id={labelId}
                      scope="row"
                      onClick={(event) => handleClick(event, row)}
                      sx={{ cursor: "pointer", p: "8px 16px" }}
                    >
                      <Typography variant="subtitle1">
                        {row.documentName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      sx={{
                        p: "8px 16px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                      align="right"
                    >
                      <Box
                        sx={{
                          width: "90px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="View">
                          <IconButton
                            color="primary"
                            size="large"
                            aria-label="View"
                            onClick={() => {
                              viewDocument(row.documentId);
                            }}
                          >
                            <VisibilityTwoToneIcon
                              sx={{ fontSize: "1.3rem" }}
                            />
                          </IconButton>
                        </Tooltip>

                        {downloadingStatus[row.documentId] && (
                          <Box sx={{ p: "12px" }}>
                            <CircularProgress color="success" size={20} />
                          </Box>
                        )}

                        {!downloadingStatus[row.documentId] && (
                          <Tooltip title="Download">
                            <IconButton
                              color="primary"
                              size="large"
                              aria-label="download"
                              onClick={() =>
                                handleDownload(
                                  row.documentId,
                                  row.documentPath,
                                  row.documentName
                                )
                              }
                            >
                              <DownloadIcon sx={{ fontSize: "1.3rem" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MainCard>
  );
};

export default ManualList;
