import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import AdminWelcomeMessageForm from "../components/AdminWelcomeMessageForm";

const AdminWelcome = () => {
  const welcomeMessage = useSelector(
    (state: any) => state.show.show.welcomeMessage
  );

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <AdminWelcomeMessageForm welcomeMessage={welcomeMessage} />
    </Box>
  );
};

export default AdminWelcome;
