import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { NavigateFunction } from "react-router-dom";

import { AppDispatch } from "../index";
import { documentClient } from "../../services/api/manual";

import { setSelectedShow } from "./show";
import {
  setIsLoggedIn,
  setLoggedInCompany,
  setLoggedInShow,
  setRole,
} from "./manualUser";

import { DefaultRootStateProps } from "types";
import { Document } from "modules/productionManual/manual/types";
import { tokenStorage } from "services/storage";

const initialState: DefaultRootStateProps["document"] = {
  error: null,
  documents: [],
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    hasError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    getDocumentsSuccess(state, action: PayloadAction<Document[]>) {
      state.documents = action.payload;
    },
  },
});

export function getDocuments(showId: number, navigate: NavigateFunction) {
  return async (dispatch: AppDispatch) => {
    documentClient
      .getDocuments(showId)
      .then((documents) => {
        dispatch(documentSlice.actions.getDocumentsSuccess(documents));
      })
      .catch((error) => {
        dispatch(documentSlice.actions.hasError(error));
        if (error.response && error.response.status === 401) {
          console.log(error);

          dispatch(setIsLoggedIn(false));
          dispatch(setSelectedShow({ showId: null, showName: null }));
          dispatch(setLoggedInCompany(null));
          dispatch(setLoggedInShow(null));
          dispatch(setRole(null));

          toast.success("Session expired, please login again.");
          tokenStorage.removeToken();
          navigate("/manual/login");
        }
      });
  };
}

export default documentSlice.reducer;
