import { UpdatesProps, Update } from "types/updates";

export const groupUpdatesByCategory = (
  updates: UpdatesProps["updates"]
): Record<string, Update[]> => {
  if (!updates || !updates.recentlyUpdatedDocuments) {
    return {};
  }

  return updates.recentlyUpdatedDocuments.reduce<Record<string, Update[]>>(
    (acc, update) => {
      const category = update.document.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(update);
      return acc;
    },
    {}
  );
};
