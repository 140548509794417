import * as React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { ScrollToTop } from "../lib";
import { useCustomTheme } from "../themes";
import { persister, store } from "../store";
import SetPath from "../utils/SetPath";

const queryClient = new QueryClient();

const handleRefreshClick = async () => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });

  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });

  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

const ErrorFallback = () => {
  return (
    <div
      className="w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h1 className="text-[24px] font-bold">Please bear with us..</h1>
      <p>
        Sorry for the inconvenience. We suggest you <b>refresh the page</b> to
        resolve the issue.
      </p>
      <button
        className="mt-4 py-2 px-4 bg-red-600 border text-white border-gray-300 disabled:opacity-70 disabled:cursor-not-allowed rounded-md shadow-sm font-medium focus:outline-none"
        onClick={handleRefreshClick}
      >
        Refresh
      </button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const theme = useCustomTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persister}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                  <Router>
                    <SetPath>
                      <ScrollToTop />
                      <CssBaseline />
                      {children}
                    </SetPath>
                  </Router>
                </QueryClientProvider>
              </HelmetProvider>
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
