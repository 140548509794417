import { Box, Divider, Typography } from "@mui/material";

import { useSelector } from "store";

const ShowTitleSection = () => {
  const { selectedItem } = useSelector((state) => state.menu);
  const user = useSelector((state) => state.menu.menuType);
  const selectedShow = useSelector((state) => state.show.selectedShow);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "end",
        pb: 3,
      }}
    >
      {user === "admin" && (
        <>
          <Typography sx={{ fontSize: "20px" }}>Admin</Typography>
          <Divider orientation="vertical" sx={{ mx: 2 }} flexItem />
        </>
      )}

      <Typography sx={{ fontSize: "20px" }}>{selectedItem.name}</Typography>
      {selectedShow?.showName && (
        <>
          <Divider orientation="vertical" sx={{ mx: 2 }} flexItem />
          <Typography sx={{ fontSize: "20px", pr: 2 }}>
            {selectedShow?.showName}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ShowTitleSection;
