import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";
import {
  Avatar,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { IconChecklist } from "@tabler/icons-react";

import { Update } from "types/updates";
import { useDispatch } from "store";
import { getUpdates } from "store/slices/updates";
import { useCustomTheme } from "themes";
import { updatesTimePeriod } from "utils/updatesTimePeriod";
import { adminMenuItems } from "components/Layout/ProductionManualLayout/MenuList";
import { activeItem } from "store/slices/menu";

interface DashboardUpdatesProps {
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
}

const DashboardUpdates: FC<DashboardUpdatesProps> = ({ lg, md, sm, xs }) => {
  const theme = useCustomTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const showId = useSelector((state: any) => state.show.selectedShow.showId);
  const updates = useSelector(
    (state: any) => state.updates.updates.recentlyUpdatedDocuments
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sixMonthsAgo = useMemo(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 6);
    return date;
  }, []);

  const sortedAndFilteredUpdates = useMemo(() => {
    return updates
      ? updates
          .filter(
            (update: Update) => new Date(update.lastUpdateDate) >= sixMonthsAgo
          )
          .sort((a: Update, b: Update) => {
            const dateA = new Date(a.lastUpdateDate);
            const dateB = new Date(b.lastUpdateDate);
            return dateB.getTime() - dateA.getTime();
          })
      : [];
  }, [updates, sixMonthsAgo]);

  const categoryRoute = (category: string) => {
    const words = category.split(" ");

    if (words.length > 1) {
      return words[1].toLowerCase();
    }

    return category.toLowerCase();
  };

  useEffect(() => {
    dispatch(getUpdates(showId));
  }, [dispatch, showId]);

  return (
    <Grid item lg={lg} md={md} sm={sm} xs={xs}>
      <TableContainer
        component={Box}
        sx={{
          height: "350px",
          bgcolor: "#fff",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          border: `1px solid ${theme.palette.tertiary.main}`,
          borderBottom: "none",
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>RECENT UPDATES</TableCell>
              <TableCell align="right">{updatesTimePeriod()}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedAndFilteredUpdates
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((update: Update, index: number) => {
                return (
                  <TableRow key={nanoid()}>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        p: index === 0 ? "32px 16px 8px 16px" : "8px 16px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          variant="circular"
                          sx={{
                            bgcolor: "#f4f2ef",
                            width: matchDownMd ? "30px" : "34px",
                            height: matchDownMd ? "30px" : "34px",
                          }}
                          aria-controls="menu-card"
                        >
                          <IconChecklist
                            strokeWidth={1}
                            size={matchDownMd ? 18 : 22}
                            color="#000"
                          />
                        </Avatar>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: theme.palette.text.primary,
                          }}
                          to={`/manual/admin/${categoryRoute(
                            update.document.category
                          )}`}
                          onClick={() => {
                            if (update.document.category) {
                              const categoryItem = adminMenuItems.find(
                                (item) =>
                                  item.title.toLocaleLowerCase() ===
                                  update.document.category.toLocaleLowerCase()
                              );
                              if (categoryItem) {
                                dispatch(
                                  activeItem({
                                    id: categoryItem.id,
                                    name: categoryItem.title,
                                  })
                                );
                              }
                            }
                          }}
                        >
                          <Typography
                            sx={{
                              pl: 2,
                              fontSize: matchDownMd ? "12px" : "14px",
                              "&:hover": {
                                color: theme.palette.primary.main,
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {update.document.documentName}
                          </Typography>
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        p: index === 0 ? "32px 16px 8px 16px" : "8px 16px",
                      }}
                      align="right"
                    >
                      <Typography variant="caption">
                        {`Updated on: ${update.lastUpdateDate}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          bgcolor: "#fff",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          border: `1px solid ${theme.palette.tertiary.main}`,
        }}
        rowsPerPageOptions={[5]}
        component="div"
        count={sortedAndFilteredUpdates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

export default DashboardUpdates;
