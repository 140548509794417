import { FC, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import {
  IconBook,
  IconForms,
  IconBuildingStore,
  IconArrowNarrowRight,
} from "@tabler/icons-react";

import DashboardUpdates from "../components/DashboardUpdates";

import { useCustomTheme } from "themes";
import ContactComponent from "modules/productionManual/home/components/Contact/Contact";
import { activeItem } from "store/slices/menu";
import { useDispatch } from "store";
import { getDocuments } from "store/slices/document";

const categories = [
  {
    id: "2",
    icon: <IconBook size={20} strokeWidth={1} color={"black"} />,
    title: "Manual documents",
    data: "manualDocuments",
    buttonText: "Manual",
    path: "/manual/admin/manual",
    documentsCategory: "Manual",
  },
  {
    id: "3",
    icon: <IconForms size={20} strokeWidth={1} color={"black"} />,
    title: "Template documents",
    data: "TemplateDocuments",
    buttonText: "Templates",
    path: "/manual/admin/templates",
    documentsCategory: "Templates",
  },
  {
    id: "4",
    icon: <IconBuildingStore size={20} strokeWidth={1} color={"black"} />,
    title: "Production Vendor documents",
    data: "vendorDocuments",
    buttonText: "Production Vendors",
    path: "/manual/admin/vendors",
    documentsCategory: "Production Vendors",
  },
];

interface AdminDashboardProps {}

interface StatCardProps {
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
  dataCount: any;
  category: {
    id: string;
    icon: JSX.Element;
    title: string;
    data: string;
    buttonText: string;
    path: string;
  };
}

const StatCard = ({ lg, md, sm, xs, category, dataCount }: StatCardProps) => {
  const theme = useCustomTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid lg={lg} md={md} sm={sm} xs={xs} item>
      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "8px",
          border: `1px solid ${theme.palette.tertiary.main}`,
        }}
      >
        <Grid flexDirection={"column"} container>
          <Grid sx={{ width: "100%" }} item>
            <Grid
              id="test"
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                minHeight: "90px",
                flexDirection: matchDownMd ? "column" : "row",
                justifyContent: matchDownMd ? "center" : "left",
                alignItems: matchDownMd ? "center" : "left",
                p: "16px",
              }}
              container
            >
              <Grid item>
                <Avatar
                  variant="circular"
                  sx={{ bgcolor: "#f4f2ef" }}
                  aria-controls="menu-card"
                >
                  {category.icon}
                </Avatar>
              </Grid>
              <Grid
                item
                sx={{
                  ml: matchDownMd ? "0px" : "16px",
                  p: matchDownMd ? "16px" : "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#667085",
                      fontSize: "12px",
                      py: matchDownMd ? "8px" : "",
                      textAlign: !matchDownMd ? "left" : "center",
                    }}
                  >
                    {category.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "16px",
                      textAlign: !matchDownMd ? "left" : "center",
                    }}
                  >
                    {dataCount}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            sx={{
              width: "100%",
              borderTop: `1px solid ${theme.palette.tertiary.main}`,
            }}
            item
          >
            <Box sx={{ p: "8px 16px" }}>
              <Button
                onClick={() => {
                  navigate(`${category.path}`);
                  dispatch(activeItem({ id: category.id }));
                }}
                sx={{ border: "none", display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    lineHeight: 1,
                    fontSize: matchDownMd ? "12px" : "14px",
                  }}
                >
                  {category.buttonText}
                </Typography>

                <IconArrowNarrowRight
                  size={25}
                  strokeWidth={1}
                  color={"black"}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

const AdminDashboard: FC<AdminDashboardProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedShow = useSelector((state: any) => state.show.selectedShow);
  const showId = useSelector((state: any) => state.show.selectedShow.showId);
  const documents = useSelector((state: any) => state.document.documents);

  const filteredDocs = (category: string) => {
    return documents.filter((doc: any) => doc.category === category).length;
  };

  useEffect(() => {
    if (showId) {
      dispatch(getDocuments(showId, navigate));
    }
  }, [dispatch, showId, navigate]);

  return (
    <Box sx={{ minHeight: "calc(100vh - 200px)" }}>
      <Grid container flexDirection={"column"} columnSpacing={2} rowSpacing={2}>
        <Box sx={{ p: "16px" }}>
          <Typography variant="h1" sx={{ fontSize: "24px", px: "8px" }}>
            {selectedShow?.showName}
          </Typography>
        </Box>

        <Grid item>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <ContactComponent md={6} sm={12} xs={12} />
            <DashboardUpdates md={6} sm={12} xs={12} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container columnSpacing={2} rowSpacing={2}>
            {categories.map((category) => {
              return (
                <StatCard
                  key={nanoid()}
                  xs={12}
                  md={12}
                  lg={4}
                  category={category}
                  dataCount={filteredDocs(category.documentsCategory)}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
