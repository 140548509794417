import { createSlice } from "@reduxjs/toolkit";

import { DefaultRootStateProps } from "types";

const initialState: DefaultRootStateProps["searchTerm"] = {
  searchTerm: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const { setSearchTerm } = searchSlice.actions;

export default searchSlice.reducer;
