import { ContentLayout } from "../../../components/Layout/MainLayout/ContentLayout";
import { TermsAndConditions } from "../../../components/TermsAndConditions";

export const Terms = () => {
  return (
    <ContentLayout className=" py-[50px] laptop:px-[150px] phone:px-[20px]">
      <TermsAndConditions />
    </ContentLayout>
  );
};
