import { nanoid } from "nanoid";

import hod_image_2 from "../../../assets/images/hod/section_2_image_1.png";
import hod_image_3 from "../../../assets/images/hod/section_3_image_1.jpg";
import hod_image_4 from "../../../assets/images/hod/section_4_image_1.jpg";
import hod_image_5 from "../../../assets/images/hod/section_5_image_1.jpg";
import hod_image_6 from "../../../assets/images/hod/section_6_image_1.png";
import team_image_1 from "../../../assets/images/hod/team_image_1.png";
import team_image_2 from "../../../assets/images/hod/team_image_2.jpeg";
import team_image_3 from "../../../assets/images/hod/team_image_3.jpeg";
import team_image_4 from "../../../assets/images/hod/team_image_4.jpeg";
import team_image_5 from "../../../assets/images/hod/team_image_5.jpeg";
import team_image_6 from "../../../assets/images/hod/team_image_6.jpeg";
import brand_image_1 from "../../../assets/images/hod/brand_image_1.jpg";
import brand_image_2 from "../../../assets/images/hod/brand_image_2.jpg";
import brand_image_3 from "../../../assets/images/hod/brand_image_3.jpg";
import brand_image_4 from "../../../assets/images/hod/brand_image_4.png";

const mainTitle = "What would I do if..";

export const hodData = [
  {
    image: hod_image_2,
    mainTitle: mainTitle,
    secondaryTitle: "Leave of Absence",
    lists: [
      {
        listTitle:
          "What do I do if someone in my team is going on maternity leave?",
        bullets: [
          "• Ensure they check the gov.uk website and complete the maternity form as NBCUniversal policies only cover permanent employees",
          "• If the individual is self-employed, Maternity Allowance is paid at £156.66 per week",
        ],
      },
      {
        listTitle: "What do I do if I have jury service whilst on Production?",
        bullets: [
          <span key={nanoid()}>
            • Ensure the individual has checked the{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="text-[#0096FF] underline"
              href="https://www.gov.uk/maternity-allowance/eligibility"
              key={nanoid()}
            >
              {"gov.uk"}
            </a>{" "}
            website if they are self-employed to see what they are eligible for.
          </span>,
        ],
      },
      {
        listTitle: "What do I do if someone is regularly off sick?",
        bullets: [
          "• Speak to them about their absence to understand what is causing them to take time off",
          "• Ask if there is anything you can do as their manager to provide them with additional support",
          "• If the absence continues or there is a pattern of absence, make a note of this and get in touch with HR",
          "• It might be appropriate to refer them to occupational health to get a better understanding of how they can be supported at work, speak to HR about this",
          "• They may also benefit by having confidential support from our employee assistance program this is a free benefit for all NBCUniversal workers",
        ],
      },
    ],
  },
  {
    image: hod_image_3,
    mainTitle: mainTitle,
    secondaryTitle: "",
    lists: [
      {
        listTitle: "Someone is not showing up on time, what do I do?",
        bullets: [
          "• It's important to know the facts before assumptions are made, discretely ask the reason for the lateness",
          "• Remind them of the importance of punctuality and to make sure they contact you when they know they are going to be late",
          "• Keep a record of lateness if it persists or if you see a pattern",
          "• Arrange a meeting with the individual to discuss the lateness, make sure you let HR know as they will attend the meeting to support you",
        ],
      },
      {
        listTitle: "Someone keeps calling in sick, what do I do?",
        bullets: [
          "• Check on their welfare and ask why they are calling in sick",
          "• Keep a record of their absences",
          "• Let HR know about the issue and a meeting can be arranged with the individual to offer them support they may require",
        ],
      },
    ],
  },
  {
    image: hod_image_4,
    mainTitle: mainTitle,
    secondaryTitle: "Support resources",
    lists: [
      {
        listTitle: "Who do I contact for support on Productions?",
        bullets: [
          "• HR can help you with any people related questions or concerns you have, even if you aren’t sure who can help, its always best to speak to HR",
          <span key={nanoid()}>
            •{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="text-[#0096FF] underline"
              href="http://www.comcastnbcuniversallistens.com/"
              key={nanoid()}
            >
              {"Comcast Listens"}
            </a>{" "}
            is an independent third-party company where you can raise concerns
            you may have on a production. You can choose to do this anonymously,
            but where possible we always encourage people to identify themselves
            so we can ask follow up questions and give feedback easily. We take
            all concerns very seriously and it will be promptly logged and
            investigated.
          </span>,
          `• Production Manager/Supervisors are usually in the best position to resolve your concerns quickly. If you’re not comfortable raising a concern with your supervisor, you can talk to another local leader. Each of our leaders are trained on what to do when an employee comes forward with a workplace or integrity concern.`,
        ],
      },
      {
        listTitle: (
          <span key={nanoid()}>
            • What is the{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="text-[#0096FF] underline"
              href="https://www.healthassured.org/eap-employee-assistance-program/?utm_source=google&utm_medium=cpc&utm_campaign=EAP&adgroup=EAP%20Volume%20Prospects&utm_term=eap&gclid=Cj0KCQjw94WZBhDtARIsAKxWG-8vkN8mcUN94FdZMee0jLGOrxj7CrvHPzxI-Hzd929CUuq-fraqf3EaAtFAEALw_wcB"
              key={nanoid()}
            >
              {"EAP"}
            </a>{" "}
            and when can someone use it?
          </span>
        ),
        bullets: [
          "• EAP stands for the Employee Assistance Program and is for anyone who works for NBCUniversal. If you or someone in your team are struggling or need extra support, they can access confidential support by trained professionals",
        ],
      },
    ],
  },
  {
    image: hod_image_5,
    mainTitle: "What happens if someone from the Production speaks up?",
    secondaryTitle: "Support",
    lists: [
      {
        listTitle: "Who do I contact for support on Productions?",
        bullets: [
          "• Comcast NBCUniversal is committed to responding quickly to any concerns or complaints raised to them.  These can be raised through Comcast Listens, to a manager, HR or Legal. When appropriate, a trained investigator will be assigned to review the concern.",
          `• Confidential information such as the person’s identity (if disclosed) and the information they provide in the complaint will be shared if necessary for the purpose of the investigation.`,
          "• Although the investigation's results may not be shared with anyone, if wrongdoing is confirmed, appropriate discipline and/or corrective action will be taken regardless of the position of the individuals involved.",
          "• Comcast NBCUniversal strictly prohibits retaliation for a good faith report—even if your report is eventually unsubstantiated.  Comcast NBCUniversal also prohibits retaliation when you participate in good faith in the handling or investigation of a report.",
        ],
      },
    ],
  },
  {
    image: hod_image_6,
    mainTitle: mainTitle,
    secondaryTitle: "Performance Issues and Team Conflict",
    lists: [
      {
        listTitle:
          "I’m having a performance issue with someone on my team, what do I do?",
        bullets: [
          "• Make a note of where the individual is underperforming, its important that you only record objective information",
          `• Arrange a private meeting with the individual to discuss their current performance, empathetically highlighting that its not where you would expect it to be`,
          "• Ask if there is anything you should be made aware of as their manager e.g. are they struggling with their mental health, are they having issues at home etc.",
          "• Ask if they feel they need any adjustments to help them do their job to their fullest ability",
          "• Speak with your HR team, they can help advise you on the best course of action",
        ],
      },
      {
        listTitle: "Two people on my team aren’t getting on what do I do?",
        bullets: [
          "• Speak to the individuals to understand what the issue is and how they would like it resolved",
          `• Make a note of the issues raised and include any important dates, facts or times`,
          "• If you can resolve it informally and to their satisfaction, hold a meeting with them to move the issue forward",
          "• If you are unable to resolve the issue or if the problem is bigger than you anticipated refer straight to HR",
        ],
      },
      {
        listTitle: "Somebody isn’t doing their assigned tasks",
        bullets: [
          "• Speak privately to the individual to understand why they can’t complete the tasks",
          `• If necessary, offer them support to enable them to get the tasks done`,
          "• If the support they require is more complex than you anticipated or if you uncover an issue please speak to HR, they can help you navigate the next steps",
        ],
      },
    ],
  },
];

export const hodTeamData = [
  {
    image: team_image_1,
    title: "Sara Swinney (UK)",
    position: "VP Human Resources",
    email: "Sara.Swinney@nbcuni.com",
  },
  {
    image: team_image_2,
    title: "Charlie Oliver (UK)",
    position: "HR Director",
    email: "Charlie.Oliver@nbcuni.com",
  },
  {
    image: team_image_3,
    title: "Lucy Guthrie (UK)",
    position: "Senior HR Manager",
    email: "Lucy.Guthrie@nbcuni.com",
  },
  {
    image: team_image_4,
    title: "Ellen Smith (UK)",
    position: "HR Coordinator",
    email: "Ellen.Smith@nbcuni.com",
  },
  {
    image: team_image_5,
    title: "Alex Baden (AUS)",
    position: "HR Director",
    email: "alex.baden@matchboxpictures.com",
  },
  {
    image: team_image_6,
    title: "Steph Kettle (AUS)",
    position: "HR Manager",
    email: "stephanie.kettle@matchboxpictures.com",
  },
];

export const brandImages = {
  image1: brand_image_1,
  image2: brand_image_2,
  image3: brand_image_3,
  image4: brand_image_4,
};
