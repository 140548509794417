import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminContactForm from "../components/AdminContactForm";

import { useDispatch } from "store";
import { getShow } from "store/slices/show";

const AdminContact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contact = useSelector((state: any) => state.show.show.contact);
  const selectedShow = useSelector((state: any) => state.show.show);

  useEffect(() => {
    dispatch(getShow(selectedShow.showId, navigate));
  }, [dispatch, selectedShow.showId, navigate]);

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <AdminContactForm selectedShow={selectedShow} contact={contact} />
    </Box>
  );
};

export default AdminContact;
