import ProductionManualLayout from "../components/Layout/ProductionManualLayout";
import Templates from "../modules/productionManual/templates/views/Templates";
import ManualHome from "../modules/productionManual/home/views/ManualHome";
import Manual from "../modules/productionManual/manual/views/Manual";
import ProductionVendors from "../modules/productionManual/productionVendors/views/ProductionVendors";
// import Redbook from "../modules/productionManual/redbook/views/Redbook";
import Documents from "../modules/productionManual/documents/views/Documents";

import AuthGuard from "utils/AuthGuard";

const ManualRoutes = {
  path: "/manual",
  element: (
    <AuthGuard>
      <ProductionManualLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: <ManualHome />,
    },
    {
      path: "manual",
      element: <Manual />,
    },
    {
      path: "templates",
      element: <Templates />,
    },
    //---- Temporary hide ----//
    // {
    //   path: "redbook",
    //   element: <Redbook />,
    // },
    {
      path: "vendors",
      element: <ProductionVendors />,
    },
    {
      path: "documents",
      element: <Documents />,
    },
  ],
};

export default ManualRoutes;
