import * as React from "react";
import { nanoid } from "@reduxjs/toolkit";

import { brandImages, hodTeamData } from "./hod.data";
import { HodTeamMemberCard } from "./HodTeamMemberCard";

export const HodTeam = () => {
  return (
    <div>
      <div className="flex justify-center items-center bg-yellow-200 p-7 text-[32px] font-bold">
        <h1>YOUR HUMAN RESOURCES TEAM</h1>
      </div>
      <div className="flex justify-center max-w-full">
        <div className="grid tablet:grid-cols-6 gap-3 max-w-[100vw]">
          {hodTeamData.map((item) => {
            return (
              <HodTeamMemberCard
                key={nanoid()}
                image={item.image}
                title={item.title}
                position={item.position}
                email={item.email}
              />
            );
          })}
        </div>
      </div>
      <div className="flex justify-center items-center bg-yellow-200 p-4 text-[28px]">
        <h1>INTERNATIONAL</h1>
      </div>
      <div className="flex flex-row justify-evenly items-center pt-[50px]">
        <div className="flex justify-center w-[calc(100vw-300px)/5)]">
          <img className="w-[60%]" src={brandImages.image1} alt="" />
        </div>
        <div className="flex justify-center w-[calc(100vw-300px)/5)]">
          <img className="w-[60%]" src={brandImages.image2} alt="" />
        </div>
        <div className="flex justify-center w-[calc(100vw-300px)/5)]">
          <img className="w-[60%]" src={brandImages.image3} alt="" />
        </div>
        <div className="flex justify-center w-[calc(100vw-300px)/5)]">
          <img className="w-[60%]" src={brandImages.image4} alt="" />
        </div>
      </div>
    </div>
  );
};
