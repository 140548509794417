import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { authClient } from "../../../../services/api/auth";
import { tokenStorage } from "../../../../services/storage";

import Loader from "components/Loader/Loader";
import { useDispatch } from "store";
import { setIsLoggedIn, setRole } from "store/slices/manualUser";
import { setMenuType } from "store/slices/menu";

const CallbackComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (!code) {
      navigate("/manual/login");
      return;
    }

    authClient
      .getToken(code)
      .then((r) => {
        tokenStorage.setIdToken(r.id_token);
        tokenStorage.setRefreshToken(r.refresh_token);
        dispatch(setIsLoggedIn(true));
        dispatch(setMenuType("admin"));
        dispatch(setRole("admin"));
        setLoading(false);
        navigate("/manual/admin/home");
      })
      .catch((err) => {
        console.error("Error:", err);
        setError(true);
      });
  }, [location, dispatch, navigate]);

  return (
    <Box sx={{ height: "100vh" }}>
      {loading && !error && <Loader />}
      {error && (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" sx={{ pb: 3, color: "#ff2c2c" }}>
            Failed to authenticate
          </Typography>
          <Button
            color="inherit"
            sx={{ background: "" }}
            onClick={() => navigate("/manual/login")}
          >
            Back
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CallbackComponent;
