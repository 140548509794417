import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import AdminList from "components/List/AdminList/AdminList";
import { useDispatch } from "store";
import {
  openCreateTemplateDocumentModal,
  openDeleteTemplateDocumentModal,
} from "store/slices/modal";
import { meetingClient } from "services/api/manual";
import { Meeting } from "types/meeting";
import { getTemplateDocuments, getTemplates } from "store/slices/templates";
import { ProductionCompanyState } from "types/productionCompany";
import { TemplateState } from "types/template";

interface AdminMeetingListProps {
  rows: Meeting[];
}

const headCells: any = [
  {
    id: "documentName",
    numeric: false,
    label: "Document Title",
    align: "left",
  },
  {
    id: "category",
    numeric: false,
    label: "Document Category",
    align: "left",
  },
  {
    id: "subcategory",
    numeric: false,
    label: "Document Subcategory",
    align: "left",
  },
  {
    id: "region",
    numeric: false,
    label: "Document Region",
    align: "left",
  },
];

const AdminTemplateDocumentList = ({ rows }: AdminMeetingListProps) => {
  const dispatch = useDispatch();

  const productionCompanyId = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany?.productionCompanyId
  );

  const selectedTemplate = useSelector(
    (state: TemplateState) => state.templates.selectedTemplate
  );

  useEffect(() => {
    if (selectedTemplate) {
      dispatch(getTemplateDocuments(productionCompanyId!, selectedTemplate.id));
    }
  }, [dispatch, productionCompanyId, selectedTemplate]);

  const handleRefreshMeetings = () => {
    if (productionCompanyId) {
      dispatch(getTemplates(productionCompanyId));
    }
  };

  return (
    <Box sx={{ p: "16px", width: "100%", height: "100%" }}>
      <AdminList
        title={"Tempalte documents"}
        categoryTitle={"Document"}
        rows={rows}
        editIcon={false}
        deleteIcon={false}
        disableDeleteAll
        searchComponent={true}
        onDelete={openDeleteTemplateDocumentModal}
        onAdd={openCreateTemplateDocumentModal}
        headCells={headCells}
        searchProp="documentName"
        deleteMultiple={meetingClient.deleteMultipleMeetings}
        handleRefresh={handleRefreshMeetings}
      />
    </Box>
  );
};

export default AdminTemplateDocumentList;
