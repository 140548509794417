import { ContentLayout } from "../../../components/Layout/MainLayout/ContentLayout";
import { images } from "../../../assets";
import { HodSection } from "../components/HodSection";
import { HodTeam } from "../components/HodTeam";

export const HodGuide = () => {
  return (
    <ContentLayout className="py-[50px] laptop:px-[150px] phone:px-[20px]">
      <div
        className="min-h-[600px] flex w-full h-full justify-center items-center"
        style={{
          backgroundImage: `url(${images.hodImages.image1})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="p-[50px]">
          <h1 className="text-white pb-[100px] font-bold tracking-wider laptop:text-[62px] tablet:text-[62px] phone:text-[22px] phone:text-center">
            Head of Department Guide
          </h1>

          <h2 className="text-white text-[62px] font-bold tracking-wider  phone:text-[42px] phone:text-center">
            NBC Universal Productions
          </h2>
        </div>
      </div>

      <HodSection />
      <HodTeam />
    </ContentLayout>
  );
};
