import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { templateClient } from "services/api/manual/template";
import { Document } from "types/document";
import { Meeting } from "types/meeting";
import { Template } from "types/template";

const initialState: any = {
  error: null,
  selectedTemplate: null,
  templates: [],
  templateMeetings: [],
  templateDocuments: [],
};

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getTemplateSuccess(state, action: PayloadAction<Template | null>) {
      state.selectedTemplate = action.payload;
    },
    getTemplatesSuccess(state, action: PayloadAction<Template[]>) {
      state.templates = action.payload;
    },
    getTemplatesMeetingsSuccess(state, action: PayloadAction<Meeting[]>) {
      state.templateMeetings = action.payload;
    },
    getTemplatesDocumentsSuccess(state, action: PayloadAction<Document[]>) {
      state.templateDocuments = action.payload;
    },
  },
});

export function getTemplates(prodCompanyId: number) {
  return async (dispatch: any) => {
    templateClient
      .getTemplates(prodCompanyId)
      .then((templates) => {
        dispatch(templateSlice.actions.getTemplatesSuccess(templates));
      })
      .catch((error) => {
        dispatch(templateSlice.actions.hasError(error));
      });
  };
}

export function getTemplateById(prodCompanyId: number, templateId: number) {
  return async (dispatch: any) => {
    templateClient
      .getTemplates(prodCompanyId)
      .then((templates) => {
        const selectedTemplate = templates.find(
          (template) => template.id === templateId
        );
        dispatch(templateSlice.actions.getTemplateSuccess(selectedTemplate!));
      })
      .catch((error) => {
        dispatch(templateSlice.actions.hasError(error));
      });
  };
}

export function getTemplateMeetings(prodCompanyId: number, templateId: number) {
  return async (dispatch: any) => {
    templateClient
      .getTemplates(prodCompanyId)
      .then((templates) => {
        const selectedTemplate = templates.find(
          (template) => template.id === templateId
        );
        dispatch(
          templateSlice.actions.getTemplatesMeetingsSuccess(
            selectedTemplate?.meetings || []
          )
        );
      })
      .catch((error) => {
        dispatch(templateSlice.actions.hasError(error));
      });
  };
}

export function getTemplateDocuments(
  prodCompanyId: number,
  templateId: number
) {
  return async (dispatch: any) => {
    templateClient
      .getTemplates(prodCompanyId)
      .then((templates) => {
        const selectedTemplate = templates.find(
          (template) => template.id === templateId
        );
        dispatch(
          templateSlice.actions.getTemplatesDocumentsSuccess(
            selectedTemplate?.documents || []
          )
        );
      })
      .catch((error) => {
        dispatch(templateSlice.actions.hasError(error));
      });
  };
}

export const { hasError, getTemplatesSuccess, getTemplateSuccess } =
  templateSlice.actions;

export default templateSlice.reducer;
