import * as React from "react";

import { Heading } from "../Heading";

const TCParagraphStyling = "py-3 text-[14px]";

export const TermsAndConditions = () => {
  return (
    <div className="pb-[50px]">
      <Heading
        className="bg-[#eeeeee] pl-3 text-[22px]"
        title={"Terms and Conditions"}
      />
      <p className={TCParagraphStyling}>
        Click{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "blue", textDecoration: "underline" }}
          href="https://www.nbcuniversal.com/terms"
        >
          here
        </a>{" "}
        for NBCUniversal Terms of Service{" "}
      </p>
      <p className={TCParagraphStyling}>
        Please read these Terms and Conditions carefully. By continuing to use
        this Website these Terms and Conditions will apply to you and you will
        be deemed to have accepted them. You are confirming that you are at
        least 16 years old or have the permission of your parent/guardian to use
        this Website. <br></br>
      </p>
      <p className={TCParagraphStyling}>
        The copyright and all other rights in the material on this website are
        owned by NBCU or are included with the permission of the owner of the
        rights. As a visitor to this Website, you may download a single copy of
        the material for your own private viewing purposes only. Unless we give
        you express written permission, you may not decompile, reverse engineer,
        disassemble, rent, lease, loan, sublicense or create derivative works
        from the Website, which includes any information and software made
        available via the Website. You may not copy, save, download, modify,
        reproduce, republish, distribute, transmit or use for any purposes,
        whether commercial or non-commercial, the Website or any information
        contained on the Website, except to the extent necessarily required in
        order for you to display, use and navigate the Website.<br></br>
      </p>
      <p className={TCParagraphStyling}>
        While NBCU takes every care to ensure that the information on this
        Website is accurate and complete this Website is provided “as is”;
        without any warranties of any kind and NBCU does not accept any
        liability arising from any inaccuracy or omission in the information
        available on or via the Website or interruption in availability of the
        Website. NBCU excludes, to the fullest extent permitted by applicable
        laws, and save in respect of death or personal injury arising from its
        negligence, all liability for any claims, losses, demands and damages
        arising directly or indirectly out of or in any way connected with the
        Website and/or any services offered via the Website from time to time.
        This exclusion shall apply in respect of, without limitation, any
        interruption of service, lost profits, loss of contracts or business
        opportunity, loss of data, or any other consequential, incidental,
        special, or punitive damages, arising out of the Website, even if NBCU
        has been advised of the possibility of such damages, whether arising in
        contract, tort, under statute or otherwise.<br></br>
      </p>
      <p className={TCParagraphStyling}>
        If any of these Terms and Conditions are determined to be illegal,
        invalid or unenforceable by reason of the laws in any state or country
        in which they are intended to be effective, then to the extent so
        determined, it shall be severed and deleted and the remaining Terms and
        Conditions shall remain in full force and continue to be binding and
        enforceable. These Terms of Use are exclusively governed by and
        construed in accordance with the laws of England and Wales whose courts
        will have exclusive jurisdiction in any dispute, save that NBCU has the
        right at its sole discretion to commence and pursue proceedings in
        alternative jurisdictions.”
      </p>
    </div>
  );
};
