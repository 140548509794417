import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminList from "components/List/AdminList/AdminList";
import { useDispatch } from "store";
import { getTemplateById, getTemplates } from "store/slices/templates";
import { ProductionCompanyState } from "types/productionCompany";
import {
  openCreateProductionTemplateModal,
  openDeleteProductionTemplateModal,
} from "store/slices/modal";
import { Template } from "types/template";
import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

interface TemplateData {
  id: number;
  name: string;
}

const headCells: any = [
  {
    id: "name",
    numeric: false,
    label: "Template name",
    align: "left",
  },
];

const ProductionTemplates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productionCompanyId = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany?.productionCompanyId
  );
  const dataRows = useSelector((state: any) => state.templates.templates);

  useEffect(() => {
    if (productionCompanyId) {
      dispatch(getTemplates(productionCompanyId));
    }
  }, [dispatch, productionCompanyId]);

  function createData(id: number, name: string): TemplateData {
    return { id: id, name: name };
  }

  const rows = Array.isArray(dataRows)
    ? dataRows.map((row: Template) => createData(row.id, row.name))
    : [];

  function openEditProductionTemplateModal(item: any) {
    dispatch(getTemplateById(productionCompanyId!, item.id));
    navigate(`/manual/admin/production-templates/modify`);
  }

  return (
    <Box sx={{ p: "16px", width: "100%", height: "100%" }}>
      <ShowTitleSection />
      <AdminList
        title={"Production Templates"}
        categoryTitle={"Production template"}
        rows={rows}
        editIcon={true}
        editDispatch={false}
        searchComponent={true}
        onDelete={openDeleteProductionTemplateModal}
        onAdd={openCreateProductionTemplateModal}
        onEdit={openEditProductionTemplateModal}
        headCells={headCells}
        searchProp="name"
        disableDeleteAll={true}
        searchPlaceholder={"Search templates"}
        editTooltipText={"Update template"}
      />
    </Box>
  );
};

export default ProductionTemplates;
