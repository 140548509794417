import { useEffect } from "react";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { useCustomTheme } from "../../../../../themes";
import { Meeting } from "../../../../../types/meeting";

import { openModal } from "store/slices/modal";
import { useDispatch, useSelector } from "store";
import { getMeetingById, getMeetings } from "store/slices/meeting";

interface MeetingItemProps {
  meeting: Meeting;
  onOpenModal: (meeting: Meeting) => void;
  showId: number;
}

const MeetingItem = ({ meeting, onOpenModal }: MeetingItemProps) => (
  <ListItem
    sx={{
      borderTop: "1px solid #ebebeb",
    }}
    disablePadding
  >
    <ListItemButton
      onClick={() => {
        onOpenModal(meeting);
      }}
    >
      <ListItemText sx={{ p: "8px 0" }} primary={meeting.title} />
    </ListItemButton>
  </ListItem>
);

const Meetings = () => {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const meetings = useSelector((state: any) => state.meeting.meetings);
  const showId = useSelector((state: any) => state.show.selectedShow.showId);

  const handleOpen = (meeting: Meeting) => {
    dispatch(getMeetingById(meeting));
    dispatch(openModal());
  };

  useEffect(() => {
    dispatch(getMeetings(showId, navigate));
  }, [dispatch, showId, navigate]);

  return (
    <Grid
      xs={12}
      sx={{
        bgcolor: "#fff",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.tertiary.main}`,
      }}
      item
    >
      <Box sx={{ borderBottom: `1px solid ${theme.palette.tertiary.main}` }}>
        <Box sx={{ p: "16px" }}>
          <Typography>MEETINGS</Typography>
          <Box sx={{ pt: "16px" }}>
            <Typography variant="caption">
              {`Refer to "Contacts" to obtain the name of the person to coordinate the following meeting(s)`}
            </Typography>
          </Box>
        </Box>
        <List>
          {meetings?.map((meeting: Meeting) => (
            <MeetingItem
              showId={showId}
              key={nanoid()}
              meeting={meeting}
              onOpenModal={handleOpen}
            />
          ))}
        </List>
      </Box>
    </Grid>
  );
};

export default Meetings;
