import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import userReducer from "./slices/user";
import manualUserReducer from "./slices/manualUser";
import menuReducer from "./slices/menu";
import documentReducer from "./slices/document";
import searchReducer from "./slices/searchTerm";
import modalReducer from "./slices/modal";
import meetingReducer from "./slices/meeting";
import showReducer from "./slices/show";
import updatesReducer from "./slices/updates";
import productionCompanyReducer from "./slices/productionCompany";
import policyReducer from "./slices/policy";
import templateReducer from "./slices/templates";

const reducer = combineReducers({
  user: userReducer,
  show: showReducer,
  policy: policyReducer,
  document: documentReducer,
  meeting: meetingReducer,
  searchTerm: searchReducer,
  updates: updatesReducer,
  modal: modalReducer,
  menu: persistReducer(
    {
      key: "menu",
      storage,
      keyPrefix: "sfp-",
    },
    menuReducer
  ),
  manualUser: persistReducer(
    {
      key: "manualUser",
      storage,
      keyPrefix: "sfp-",
    },
    manualUserReducer
  ),
  productionCompany: persistReducer(
    {
      key: "productionCompany",
      storage,
      keyPrefix: "sfp-",
    },
    productionCompanyReducer
  ),
  templates: persistReducer(
    {
      key: "templates",
      storage,
      keyPrefix: "sfp-",
    },
    templateReducer
  ),
});

export default reducer;
