import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import ShowSelectMessage from "components/ShowSelect/ShowSelectMessage";
import { useAppDispatch } from "hooks";
import { getPublicShows } from "store/slices/show";
import ShowTitleSection from "components/Layout/ProductionManualLayout/Header/ShowTitleSection";

const AdminHome = () => {
  const dispatch = useAppDispatch();

  const showId = useSelector((state: any) => state.show.selectedShow.showId);

  useEffect(() => {
    dispatch(getPublicShows());
  }, [dispatch]);

  return (
    <Box sx={{ p: "16px", width: "100%", height: "100%" }}>
      {showId && <ShowTitleSection />}
      {!showId && <ShowSelectMessage />}
      {showId && <Outlet />}
    </Box>
  );
};

export default AdminHome;
