import { ContentLayout } from "../../../../components/Layout/MainLayout/ContentLayout";
import { LoginForm } from "../components/LoginForm";

export const Login = () => {
  return (
    <ContentLayout>
      <LoginForm />
    </ContentLayout>
  );
};
