import { useRoutes } from "react-router-dom";

import { Home } from "../pages/home";

import AppRoutes from "./AppRoutes";
import ManualRoutes from "./ManualRoutes";
import ManualLoginRoutes from "./ManualLoginRoutes";
import ManualAdminRoutes from "./ManualAdminRoutes";

export default function ThemeRoutes() {
  return useRoutes([
    { path: "/", element: <Home /> },
    AppRoutes,
    ManualRoutes,
    ManualLoginRoutes,
    ManualAdminRoutes,
  ]);
}
