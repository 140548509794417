import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminMeetingList from "../components/AdminMeetingList";

import { useDispatch } from "store";
import { getMeetings } from "store/slices/meeting";
import { Meeting } from "types/meeting";

const AdminMeetings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showId = useSelector((state: any) => state.show.selectedShow.showId);
  const dataRows = useSelector((state: any) => state.meeting.meetings);

  useEffect(() => {
    if (showId) {
      dispatch(getMeetings(showId, navigate));
    }
  }, [dispatch, showId, navigate]);

  function createData({
    meetingId,
    title,
    agenda,
    duration,
    location,
    attendeesProd,
    attendeesUis,
    meetingInfo,
  }: Meeting) {
    return {
      id: meetingId,
      title: title,
      agenda: agenda,
      duration: duration,
      location: location,
      uisAttendees: attendeesUis,
      prodAttendees: attendeesProd,
      meetingInfo: meetingInfo,
    };
  }

  const rows = dataRows.map((row: Meeting) => {
    return createData({
      meetingId: row.meetingId,
      title: row.title,
      agenda: row.agenda,
      duration: row.duration,
      location: row.location,
      attendeesUis: row.attendeesUis,
      attendeesProd: row.attendeesProd,
      meetingInfo: row.meetingInfo,
    });
  });

  return (
    <>
      <AdminMeetingList rows={rows} />
    </>
  );
};

export default AdminMeetings;
