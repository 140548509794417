import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import useAuth from "hooks/useAuth";
import { GuardProps } from "types";

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, loggedInCompany, loggedInShow } = useAuth();
  const selectedCompanyName = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyName
  );
  const selectedShowName = useSelector(
    (state: any) => state.show.selectedShow.showName
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !isLoggedIn ||
      loggedInCompany !== selectedCompanyName ||
      loggedInShow !== selectedShowName
    ) {
      navigate("/manual/login");
    }
  }, [
    navigate,
    isLoggedIn,
    loggedInShow,
    loggedInCompany,
    selectedCompanyName,
    selectedShowName,
  ]);

  return children;
};

export default AuthGuard;
