import { FC, Fragment, useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  IconHome2,
  IconBook,
  IconRibbonHealth,
  IconReport,
  IconGavel,
  IconNotebook,
} from "@tabler/icons-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { nanoid } from "nanoid";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  manualLinks,
  policyLinks,
} from "../MainNavigation/MainNavigationLinkData";

import { setSelectedProductionCompany } from "store/slices/productionCompany";
import { getShowsByProductionCompany } from "store/slices/show";
import { useDispatch } from "store";
import { setSelectedPolicy } from "store/slices/policy";
import { openDropdownDrawer } from "store/slices/menu";

export const mainDropdownLinks = [
  {
    title: "Home",
    href: "/",
    hasDropdown: false,
    icon: <IconHome2 />,
  },
  {
    title: "Policies",
    href: "",
    hasDropdown: true,
    dropdownKey: "policies",
    icon: <IconGavel />,
  },
  {
    title: "Wellbeing",
    href: "/wellbeing",
    hasDropdown: false,
    icon: <IconRibbonHealth />,
  },
  {
    title: "HOD Guide",
    href: "/hod-guide",
    hasDropdown: false,
    icon: <IconNotebook />,
  },
  {
    title: "Production Manual",
    href: "",
    hasDropdown: true,
    dropdownKey: "manual",
    icon: <IconBook />,
  },
  {
    title: "Reporting",
    href: "https://secure.ethicspoint.com/domain/media/en/gui/21987/index.html",
    hasDropdown: false,
    external: true,
    icon: <IconReport />,
  },
];

const DropdownList = () => {
  const [isPoliciesOpen, setIsPoliciesOpen] = useState(false);
  const [isManualOpen, setIsManualOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTogglePolicies = () => {
    setIsPoliciesOpen(!isPoliciesOpen);
    if (isManualOpen) {
      handleCloseManual();
    }
  };

  const handleToggleManual = () => {
    setIsManualOpen(!isManualOpen);
    if (isPoliciesOpen) {
      handleClosePolicies();
    }
  };

  const handleClosePolicies = () => {
    setIsPoliciesOpen(false);
  };

  const handleCloseManual = () => {
    setIsManualOpen(false);
  };

  const isOpen = useSelector((state: any) => state.menu.dropdownOpen);

  useEffect(() => {
    if (!isOpen) {
      handleClosePolicies();
      handleCloseManual();
    }
  }, [isOpen]);

  return (
    <Box>
      <List>
        {mainDropdownLinks.map((item) => {
          if (item.hasDropdown && item.dropdownKey === "policies") {
            return (
              <Fragment key={nanoid()}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleTogglePolicies()}>
                    <Box sx={{ display: "flex" }}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        sx={{ color: "#000" }}
                        primary={item.title}
                      />
                      <ExpandMoreIcon
                        sx={{
                          color: "#000",
                          transform: isPoliciesOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.3s ease-in-out",
                        }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>

                <Collapse in={isPoliciesOpen} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    {policyLinks.map((link) => {
                      return (
                        <ListItem key={nanoid()}>
                          <ListItemButton
                            onClick={() => {
                              dispatch(
                                setSelectedPolicy({
                                  policyName: link.name,
                                  policyCode: link.code,
                                })
                              );
                              navigate(link.to);
                              handleClosePolicies();
                              dispatch(openDropdownDrawer(false));
                            }}
                          >
                            <ListItemText
                              sx={{ color: "#000" }}
                              primary={link.name}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Divider />
                </Collapse>
              </Fragment>
            );
          }

          if (item.hasDropdown && item.dropdownKey === "manual") {
            return (
              <Fragment key={nanoid()}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleToggleManual()}>
                    <Box sx={{ display: "flex" }}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        sx={{ color: "#000" }}
                        primary={item.title}
                      />
                      <ExpandMoreIcon
                        sx={{
                          color: "#000",
                          transform: isManualOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.3s ease-in-out",
                        }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>

                <Collapse in={isManualOpen} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    {manualLinks.map((link) => {
                      return (
                        <ListItem key={nanoid()}>
                          <ListItemButton
                            onClick={() => {
                              if (link.id) {
                                dispatch(getShowsByProductionCompany(link.id));
                              }
                              dispatch(
                                setSelectedProductionCompany({
                                  productionCompanyId: link.id ?? null,
                                  productionCompanyName: link.name,
                                })
                              );
                              handleCloseManual();
                              dispatch(openDropdownDrawer(false));
                              navigate("/manual");
                            }}
                          >
                            <ListItemText
                              sx={{ color: "#000" }}
                              primary={link.name}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Divider />
                </Collapse>
              </Fragment>
            );
          }

          return (
            <ListItem key={nanoid()} disablePadding>
              <ListItemButton
                sx={{ textAlign: "center" }}
                component={RouterLink}
                to={item.href}
              >
                <Box sx={{ display: "flex" }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText sx={{ color: "#000" }} primary={item.title} />
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

const NavDropdownTemp: FC = () => {
  const isOpen = useSelector((state: any) => state.menu.dropdownOpen);

  return (
    <Fade in={isOpen} timeout={300}>
      <Box
        sx={{
          position: "absolute",
          top: "86px",
          left: "0px",
          width: "100%",
          bgcolor: "#fff",
          zIndex: 1200,
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <DropdownList />
      </Box>
    </Fade>
  );
};

export default NavDropdownTemp;
