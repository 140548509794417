import { getDocumentUrl } from "../modules/productionManual/manual/api/getDocumentUrl";

export async function viewDocument(id: number) {
  try {
    const url = await getDocumentUrl(id);
    const link = document.createElement("a");
    link.href = url!;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    const addedLink = document.body.appendChild(link);
    link.click();
    document.body.removeChild(addedLink);
  } catch (error) {
    console.error("Error fetching document URL:", error);
  }
}
